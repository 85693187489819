import { Dispatch, SetStateAction, useRef, ReactElement } from 'react'
import RightMenuComponent from './RightMenu'
import { Modal, Background, ChildrenContainer } from './style'

interface IModal {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  children: JSX.Element
  removeChildrenPadding?: boolean
  width?: number
  height?: number
  FS?: boolean
  hideMenu?: boolean
  extendedMenuData?: ReactElement
  zIndex?: number
}

export default function ModalComponent(props: IModal) {
  const {
    showModal,
    children,
    setShowModal,
    hideMenu,
    extendedMenuData,
    removeChildrenPadding,
    zIndex,
  } = props
  const backgroundRef = useRef<HTMLDivElement>(null)

  const handleClickBackground = (e: any) => {
    if (backgroundRef.current === e.target) {
      setShowModal(false)
    }
  }

  return showModal ? (
    <Background
      ref={backgroundRef}
      onClick={handleClickBackground}
      zIndex={zIndex}
    >
      <Modal className="modal-window" {...props}>
        <ChildrenContainer removeChildrenPadding={removeChildrenPadding}>
          {children}
        </ChildrenContainer>
        {!hideMenu && (
          <RightMenuComponent
            setShowModal={setShowModal}
            extendedMenuData={extendedMenuData}
            disable={!extendedMenuData}
          />
        )}
      </Modal>
    </Background>
  ) : (
    <div />
  )
}
