import styled from 'styled-components'

interface IStyle {
  rate?: string
}

export const Container = styled.div`
  width: 352px;
  height: 44px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-end;
  align-items: center;
`

export const Value = styled.p`
  border: 1px solid #488090;
  background: #fff;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 4px 8px;
  box-sizing: border-box;
  width: 44px;
  /* height: 20px; */
  color: #3e788a;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  margin: 0;
  top: 0px;
  left: ${(props: IStyle) => (`${(307 * Number(props.rate)) / 100 }px`)};
`

export const Slider = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 315px;
  height: 8px;
  background: #f5f4f3;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  background: ${(props: IStyle) =>
    `linear-gradient(to right, #3E788A, #3E788A ${props.rate}%, #F5F4F3 ${props.rate}%, #F5F4F3 100%)`};

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    filter: drop-shadow(1px 1px 10px rgba(7, 7, 7, 0.25));
    background: #fdfdfd;
    cursor: pointer;
    border-radius: 15px;
  }

  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: #fdfdfd;
    cursor: pointer;
    border-radius: 15px;
    filter: drop-shadow(1px 1px 10px rgba(7, 7, 7, 0.25));
  }

  &::-moz-range-progress {
    background-color: #3e788a;
  }
  &::-ms-fill-lower {
    background-color: #3e788a;
  }
  &::-ms-fill-upper {
    background-color: #f5f4f3;
  }
`
