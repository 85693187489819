import { useState } from 'react'
import { Kanban, Modal } from 'components'

import { IKanbanData, IObject } from 'interfaces'

export default function KanbanStatusCampanha({ data }: any) {
  const [kanbanData, setKanbanData] = useState<IKanbanData>(data)

  return (
    <>
      <div className="container">
        <Kanban
          data={kanbanData}
          setData={setKanbanData}
        />
      </div>
    </>
  )
}
