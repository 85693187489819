import * as i from 'assets/Icons'

export interface IInputIcons {
  password: string[]
  eye: string[]
  upload: string[]
}

const inputIcons: IInputIcons = {
  password: [i.Outlined('lockClosed'), i.Outlined('lockOpen')],
  eye: [i.Solid('eyeClosedSolid'), i.Solid('eyeOpenSolid')],
  upload: [i.Solid('uploadSolid'), i.Solid('uploadSolid')],
}

export default inputIcons
