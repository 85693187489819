import styled from 'styled-components'

export const PreviewText = styled.h3`
  color: #070707;
  /* text-align: center; */
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
`

export const Subtitles = styled.h2`
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-top: 15px;
  margin-bottom: 0;
`
export const Content = styled.h4`
  color: #9e9e9e;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin: 0;

  span {
    text-decoration-line: underline;
    color: #3e788a;
  }
`
export const SmallContent = styled.h5`
  display: flex;
  margin: 0;
  color: #bdbdbd;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  span {
    font-weight: 700;
    color: #757575;
  }
`

export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  /* margin-bottom: 7%; */
  /* border: 1px solid red; */
  width: 100%;
  /* gap: 50px; */
  overflow-x: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: white;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }
`