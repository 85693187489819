import { KanbanCard } from 'components'
import icons from 'helper/icons'
import { useEffect, useState } from 'react'
import { useFetch } from 'hooks'
import {
  CardsContainer,
  Container,
  DivColumn,
  MenuButton,
  SearchBar,
  SearchBarContainer,
} from './style'

interface IInvestorDetailProps {
  productId: number
  purchaseId: number
}

export default (props: IInvestorDetailProps) => {
  const { productId, purchaseId } = props

  const url = `purchase?id=${purchaseId}&productId=${productId}`
  const apiPf = useFetch(`${url}&type=pf`)
  const apiPj = useFetch(`${url}&type=pj`)

  const [type, setType] = useState<string>('pf')
  const [filtered, setFiltered] = useState<any>([])
  const [data, setData] = useState([])

  const handleSearch = (value: string) => {
    const f = filtered.filter((item: any) => {
      return item.name.includes(value) || item.document.includes(value)
    })
    setFiltered(f)
  }

  const fillData = (res: { [key: string]: any }) => {
    console.log('fill', res.data.response)
    setData(res.data.response)
  }

  useEffect(() => {
    switch (type) {
      case 'pj':
        apiPj.fetch('get').then(fillData)
        break
      default:
        apiPf.fetch('get').then(fillData)
    }
  }, [type])

  return (
    <Container>
      <DivColumn width={200}>
        <p className="page-title">Investidores</p>
        <SearchBarContainer>
          <img
            src={icons.searchOutlined}
            alt="search icon"
            className="leftIcon"
          />
          <SearchBar
            placeholder="Pesquisar"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </SearchBarContainer>
        <MenuButton active={type === 'pf'} onClick={() => setType('pf')}>
          <img src={icons.clientPFSolid} alt="search icon" />
          Pessoas Físicas (PF)
        </MenuButton>
        <MenuButton active={type === 'pj'} onClick={() => setType('pj')}>
          <img src={icons.clientPJSolid} alt="search icon" />
          Pessoas Jurídicas (PJ)
        </MenuButton>
      </DivColumn>
      {data.map((item: { [key: string]: any }, i) => (
        <DivColumn background="#F9F9F9" height="93%" width={300}>
          <p className="column-title">{`${item.name}: ${item.items.length}`}</p>
          <CardsContainer>
            {item.items.map((card: { [key: string]: any }) => {
              return (
                <KanbanCard
                  color={item.color}
                  column={i}
                  name={card.name}
                  cpf={card.document}
                  email={card.email}
                />
              )
            })}
          </CardsContainer>
        </DivColumn>
      ))}
    </Container>
  )
}
