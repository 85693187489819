import { Card, ClickableCard, Modal, PagePathing, PageTitle } from 'components'
import {
  useState,
  ReactElement,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { CardsContainer, Column, Content } from 'styles'
import SecurityConfirm from 'components/SecurityConfirm'
import { SapiensContext } from 'context/sapiens'
import useFetch from 'hooks/useFetch'
import { icons } from 'helper'
import CaptacaoPersonal from './CaptacaoPersonal'
import { Row } from '../../../styles'
import BasicInfoForm from './NovaCaptacao/BasicInfoForm'
import DetailsForm from './NovaCaptacao/DetailsForm'
import LayoutForm from './NovaCaptacao/LayoutForm'
import LegalForm from './NovaCaptacao/LegalForm'
import ResumoBasicInfo from './NovaCaptacao/RsumoBasicInfo'
import { numberToMoney } from 'helper/utils'

export default function InvestimentosEsg() {
  const [showModal, setShowModal] = useState(false)
  const { newCaption, setNewCaption } = useContext(SapiensContext)
  const [data, setData] = useState<any>({ amount: 0, count: 0, list: [] })
  const [pageNumber, setPageNumber] = useState<number>(0)

  const apiData = useFetch('rental-token')
  const apiIndex = useFetch('index-list')

  const loadData = () => {
    const promises = [apiData.fetch('get'), apiIndex.fetch('get')]
    Promise.all(promises)
      .then((res: any) => {
        const [tokenData, indexList] = res
        if (tokenData.fail) throw new Error(tokenData.errors.message)
        if (indexList.fail) throw new Error(tokenData.errors.message)
        setData({
          ...tokenData.data.response,
          list: indexList.data.response.map((item: any, i: number) => {
            return {
              ...item,
              label: item.fonetic,
              value: i,
            }
          }),
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (!showModal) {
      setNewCaption((c: any) => ({
        ...c,
        contratoCCB: null,
        alienFiduciaria: null,
        avalSocios: null,
        contratoCDB: null,
        previewImg: null,
        preview64Token: null,
        color: null,
        secondColor: null,
      }))
      setPageNumber(0)
    }
  }, [showModal])

  useEffect(() => {
    // console.log(pageNumber)
    // console.log(newCaption)
  }, [pageNumber])

  const pages: { [key: number]: ReactElement } = {
    0: (
      <BasicInfoForm
        indexList={data.list}
        handleNext={() => setPageNumber((oldValue) => oldValue + 1)}
      />
    ),
    1: (
      <ResumoBasicInfo
        indexList={data.list}
        handleNext={() => setPageNumber((oldValue) => oldValue + 1)}
        handleBack={() => setPageNumber((oldValue) => oldValue - 1)}
      />
    ),
    2: (
      <LayoutForm
        handleNext={() => setPageNumber((oldValue) => oldValue + 1)}
        handleBack={() => setPageNumber((oldValue) => oldValue - 1)}
      />
    ),
    3: (
      <DetailsForm
        handleNext={() => setPageNumber((oldValue) => oldValue + 1)}
        handleBack={() => setPageNumber((oldValue) => oldValue - 1)}
      />
    ),
    4: (
      <LegalForm
        handleNext={() => setPageNumber((oldValue) => oldValue + 1)}
        handleBack={() => setPageNumber((oldValue) => oldValue - 1)}
      />
    ),
    5:
      newCaption?.modalCaption?.value === 1 ? (
        <CaptacaoPersonal
          handleNext={() => setPageNumber((oldValue) => oldValue + 1)}
          handleBack={() => setPageNumber((oldValue) => oldValue - 1)}
        />
      ) : (
        <SecurityConfirm path={'newCaption'} />
      ),
    6: <SecurityConfirm path={'newCaption'} />,
  }

  return (
    <Content withoutMarginLeft noMarginTop>
      <PagePathing>{`Gestão de Produtos > Investimentos ESG`}</PagePathing>
      <PageTitle>
        <>
          <img
            className="icon"
            src={icons.esgOutlined}
            alt="card icon"
            style={{ marginRight: 20 }}
          />
          Investimentos
        </>
      </PageTitle>
      <Column gap={20}>
        <Row gap={20}>
          <Card
            variation={'Atualizado em tempo real'}
            value={`R$ ${numberToMoney(data?.amount)}` || 'R$ 0,00'}
            wide
            icon={'stonksOutlined'}
            inverted
          >
            Valor investido
          </Card>
          <Card
            variation={'Atualizado em tempo real'}
            value={String(data?.count) || '0'}
            wide
            icon={'clientOutlined'}
            inverted
          >
            Investidores
          </Card>
        </Row>
        <Row gap={20}>
          <ClickableCard
            onClick={() => setShowModal(true)}
            green
            icon="newPlusSolid"
            smallIcon
          >
            Nova captação
          </ClickableCard>
          <ClickableCard green icon="lockOpenOutlined" to="captacoes">
            Captações
          </ClickableCard>
        </Row>
      </Column>

      <Modal FS setShowModal={setShowModal} showModal={showModal}>
        {pages[pageNumber]}
      </Modal>
    </Content>
  )
}
