import PageTitle from './style'

interface IPageTitleComponent {
  withoutMarginLeft?: boolean
  children: any
  detailsMargin?: boolean
  noHeight?: boolean
}

export default function PageTitleComponent(props: IPageTitleComponent) {
  const { children } = props
  return <PageTitle {...props}>{children}</PageTitle>
}
