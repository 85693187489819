import styled from 'styled-components'

export const ExtendedContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 95%;
  padding: 34px 0 0 50px;
  width: 94%;
  /* margin-left: 103px; */
  background-color: #f9f9f9;

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
  }
`
