import styled from 'styled-components'

export const ExtendedContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  position: relative;
  max-height: 90%;
  height: 90%;
  padding: 0;
  max-width: 90%;
  margin-top: 70px;
  background-color: #f5f4f3;
  margin-left: 50px;
`

export const CardsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  max-height: 90%;
  padding-bottom: 20px;
  padding-left: 3px;
  overflow: scroll;
  gap: 20px;

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: #f5f4f3;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-left: 0;
  margin-top: 20px;
  margin-bottom: -20px;

  .icon {
    align-self: center;
    margin-bottom: 20px;
  }
`

export const Span = styled.div`
  display: flex;
  width: 217px;
  color: #070707;
  max-height: 26px;
  text-align: center;
  margin-top: 10px;
  align-items: center;
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.2px;
  img {
    margin-right: 14px;
    position: relative;
    left: 0;
    width: 16px;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 72px;
  width: 175px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0px 3px 4px 0px #00000040;
  background: '#ffffff';
  .title {
    color: '#070707';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    margin: 10px 0 0 0;
    letter-spacing: 0.2px;
  }
  .iconClickable {
    max-width: 24px;
    height: 24px;
    margin: 0 0 12px 25px;
  }
  .selectIcon {
    max-width: 15.79px;
    max-height: 15.79px;
    margin: 0 0 50px 150px;
  }
  span {
    font-weight: 300;
    font-style: normal;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.2px;
  }
`
