import { RefObject } from 'react'
import { icons } from 'helper'
import { onlyNumeric, timestampToDate, dateToHour } from 'helper/utils'
import KanbanCard from './style'

interface ICardProps {
  name: string
  cpf?: string
  email?: string
  green?: boolean
  black?: boolean
  disabled?: boolean
  step?: { [key: string]: any }
  noFooter?: boolean
  lastMessage?: string
  column: string | number
  color: string
  destinationList?: string[]
  cardData?: { [key: string]: string }
  onClick?(_event?: object): any
  ref?: (
    _instance: HTMLDivElement | null
  ) => void | RefObject<HTMLDivElement> | null
  isDragging?: boolean
  trash?: boolean
  onClickTrash?: any
  date?: any
}

const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export default function KanbanCardComponent(props: ICardProps) {
  const {
    name,
    cpf,
    email,
    trash,
    onClickTrash,
    lastMessage,
    step,
    cardData,
    date,
    noFooter,
  } = props

  return (
    <KanbanCard {...props}>
      <div className="trashContainer">
        <span className="name">{`${name?.substring(0, 20)}${
          String(name).length > 20 ? '...' : ''
        }`}</span>
        {trash && (
          <img
            className="icon trash"
            src={icons.trashOutlined}
            alt="card icon"
            onClick={() => onClickTrash({ cpf, name, email })}
          />
        )}
      </div>

      {lastMessage ? (
        <>
          <span className="date">{`${timestampToDate(
            new Date(date)
          )} às ${dateToHour(new Date(date))}`}</span>
          <span className="message">
            &quot;{lastMessage.substring(0, 85)}...&quot;
          </span>
        </>
      ) : cardData ? (
        Object.entries(cardData).map(([label, info]) => (
          <span className="label">
            {capitalize(label)}: <span>{info}</span>
          </span>
        ))
      ) : (
        <>
          <span className="label">
            {`${onlyNumeric(String(cpf)).length === 11 ? 'CPF' : 'CNPJ'}`}:{' '}
            <span>{cpf}</span>
          </span>
          <span className="label">
            Email:{' '}
            <span>{`${email?.substring(0, 16)}${
              String(email).length > 16 ? '...' : ''
            }`}</span>
          </span>
        </>
      )}

      {!noFooter ? (
        <div className="iconsRow">
          {step?.color ? (
            <span className="flexSpan">
              <div className="dot" />
              <p>{step.name}</p>
            </span>
          ) : (
            <>
              <img className="icon" src={icons.whatsappSolid} alt="card icon" />
              <img className="icon" src={icons.phoneSolid} alt="card icon" />
              <img className="icon" src={icons.emailSolid} alt="card icon" />
            </>
          )}
        </div>
      ) : (
        <div className="iconsRow" style={{ height: 15 }} />
      )}
    </KanbanCard>
  )
}
