import styled from 'styled-components'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  align-items: flex-start;
  justify-content: flex-start;
  /* background-color: blue; */
  /* height: 100%; */
`
export const PageText = styled.p`
  font-weight: 700;
  font-size: ${(props: any) => props.color};
  line-height: 26px;
  color: #070707;
`
export const CardsContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  flex-wrap: wrap;
  max-height: 80%;
  height: 480px;
  width: 80%;
  max-width: 758px;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  /* background-color: green; */
  overflow: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: #fbfbfb;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }
`
