import { PageTitle, PagePathing, EsgCard } from 'components'
import { IRibbons } from 'helper/ribbons'
import useFetch from 'hooks/useFetch'
import { useEffect, useState } from 'react'
import { CardsContainer } from 'styles'
import { ExtendedContainer } from './style'

interface IMockProps {
  reserved: {
    deadline?: number
    image?: string
    captureType?: string
    type?: keyof IRibbons
    profitability?: number
    product?: string
    round?: string
    meta?: string
    value?: string // valorReserva
    quota?: number // reservado
    income?: string // paid
  }[]

  active: {
    deadline?: number
    image?: string
    captureType?: string
    type?: keyof IRibbons
    profitability?: number
    product?: string
    round?: string
    meta?: string
    value?: string // valorReserva
    quota?: number // reservado
    income?: string // paid
  }[]

  closed: {
    deadline?: number
    image?: string
    captureType?: string
    type?: keyof IRibbons
    profitability?: number
    product?: string
    round?: string
    meta?: string
    value?: string // valorReserva
    quota?: number // reservado
    income?: string // paid
  }[]
}

interface IIvestmentDetailProps {
  id: number
  type: string
}
export default (props: IIvestmentDetailProps) => {
  const { id, type: _ } = props
  const [data, setData] = useState<IMockProps>({
    reserved: [],
    active: [],
    closed: [],
  })

  const api = useFetch('user')

  useEffect(() => {
    const fetchInvestments = async () => {
      try {
        const r = await api.fetch('get', {}, `user/products/${id}`)
        if (r.fail) throw new Error()
        setData(r.data.response)
      } catch (e) {
        console.error(e)
      }
    }
    fetchInvestments()
  }, [])

  return (
    <ExtendedContainer>
      <PagePathing>
        {'Detalhes do cliente > Produtos > Investimentos ESG'}
      </PagePathing>
      <PageTitle>Investimentos ESG</PageTitle>
      <CardsContainer overflow scrollTrack="#f9f9f9" withoutMarginLeft>
        {data.reserved.length > 0 && (
          <div style={{ marginLeft: '20px' }}>
            <p>Reservado</p>
            <CardsContainer withoutMarginLeft>
              {data.reserved.map((item: any) => {
                return (
                  <span>
                    <EsgCard
                      isReservation
                      isChanged={{
                        value: 'Valor da Reserva:',
                        parcel: 'Parcelas restantes',
                        paid: 'Meta',
                      }}
                      paid={item.meta}
                      parcel={`${item.quota}% `}
                      progress={item.deadline - item.quota}
                      value={item.value}
                      rentability={String(item.profitability)}
                      imageUrl={item.image}
                      months={item.deadline}
                      ribbon={
                        item.captureType === 'Fechada'
                          ? 'personal'
                          : item.type?.toLowerCase()
                      }
                    >
                      {`${item.product} #${item.round}`}
                    </EsgCard>
                  </span>
                )
              })}
            </CardsContainer>
          </div>
        )}

        {data.active.length > 0 && (
          <div style={{ marginLeft: '20px' }}>
            <p>Ativo</p>
            <CardsContainer withoutMarginLeft>
              {data.active.map((item: any) => {
                return (
                  <EsgCard
                    paid={item.income}
                    parcel={`${item.deadline - item.quota} `}
                    progress={(item.quota / item.deadline) * 100}
                    value={item.value}
                    rentability={String(item.profitability)}
                    imageUrl={item.image}
                    months={item.deadline}
                    tip={`${item.deadline - item.quota} parcelas restantes`}
                    meta={`Rentabilidade paga: ${item.income}`}
                    ribbon={item.type?.toLowerCase()}
                  >
                    {`${item.product} #${item.round}`}
                  </EsgCard>
                )
              })}
            </CardsContainer>
          </div>
        )}

        {data.closed.length > 0 && (
          <div style={{ marginLeft: '20px' }}>
            <p>Concluído</p>
            <CardsContainer withoutMarginLeft>
              {data.closed.map((item: any) => {
                return (
                  <EsgCard
                    paid={item.income}
                    parcel={`${item.quota} `}
                    progress={100 - (item.quota / item.deadline) * 100}
                    value={item.income}
                    rentability={String(item.profitability)}
                    imageUrl={item.image}
                    months={item.deadline}
                    ribbon={
                      item.captureType === 'Fechada'
                        ? 'personal'
                        : item.type.toLowerCase()
                    }
                  >
                    {`${item.product} #${
                      Number(item.round) < 10 ? `0${item.round}` : item.round
                    }`}
                  </EsgCard>
                )
              })}
            </CardsContainer>
          </div>
        )}
      </CardsContainer>
    </ExtendedContainer>
  )
}
