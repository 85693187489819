import { Menu, SearchBar } from 'components'
import { useAuth } from 'hooks'

import { Navigate, Outlet, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
`

export default function PrivateRoute() {
  const auth = useAuth()
  const location = useLocation()

  if (!auth.user?.isLogged) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return (
    <Container>
      <SearchBar />
      <Menu />
      <Outlet />
    </Container>
  )
}
