import { RefObject } from 'react'
import styled from 'styled-components'
import { ITheme } from 'styles/Themes'

interface IStyleProps {
  green?: boolean
  black?: boolean
  disabled?: boolean
  column: string | number
  color: string
  ref?: (
    _instance: HTMLDivElement | null
  ) => void | RefObject<HTMLDivElement> | null
  isDragging?: boolean
  theme: ITheme
  step?: any
}

export default styled.div`
  line-height: 16px;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 142px;
  width: 210px;
  border-radius: 5px;
  border: none;

  cursor: ${(props: IStyleProps) => {
    if (props.isDragging) return 'grabbing'
    return props.disabled ? 'pointer' : 'grab'
  }};
  box-shadow: ${(props: IStyleProps) =>
    props.isDragging && `0px 3px 4px 0px ${props.theme.boxShadow}`};

  color: #ffffff;
  background: ${(props: IStyleProps) => {
    return props.color || '#191919'
  }};

  .name {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-left: 10px;
    color: white;
    /* margin: 10px 0 0 10px; */
  }

  .date,
  .message,
  .label {
    font-weight: 400;
    font-size: 12px;
    margin-left: 10px;
    color: white;

    span {
      font-weight: 400;
      color: white;
    }
  }

  .iconsRow {
    display: flex;
    gap: 15px;

    .icon {
      filter: brightness(0) saturate(100%);
      filter: invert(100%) sepia(92%) saturate(156%) hue-rotate(182deg)
        brightness(114%) contrast(95%);
      max-width: 30px;
      height: 13px;
      margin: 0 0 17px 10px;
      cursor: pointer;
    }
  }

  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${(props) => {
      if (props.step?.color) return `${props.step?.color}`
      return 'white'
    }};
    margin: 0 0 17px 10px;
  }

  .trashContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  .trash {
    margin-right: 10px;
  }

  .flexSpan {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 10px;

    img {
      width: 12px;
      margin-right: 10px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 16.41px;
      letter-spacing: 1%;
    }
  }
`
