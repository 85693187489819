import styled from 'styled-components'

interface IStyleProps {
  withoutMarginLeft?: boolean
  noHeight?: boolean
}

export default styled.h3`
  overflow: hidden;
  min-height: ${(props) => {
    if (props.noHeight) return 'auto'
    return '23px'
  }};
  width: 600px;

  font-size: 14px;
  font-weight: 400;
  color: #868686;

  margin: 0;
  margin-left: ${(props: IStyleProps) => {
    if (props.withoutMarginLeft) return `10px`
    return '30px'
  }};

  .marginTop20 {
    margin-top: 20px;
  }
`
