import { useEffect, useState } from 'react'
import { Card, ClickableCard } from 'components'
import { CardsContainer } from 'styles'
import useFetch from 'hooks/useFetch'
import useSocket from 'hooks/useSocket'

import { IObject } from 'interfaces'

export default function GestaoCupomDashboard({ setShowModal }: any) {
  const api = useFetch('cupom')
  const [data, setData] = useState<IObject>({})

  useEffect(() => {
    api.fetch('get').then((res: IObject) => {
      if (res.fail) throw new Error()
      const { data } = res
      setData(data.response)
    })
  }, [])

  return (
    <>
      <CardsContainer marginLeft>
        <Card
          icon="personCheckBlack"
          value={data.clientAmount}
          wide
        >
          Qtd. de clientes
        </Card>

        <Card
          icon="ticketBlack"
          value={data.amount}
          wide
        >
          Número de cupons criados
        </Card>

        <Card
          icon="volumeBlack"
          value={data.campaignAmount}
          wide
        >
          Quantidade de campanhas
        </Card>
      </CardsContainer>

      <CardsContainer marginLeft>
        <Card
          icon="coinOutlined"
          value={data.totalCupom}
          wide
        >
          Valor liberado em cupom
        </Card>

        <Card
          icon="coinOutlined"
          value={data.rescuedAmount}
          wide
        >
          Valor resgatado em cupom
        </Card>

        <Card
          icon="coinOutlined"
          value={data.activeCupom}
          wide
        >
          Valor ativo em cupom
        </Card>
      </CardsContainer>
      <CardsContainer marginLeft>

      <ClickableCard
          onClick={() => setShowModal(true)}
          green
          icon="newPlusSolid"
          smallIcon
          >
          Novo cupom
        </ClickableCard>
      </CardsContainer>
    </>
  )
}
