import { CenteredContainer, LoginContainer } from 'styles'
import { images } from 'helper'
import { useState, useRef, useContext } from 'react'
import { Input, Button, Modal, TwoWayPassword } from 'components'
import { useAuth, useFetch } from 'hooks'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { SapiensContext } from 'context'

export default function LoginPage() {
  const navigate = useNavigate()
  const location: any = useLocation()
  const { loading, setLoading } = useContext(SapiensContext)

  const auth = useAuth()

  const from = location.state?.from?.pathname || '/'

  const formRef = useRef<HTMLFormElement | any>(null)
  const formRefCode = useRef<HTMLFormElement | any>(null)
  const [showModal, setShowModal] = useState(false)

  const api = useFetch('login')

  const handleSubmit = async (data: { password: string }) => {
    const schema = Yup.object().shape({
      password: Yup.string().required('Insira uma Chave de Acesso'),
    })
    formRef.current.setErrors({})
    try {
      await schema.validate(data, { abortEarly: false })
      setLoading(true)
      const response = await api.fetch('post', data)
      if (response.fail) throw new Error(response.errors.message)

      auth.setFirstToken(response.data.response)
      setShowModal(true)
      setLoading(false) // TODO - Já existe o método loading no hook fetch
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: {
          [key: string]: { path: string; message: string }
        } = {}
        err.inner.forEach((error: any) => {
          validationErrors[error.path] = error.message
        })
        formRef.current.setErrors(validationErrors)
      } else {
        formRef.current.setErrors({ password: 'Chave de acesso inválida' })
      }
      setLoading(false)
    }
  }

  const handleLogin = async (data: { code: string }) => {
    const schema = Yup.object().shape({
      code: Yup.string().required('Insira o código enviado.'),
    })
    formRefCode.current.setErrors({})
    try {
      await schema.validate(data, { abortEarly: false })
      setLoading(true)
      const response = await api.fetch('post', data)
      if (response.fail) throw new Error(response.errors.message)

      setLoading(false)
      auth.signin(response.data.response, () => {
        navigate(from, { replace: true })
      })
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: {
          [key: string]: { path: string; message: string }
        } = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          formRefCode.current.setErrors(validationErrors)
        }
      } else {
        formRefCode.current.setErrors({ code: err.message })
      }
      setLoading(false)
    }
  }

  const handleChange = () => {
    formRef.current.setErrors({})
  }

  if (auth.user?.isLogged) {
    return <Navigate to="/dashboard" replace />
  }

  return (
    <CenteredContainer login>
      <img src={images.logoSapiens} alt="Sapiens Logo" />
      <LoginContainer>
        Acessar plataforma Admin
        <Form onSubmit={handleSubmit} ref={formRef}>
          <Input
            name="password"
            icon="password"
            type="password"
            rounded
            placeholder="Chave de Acesso"
            onChange={handleChange}
          />
          <Button $loading={loading} rounded>
            ENTRAR
          </Button>
        </Form>
      </LoginContainer>
      <Modal
        setShowModal={setShowModal}
        showModal={showModal}
        width={500}
        height={500}
        hideMenu
      >
        <Form onSubmit={handleLogin} ref={formRefCode}>
          <TwoWayPassword />
        </Form>
      </Modal>
    </CenteredContainer>
  )
}
