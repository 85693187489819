import { Solid } from 'assets/Icons'
import * as Style from './style'
import { ProgressCricle } from 'components/index'
import { numberToMoney } from 'helper/utils'
import TokenIcon from './TokenIcon'

// import { shadow } from '../../../styles';

interface IChart {
  rate: number
  title: string
  color: string
}

interface Iitem {
  image?: string
  colorTags: Array<string>
  color?: string
  guarantee?: any
  icon?: string
  name?: string
  createdAt?: string
  rate?: number
  buttonText?: string
  min?: string
  chart: Array<IChart>
  opacity?: string
  secondColor?: string
  newIcon?: string
}

interface IProps {
  item: Iitem
  i?: number
  onPress?: any
  small?: boolean
  disabled?: boolean
}

export default ({
  item,
  i,
  onPress = () => {},
  small = false,
  disabled = false,
}: IProps) => {
  return (
    <Style.Poster
      color={item?.color}
      key={`label_poster_card_${i}`}
      small={small}
    >
      {item?.guarantee && (
        <>
          <Style.FlagIcon src={Solid('whiteCardFlag')} />
          <Style.FlagText color={item?.color}>{item?.guarantee}</Style.FlagText>
        </>
      )}
      <Style.Card onClick={disabled ? () => {} : onPress} small={small}>
        <Style.ImageContainer image={item?.image}>
          <Style.Gradient opacity={item?.opacity}>
            <Style.ImageContent>
              <Style.MinRow style={{ justifyContent: 'flex-start' }}>
                {item.colorTags.map((tag: any, index: number) => {
                  return (
                    <Style.ColoredTag
                      color={item?.color}
                      key={`color-tag-poster-${index}`}
                    >
                      <Style.TagText>{tag}</Style.TagText>
                    </Style.ColoredTag>
                  )
                })}
              </Style.MinRow>
              <Style.MinRow style={{ justifyContent: 'flex-start' }}>
                {/* <NewIcon icon={item?.icon} /> */}
                {item?.icon && <img src={item?.icon} alt="token icon" />}  
                {item?.newIcon ? <img src={`data:image/svg+xml;base64,${item?.newIcon}`}/> : <TokenIcon />}
                {/* {(
                  <TokenIcon outlined={item?.newIcon} color={item?.color} secondColor={item?.secondColor} />
                )} */}
                <Style.InvestTitle>{`${item?.name} / ${item?.createdAt}`}</Style.InvestTitle>
              </Style.MinRow>
            </Style.ImageContent>
          </Style.Gradient>
        </Style.ImageContainer>
        <Style.ChartContainer>
          {item?.chart && (
            <Style.ChartWrapper>
              <Style.RateContainer>
                <Style.RateColoured color={item?.color}>
                  {numberToMoney(Number(item?.rate).toFixed(2))}%
                </Style.RateColoured>
                <Style.RateText>Captados</Style.RateText>
              </Style.RateContainer>
              <ProgressCricle
                radius={65}
                valueArray={item?.chart}
                noLegend
                strokeWidth={3}
              />
            </Style.ChartWrapper>
          )}
          <Style.MinRow style={{ width: 'auto', marginBottom: 30 }}>
            <Style.InvestText>Investimento mínimo:</Style.InvestText>
            <Style.MoneyText>{`R$ ${item?.min}`}</Style.MoneyText>
          </Style.MinRow>
          <Style.ColoredTag
            key={`color-tag-investir-${i}`}
            color={disabled ? '#CCCCCC' : item?.color}
          >
            <Style.TagText>
              {item?.buttonText ? item?.buttonText : 'Investir'}
            </Style.TagText>
            <img src={Solid('arrowRightWhite')} alt="arrow white" style={{ marginLeft: 10 }} height={8} />
          </Style.ColoredTag>
        </Style.ChartContainer>
      </Style.Card>
    </Style.Poster>
  )
}
