import { useContext, useEffect, useState } from 'react'
import { Kanban, Modal, Carousel } from 'components'
import { SapiensContext } from 'context'
import useFetch from 'hooks/useFetch'
import useSocket from 'hooks/useSocket'
import DetalhesModal from 'pages/DetalhesModal'
import { clearString } from 'helper/utils'

import { IKanbanColumns, IKanbanData, IObject } from 'interfaces'

export default function ClientePF() {
  const { searchParam, setShowModalPF, setBuffer, socket, setSocket } =
    useContext(SapiensContext)

  const [kanbanData, setKanbanData] = useState<IKanbanData>({})
  const [userId, setUserId] = useState()
  const [filteredData, setFilteredData] = useState({})
  const api = useFetch('user?type=pf')
  // MODAL
  const [showModalDetalhes, setShowModalDetalhes] = useState(false)
  const [extendedMenu, setExtendedMenu] = useState<any>(false)

  const fetchUsers = async () => {
    try {
      const response = await api.fetch('get');
      if (response.fail) throw new Error();
      setKanbanData(response.data.response);
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    setShowModalPF(false)
    fetchUsers()
  }, [])

  useEffect(() => {
    setFilteredData(kanbanData)
  }, [kanbanData])

  useEffect(() => {
    const shownData = Object.entries(kanbanData).reduce(
      (obj: { [key: string]: IKanbanColumns }, [columnId, { items }]) => {
        obj[columnId] = {
          ...kanbanData[columnId],
          items: items.filter(
            (item) =>
              item.name.includes(searchParam) ||
              item.cpf.includes(searchParam) ||
              item.email.includes(searchParam)
          ),
        }
        return obj
      },
      {}
    )
    setFilteredData(shownData)
  }, [searchParam])

  useSocket((io: IObject) => {
    if (setSocket) setSocket(io);
    io.on('adm_refresh_client', fetchUsers);
  }, socket)

  const onCardClick = async (item: { [key: string]: any }) => {
    try {
      setBuffer({
        chatUser: {
          name: item.name,
          document: clearString(item.cpf),
          chatRoom: 'cg',
        },
      })
      setUserId(item.id)
      setShowModalDetalhes(true)
    } catch (e) {
      console.error(e)
    }
  }

  const onDropCard = async (itemId: number, columnId: number) => {
    const body = {
      step: kanbanData[columnId].id,
    };

    api.fetch('put', body, `user/${itemId}`)
      .catch(err => {
        console.error(err);
        fetchUsers();
      })
  }

  return (
    <>
      <Kanban
        data={filteredData}
        setData={setKanbanData}
        onCardClick={onCardClick}
        onDropCard={onDropCard}
      />
      <Modal
        showModal={showModalDetalhes}
        setShowModal={setShowModalDetalhes}
        FS
        extendedMenuData={extendedMenu}
      >
        <>
          <Carousel />
          <DetalhesModal userId={userId} setExtendedMenu={setExtendedMenu} />
        </>
      </Modal>
    </>
  )
}
