import { SapiensContext } from 'context/sapiens'
import { useContext, useEffect, useRef } from 'react'
import { IMask } from 'react-imask'

import {
  PageTitle,
  PagePathing,
  ButtonNext,
  ResumeInputCard,
  ResumeSelectCard,
  ResumeTextAreaCard,
} from 'components'
import { Form } from '@unform/web'
import { SubTitle, CardsWrap, CardTitle, FormDiv  } from './style'
import { options } from '../BasicInfoForm/factory'
import { IObject } from 'interfaces'
import { moneyToNumber, numberToMoney } from 'helper/utils'

// const newCaption = {
//   fullName: 'Celula de Credito Bancario Sapiensbank 4',
//   tick: 'CCBS4',
//   name: 'CCB Sapienbank 1',
//   modalCaption:  { label: 'Captação aberta', value: 0 },
//   tipoInvest:  { label: 'CDB', value: 0 },
//   garantia: { label: 'CDB - FGC', value: 0 },
//   cetip:  { label: 'Sim', value: 0 },
//   metaCaption: 500000,
//   price: 1,
//   supply: 500000,
//   minimumInvest: 1000,
//   periodoLanc:  { label: '7 dias', value: 0 },
//   prazo: { label: '15 meses', value: 0 },
//   pagamentoJuros: { label: '15 meses', value: 0 },
//   liquidez: { label: '15 meses', value: 0 },
//   indexador: { label: 'Pré fixado (Basic)', value: 0 },
//   index: { label: 'Pré fixado (Basic)', value: 0 },
//   profitability: 16,
//   impostoRenda: { label: 'Pré fixado (Basic)', value: 0 },
//   coatizationPre: { label: '1 dia', value: 0 },
//   coatizationPos: { label: '1 dia', value: 0 },
//   cupom: { label: 'Aceita todos os cupons', value: 0 },
// }

export default function ResumoBasicInfo({ handleNext, handleBack, indexList }: any) {
  const {  newCaption, setNewCaption } = useContext(SapiensContext)
  const formRef = useRef<HTMLFormElement | any>(null)

  useEffect(() => {

    if (newCaption.price && newCaption.supply) {
      setNewCaption((c: any) => ({
        ...c,
        metaCaption: newCaption.supply * newCaption.price,
      }))
    }

  }, [newCaption?.price,newCaption?.supply])

  useEffect(() => {
    if (newCaption?.pagamentoJuros && newCaption?.pagamentoJuros?.value !== 4) {
      setNewCaption((c: any) => ({
        ...c,
        indexador: { label: 'Pré fixado (Basic)', value: 0, flag: 'Pré-fixado' },
      }))
    }
  }, [newCaption?.pagamentoJuros])

  
  useEffect(() => {
    if (newCaption?.indexador?.value === 0) {
      setNewCaption((c: any) => ({
        ...c,
        index: null
      }))
    }
  }, [newCaption?.indexador])

  useEffect(() => {
    if (newCaption?.modalCaption && newCaption?.modalCaption?.value === 0) {
      setNewCaption((c: any) => ({
        ...c,
        impostoRenda: { label: 'Descontado na fonte', value: 0 }
      }))
    }
  }, [newCaption?.modalCaption])

  const handleUnmask = (string: string, split: string) => string.split(split)[0]

  const handleChangePage = () => {
    return null
  }

  useEffect(() => {
    console.log(newCaption)
  }, [])
  return (

    // <Wrapper>
    <>
      {/* <div className="content-wrapper"> */}
        <PagePathing
          withoutMarginLeft
        >{`Gestão de produtos > Investimentos > Nova captação > Resumo da captação `}</PagePathing>
        <PageTitle withoutMarginLeft detailsMargin>Resumo da captação</PageTitle>
        <SubTitle>
          Confira e avance na seta abaixo para confirmar a criação
        </SubTitle>
        <FormDiv>
        <Form onSubmit={handleChangePage} ref={formRef}>
          <CardTitle>Informações do produto</CardTitle>
          <CardsWrap>
                <ResumeTextAreaCard
                  // smallIcon
                  handleChangeInput={(e: any) => {
                    setNewCaption((c: IObject) => ({
                      ...c,
                      fullName: e,
                    }))
                  }}
                  names="resumo-meta-captacao"
                  icon="leafOutlined"
                  placeholder={newCaption.fullName}
                >
                  Nome completo do produto
                </ResumeTextAreaCard>
                <ResumeTextAreaCard
                  // smallIcon
                  handleChangeInput={(e: any) => {
                    setNewCaption((c: IObject) => ({
                      ...c,
                      tick: e,
                    }))
                  }}
                  names="resumo-meta-captacao"
                  icon="leafOutlined"
                  placeholder={newCaption.tick}
                >
                  Sigla do produto
                </ResumeTextAreaCard>
                <ResumeTextAreaCard
                  // smallIcon
                  handleChangeInput={(e: any) => {
                    setNewCaption((c: IObject) => ({
                      ...c,
                      name: e,
                    }))
                  }}
                  names="resumo-meta-captacao"
                  icon="leafOutlined"
                  placeholder={newCaption.name}
                >
                  Nome resumido
                </ResumeTextAreaCard>
            <ResumeSelectCard
              handleChange={(e: any) => {
                setNewCaption((c: IObject) => ({
                  ...c,
                  modalCaption: e,
                }))
              }}
              icon="lockOpenOutlined"
              type="modalCaption"
              options={options.modalCaption}
              name={newCaption.modalCaption.label}
            >
              Modalidade da captação
            </ResumeSelectCard>
            <ResumeSelectCard
              handleChange={(e: any) => {
                setNewCaption((c: IObject) => ({
                  ...c,
                  tipoInvest: e,
                }))
              }}
              icon="lockOpenOutlined"
              type="modalCaption"
              options={options.tipoInvest}
              name={newCaption.tipoInvest.label}
            >
              Tipo de investimento
            </ResumeSelectCard>
            <ResumeSelectCard
              small
              handleChange={(e: any) => {
                setNewCaption((c: IObject) => ({
                  ...c,
                  garantia: e,
                }))
              }}
              icon="lockOpenOutlined"
              type="modalCaption"
              options={options.garantia}
              name={newCaption.garantia.label}
            >
              Garantia
            </ResumeSelectCard>
            <ResumeSelectCard
              small
              handleChange={(e: any) => {
                setNewCaption((c: IObject) => ({
                  ...c,
                  cetip: e,
                }))
              }}
              icon="lockOpenOutlined"
              type="modalCaption"
              options={options.cetip}
              name={newCaption.cetip.label}
            >
              Selo CETIP
            </ResumeSelectCard>
            </CardsWrap>
            <CardTitle>Informações da captação</CardTitle>
          <CardsWrap>
          <ResumeInputCard
              handleChangeInput={(e: any) =>
                setNewCaption((c: IObject) => ({
                  ...c,
                  price: Number(moneyToNumber(e)),
                }))
              }
              blocks={{
                num: {
                  mask: Number,
                  thousandsSeparator: '.',
                },
              }}
              names="price"
              icon="databaseBlack"
              placeholder={numberToMoney(newCaption.price)}
            >Preço da cota
            </ResumeInputCard>
            <ResumeInputCard
              handleChangeInput={(e: any) =>
                setNewCaption((c: IObject) => ({
                  ...c,
                  supply: Number(moneyToNumber(e)),
                }))
              }
              blocks={{
                num: {
                  mask: Number,
                  thousandsSeparator: '.',
                },
              }}
              names="supply"
              icon="databaseBlack"
              placeholder={numberToMoney(newCaption.supply)}
            >Total de cotas
            </ResumeInputCard>
            <ResumeInputCard
            disabled
              handleChangeInput={(e: any) =>
                setNewCaption((c: IObject) => ({
                  ...c,
                  metaCaption: Number(moneyToNumber(e)),
                }))
              }
              blocks={{
                num: {
                  mask: Number,
                  thousandsSeparator: '.',
                },
              }}
              names="metaCaption"
              icon="crosshairOutlined"
              placeholder={numberToMoney(newCaption.metaCaption)}
            >Meta da captação
            </ResumeInputCard>
            <ResumeInputCard
              handleChangeInput={(e: any) =>
                setNewCaption((c: IObject) => ({
                  ...c,
                  minimumInvest: Number(moneyToNumber(e)),
                }))
              }
              blocks={{
                num: {
                  mask: Number,
                  thousandsSeparator: '.',
                },
              }}
              names="minimumInvest"
              icon="dollarsignOutlined"
              placeholder={numberToMoney(newCaption.minimumInvest)}
            >Investimento mínimo
            </ResumeInputCard>
            <ResumeSelectCard
              handleChange={(e: any) => {
                setNewCaption((c: IObject) => ({
                  ...c,
                  periodoLanc: e,
                }))
              }}
              icon="calendarOutlined"
              type="periodoLanc"
              options={options.periodoLanc}
              name={newCaption.periodoLanc.label}
            >
              Período de lançamento
            </ResumeSelectCard>
          </CardsWrap>

          <CardTitle>Rentabilidade</CardTitle>
          <CardsWrap>
          <ResumeSelectCard
              handleChange={(e: any) => {
                setNewCaption((c: IObject) => ({
                  ...c,
                  prazo: e,
                }))
              }}
              icon="checkedContractOutlined"
              type="prazo"
              options={options.prazo}
              name={newCaption.prazo.label}
            >
              Prazo de contrato
            </ResumeSelectCard>
            <ResumeSelectCard
              handleChange={(e: any) => {
                setNewCaption((c: IObject) => ({
                  ...c,
                  pagamentoJuros: e,
                }))
              }}
              icon="coinOutlined"
              type="pagamentoJuros"
              options={options.juros}
              name={newCaption.pagamentoJuros.label}
            >
              Pagamento dos juros
            </ResumeSelectCard>
            
            <ResumeSelectCard
              handleChange={(e: any) => {
                setNewCaption((c: IObject) => ({
                  ...c,
                  liquidez: e,
                }))
              }}
              icon="coinOutlined"
              type="liquidez"
              options={options.liquidez}
              name={newCaption.liquidez.label}
            >
             Liquidez
            </ResumeSelectCard>
            <ResumeSelectCard
              disabled={newCaption?.pagamentoJuros && newCaption?.pagamentoJuros.value !== 4}
              handleChange={(e: any) => {
                setNewCaption((c: IObject) => ({
                  ...c,
                  indexador: e,
                }))
              }}
              icon="stonksOutlined"
              type="indexador"
              options={options.indexador}
              name={newCaption.indexador.label}
            >
             Indexador
            </ResumeSelectCard>
            <ResumeSelectCard
            small
            disabled={newCaption?.indexador?.value === 0}
              handleChange={(e: any) => {
                setNewCaption((c: IObject) => ({
                  ...c,
                  index: e,
                }))
              }}
              icon="profitabilityOutlined"
              type="index"
              options={indexList}
              name={newCaption?.index?.label || ''}
            >
             Índice
            </ResumeSelectCard>
            <ResumeInputCard
              handleChangeInput={(e: any) =>
                setNewCaption((c: any) => ({
                  ...c,
                  profitability: Number(handleUnmask(e, '%')),
                }))
              }
              isMask="num%"
              eager
              blocks={{
                num: {
                  mask: Number,
                  scale: 2,
                  signed: false,
                  from: 0,
                  to: 100,
                },
              }}
              small
              names="resumo-juros"
              icon="profitabilityOutlined"
              placeholder={`${newCaption.profitability}%`}
            >Juros a.a.
            </ResumeInputCard>

            <ResumeSelectCard
              disabled={newCaption?.modalCaption && newCaption?.modalCaption?.value === 0}
              handleChange={(e: any) => {
                setNewCaption((c: IObject) => ({
                  ...c,
                  impostoRenda: e,
                }))
              }}
              icon="profitabilityOutlined"
              type="impostoRenda"
              options={options.ir}
              name={newCaption.impostoRenda.label}
            >
            Imposto de renda
            </ResumeSelectCard>
            <ResumeSelectCard
            
              handleChange={(e: any) => {
                setNewCaption((c: IObject) => ({
                  ...c,
                  coatizationPre: e,
                }))
              }}
              icon="loginOutlined"
              type="coatizationPre"
              options={options.coatizationPre}
              name={newCaption.coatizationPre.label}
            >
            Período de cotização de entrada
            </ResumeSelectCard>
            <ResumeSelectCard
            
            handleChange={(e: any) => {
              setNewCaption((c: IObject) => ({
                ...c,
                coatizationPos: e,
              }))
            }}
            icon="loginOutlined"
            type="coatizationPos"
            options={options.coatizationPos}
            name={newCaption.coatizationPos.label}
          >
          Período de cotização de saída
          </ResumeSelectCard>
          </CardsWrap>
          <CardTitle>Informações da captação</CardTitle>
          <CardsWrap>
          <ResumeSelectCard
            handleChange={(e: any) => {
              setNewCaption((c: IObject) => ({
                ...c,
                cupom: e,
              }))
            }}
            icon="ticketBlack"
            type="cupom"
            options={options.cupom}
            name={newCaption.cupom.label}
          >
          Uso do cupom
          </ResumeSelectCard>
          </CardsWrap>



        </Form>
        </FormDiv>
      {/* </div> */}
      <div className="align-self">
        <ButtonNext onClick={handleNext} smallTop alsoBack onClickBack={handleBack}/>
      </div>
      </>
    // </Wrapper>
  )
}

