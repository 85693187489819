import * as i from 'assets/Icons'

export interface IIcons {
  [key: string]: any
}

const icons: IIcons = {
  // circleSolid: i.Solid('circleSolid'),
  agroGreenOutlined: i.Outlined('agroGreen'),
  alertOutlined: i.Outlined('alert'),
  arrowLeftSolid: i.Solid('arrowLeft'),
  arrowRightSolid: i.Solid('arrowRight'),
  backSolid: i.Solid('back'),
  backGreenSolid: i.Solid('backGreen'),
  bellOutlined: i.Outlined('bell'),
  bellSolid: i.Solid('bell'),
  billOutlined: i.Outlined('bill'),
  birthdayOutlined: i.Outlined('birthday'),
  bottleOutlined: i.Outlined('bottle'),
  btcSolid: i.Solid('btc'),
  bugSolid: i.Solid('bug'),
  buildingOutlined: i.Outlined('building'),
  calendarClientOutlined: i.Outlined('calendarClient'),
  calendarOutlined: i.Outlined('calendar'),
  calendarSapiensOutlined: i.Outlined('calendarSapiens'),
  canceledOutlined: i.Outlined('canceled'),
  cartaoOutlined: i.Outlined('cartao'),
  cellphoneOutlined: i.Outlined('cellphone'),
  chatLeafLogo: i.Solid('chatLeafLogo'),
  chatOutlined: i.Outlined('chat'),
  chatSolid: i.Solid('chat'),
  chartArrowOutlined: i.Outlined('chartArrow'),
  client2Outlined: i.Outlined('client2'),
  clientOutlined: i.Outlined('client'),
  clientPFSolid: i.Solid('clientPF'),
  clientPJSolid: i.Solid('clientPJ'),
  clientSolid: i.Solid('client'),
  co2Outlined: i.Outlined('co2'),
  coinOutlined: i.Outlined('coin'),
  coinSolid: i.Solid('coin'),
  confirmLoadGreenOutlined: i.Outlined('confirmLoadGreen'),
  confirmationOutlined: i.Outlined('confirmation'),
  contractOutlined: i.Outlined('contract'),
  checkedContractOutlined: i.Outlined('checkedContract'),
  creditCardOutlined: i.Outlined('creditCard'),
  crosshairOutlined: i.Outlined('crosshair'),
  cryptoOutlined: i.Outlined('crypto'),
  cryptoSolid: i.Solid('crypto'),
  currencyOutlined: i.Outlined('currency'),
  dashboardOutlined: i.Outlined('dashboard'),
  dashboardSolid: i.Solid('dashboard'),
  dataDetailsOutlined: i.Outlined('dataDetails'),
  deleteSolid: i.Solid('delete'),
  docOutlined: i.Outlined('doc'),
  docSolid: i.Solid('doc'),
  dollarsignOutlined: i.Outlined('dollarsign'),
  dotSolid: i.Solid('dot'),
  downloadSolid: i.Solid('download'),
  dropdownSolid: i.Solid('dropdown'),
  editOutlined: i.Outlined('edit'),
  emailSolid: i.Solid('email'),
  errorOutlined: i.Outlined('error'),
  esgOutlined: i.Outlined('esg'),
  exchangeSolid: i.Solid('exchange'),
  eyeClosedSolid: i.Solid('eyeClosed'),
  eyeOpenSolid: i.Solid('eyeOpen'),
  folderOutlined: i.Outlined('folder'),
  h2oOutlined: i.Outlined('h2o'),
  hashOutlined: i.Outlined('hash'),
  houseGreenOutlined: i.Outlined('houseGreen'),
  infoOutlined: i.Outlined('info'),
  kanbancheck: i.Outlined('kanbancheck'),
  kanbandoc: i.Outlined('kanbandoc'),
  kanbanphone: i.Outlined('kanbanphone'),
  kanbanplay: i.Outlined('kanbanplay'),
  kanbanselfie: i.Outlined('kanbanselfie'),
  kanbansuccess: i.Outlined('kanbansuccess'),
  leafOutlined: i.Outlined('leaf'),
  leafSolid: i.Solid('leaf'),
  loadGifOutlined: i.Outlined('loadGif'),
  loanOutlined: i.Outlined('loan'),
  locationOutlined: i.Outlined('location'),
  lockClosedOutlined: i.Outlined('lockClosed'),
  lockOpenOutlined: i.Outlined('lockOpen'),
  loginOutlined: i.Outlined('login'),
  loginReverseOutlined: i.Outlined('loginReverse'),
  logoutOutlined: i.Outlined('logout'),
  mailOutlined: i.Outlined('mail'),
  maximizeOutlined: i.Outlined('maximize'),
  moneyOutlined: i.Outlined('money'),
  moneyRunSolid: i.Solid('moneyRun'),
  newClientOutlined: i.Outlined('newClient'),
  nextGreenArrowSolid: i.Solid('nextGreenArrow'),
  notificationOutlined: i.Outlined('notification'),
  paperClockOutlined: i.Outlined('paperClock'),
  paperPlainOutlined: i.Outlined('paperPlain'),
  passwordOutlined: i.Outlined('password'),
  pdfOutlined: i.Outlined('pdf'),
  phoneOutlined: i.Outlined('phone'),
  phoneSolid: i.Solid('phone'),
  plusSolid: i.Solid('plus'),
  productSolid: i.Solid('product'),
  productsOutlined: i.Outlined('products'),
  profitabilityOutlined: i.Outlined('profitability'),
  sapiensCalendarOutlined: i.Outlined('sapiensCalendar'),
  sapiensCoinSolid: i.Solid('sapiensCoin'),
  searchOutlined: i.Outlined('search'),
  securityOutlined: i.Outlined('security'),
  selfieOutlined: i.Outlined('selfie'),
  sentSolid: i.Solid('sent'),
  smsSolid: i.Solid('sms'),
  statementOutlined: i.Outlined('statement'),
  stonksOutlined: i.Outlined('stonks'),
  successOutlined: i.Outlined('success'),
  targetOutlined: i.Outlined('target'),
  teamOutlined: i.Outlined('team'),
  trashOutlined: i.Outlined('trash'),
  trashSolid: i.Solid('trash'),
  uploadSolid: i.Solid('upload'),
  userOutlined: i.Outlined('user'),
  whatsOutlined: i.Outlined('whats'),
  whatsappSolid: i.Solid('whatsapp'),
  windmillOutlined: i.Outlined('windmill'),
  windmillSolid: i.Solid('windmill'),
  withdrawSolid: i.Solid('withdraw'),
  xSolid: i.Solid('x'),
  checkOutlined: i.Outlined('check'),
  newHouseGreenSolid: i.Solid('newHouseGreen'),
  greenCheckOutlined: i.Outlined('greenCheck'),
  newPlusSolid: i.Solid('newPlus'),
  blockedSolid: i.Solid('blocked'),
  trophySolid: i.Solid('trophy'),
  newHouseGreen2: i.Outlined('newHouseGreen2'),
  lockClosedOutlined2: i.Outlined('lockClosedOutlined2'),
  newContractOutlined: i.Outlined('newContract'),
  homeSolid: i.Solid('home'),
  shieldOutlined: i.Outlined('shield'),
  vectorOutlined: i.Outlined('vector'),
  greyCheckOutlined: i.Outlined('greyCheck'),
  volumeBlack: i.Outlined('volumeBlack'),
  ticketBlack: i.Outlined('ticketBlack'),
  databaseBlack: i.Outlined('databaseBlack'),
  personCheckBlack:  i.Outlined('personCheckBlack'),
}

export default icons
