import styled from 'styled-components'

interface IStyleProps {
  gap?: number
}

export default styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props: IStyleProps) => {
    if (props.gap) return `${props.gap}px`
    return ''
  }};
`
