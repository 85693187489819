import { useContext, useRef, useCallback, useEffect } from 'react'
import { CodeInput, Button } from 'components'
import { SapiensContext } from 'context/sapiens'
import { Form } from '@unform/web'
import { useNavigate } from 'react-router-dom'
import useFetch from 'hooks/useFetch'
import { clearString, normalDateToNewDate } from 'helper/utils'
import { ContainerS, PageText, PageTitle } from './style'
import { IObject } from 'interfaces'
import { assembleBody } from './createCaption'

interface ISecurity {
  path: string
}

export default function SecurityConfirm(props: ISecurity) {
  const { path } = props
  const {
    loading,
    setLoading,
    setTitle,
    pin,
    selected,
    selectedClient,
    setNovoCupom,
    novoCupom,
    newCaption,
    setNewCaption,
    defaultCaption
  } = useContext(SapiensContext)

  const api = useFetch('register')
  const api2 = useFetch('new-caption')
  const apiCupom = useFetch('cupom')
  const apiCaption = useFetch('test')
  const navigate = useNavigate()
  const formRef = useRef(null)

  useEffect(() => {
    setLoading(false)
  }, [])

  const handleCreacteCaption = async () => {
    const body = assembleBody({ newCaption, selected })
    try {
      const r: any = await apiCaption.fetch('post', body)
      if (r.fail) throw new Error(r.errors.message)
      setTitle('Investimento criado!')
      setLoading(false)
      setNewCaption(defaultCaption)
      navigate('/confirm')
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const handleRegisterClient = async () => {
    const { address, firstName, secondName, accountType, ...data } =
      selectedClient
    const { state, ...addr } = address
    const body = {
      pin,
      name: firstName,
      [accountType.value === 0 ? 'nameMother' : 'fantasy']: secondName,
      ...data,
      address: {
        state: state.value,
        ...addr,
      },
    }

    try {
      const r = await api.fetch('post', body)
      if (r.fail) throw new Error(r.errors.message)
      setTitle(r.data.message)
      setLoading(false)
      navigate('/confirm')
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const handleCreateCupom = async () => {
    // ENDPOINT DE CRIACAO DE CUPOM
    const body = {
      options: {
        code: novoCupom.cupomCode,
        name: novoCupom.campaignType.label,
        expiresAt: normalDateToNewDate(novoCupom.cupomExpiration),
        expiresAmount: Number(novoCupom.cupomSupply),
        value: Number(novoCupom.cupomValue),
      },
    }

    try {
      const r: IObject = await apiCupom.fetch('post', body)
      if (r.fail) throw new Error(r.errors.message)
      setNovoCupom({
        campaignType: { label: 'Tipo de campanha', value: 0 },
        cupomName: '',
        cupomCode: '',
        currency: { label: 'BRL', value: 0, type: 0, price: 1 },
        cupomExpiration: '',
        cupomSupply: 0,
        cupomValue: '',
      })
      setLoading(false)
      setTitle('Cupom criado!')
      navigate('/confirm')
    } catch (err: any) {
      setLoading(false)
      console.log(err)
    }
  }

  const onClick = () => {
    setLoading(true)
    switch (path) {
      case 'newClient':
        return handleRegisterClient()
      case 'newCaption':
        return handleCreacteCaption()
      case 'newCupom':
        return handleCreateCupom()
      default:
        console.log('caiu no default', path)
    }
    return
  }

  return (
    <ContainerS>
      <PageTitle>Confirmação de segurança</PageTitle>
      <PageText>
        Digite aqui o seu PIN de 4 dígitos para confirmar a operação.
      </PageText>
      <Form onSubmit={onClick} ref={formRef}>
        <ContainerS>
          <CodeInput
            type="text"
            name="confirm"
            maxLength={4}
            required
            autoFocus
            security
          />
          <Button $loading={loading} rounded>
            CONFIRMAR
          </Button>
        </ContainerS>
      </Form>
    </ContainerS>
  )
}
