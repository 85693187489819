import { Column } from 'styles/index'
import { PagePathing, PageTitle, UploadImg, ButtonNext } from 'components/index'
import { Form } from '@unform/web'
import { Subtitles, Content, SmallContent, Wrap, FormDiv } from './style'
import { useContext, useState, useEffect } from 'react'
import { SapiensContext } from 'context/sapiens'
import * as Yup from 'yup'

const content = [
  {
    title: 'Contrato CCB',
    placeHolder: 'Upload de Contrato CCB',
    key: 'contratoCCB',
  },
  {
    title: 'Alienação fiduciária',
    placeHolder: 'Upload Doc. de Alienação fiduciária',
    key: 'alienFiduciaria',
  },
  {
    title: 'Aval dos sócios',
    placeHolder: 'Upload Contrato Aval dos sócios',
    key: 'avalSocios',
  },
  {
    title: 'CDB’s',
    placeHolder: 'Upload Doc. CDB’s',
    key: 'contratoCDB',
  },
]

const schemaContracts = Yup.object()
  .shape({
    contratoCCB: Yup.string().min(1),
    alienFiduciaria: Yup.string().min(1),
    avalSocios: Yup.string().min(1),
    contratoCDB: Yup.string().min(1),
  })
  .typeError('Este input deve ser preenchido corretamente')

export default function ({ handleNext, handleBack} : any) {
  const { newCaption, setNewCaption } = useContext(SapiensContext)
  const [allowedNext, setAllowedNext] = useState<boolean>(false)

  // useEffect(() => {
  //   const validateInputs = async () => {
  //     try {
  //       await schemaContracts.validate(newCaption, { abortEarly: false })
  //       setAllowedNext(true)
  //     } catch (err) {
  //       console.log(err)
  //       setAllowedNext(false)
  //     }
  //   }
  //   validateInputs()
  // }, [newCaption])

  // const handleNext = () => {}
  return (
    <>
      <PagePathing
        withoutMarginLeft
      >{`Gestão de produtos >  Investimentos  > Nova captação  > Resumo da captação > Layout`}</PagePathing>
      <PageTitle withoutMarginLeft detailsMargin>
        Detalhes Jurídicos
      </PageTitle>
      <FormDiv>
        <Form onSubmit={() => {}}>
          <Wrap>
            {content.map((contract) => {
              return (
                <Column gap={24}>
                  <Column gap={16}>
                    <Subtitles>{contract.title}</Subtitles>
                    <Content>
                      <span>Selecione um arquivo </span>ou arraste e solte aqui
                    </Content>
                    <UploadImg
                      noWeigth
                      name={contract.key}
                      id={contract.key}
                      iconName="pdfBlack"
                      placeholder={contract.placeHolder}
                      accept=".pdf"
                      setPreview64={(pic: string) => {
                        setNewCaption((c: any) => ({
                          ...c,
                          [contract.key]: pic,
                        }))
                      }}
                    />
                  </Column>
                  <Column gap={5}>
                    <SmallContent>
                      <span>Recomendações do upload:</span>
                    </SmallContent>
                    <SmallContent> • Documento no formato PDF</SmallContent>
                    <SmallContent>
                      {' '}
                      • Tamanho máximo do arquivo: 10MB
                    </SmallContent>
                  </Column>
                </Column>
              )
            })}
          </Wrap>
        </Form>
      </FormDiv>
      <ButtonNext onClick={handleNext}  smallTop alsoBack onClickBack={handleBack}/>
    </>
  )
}
