import styled from 'styled-components'
import { SketchPicker } from 'react-color'
interface IStyle {
  color?: string
  disabled?: boolean
}
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`

export const ColorTab = styled.div`
  /* opacity: ${(props: IStyle) => {
    if (props.disabled) return 0.3
    return 1
  }}; */
  width: 14px;
  height: 50px;
  background-color: ${(props: IStyle) => {
    if (props.color) return props.color
    return '#757575'
  }};
`

export const ColorInput = styled.div`
  box-sizing: border-box;
  width: 301px;
  height: 50px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #dcdce4;
  padding: 15px 16px;
  font-size: 14px;
  font-weight: 400;
  background: ${(props: IStyle) => {
    if (props.disabled) return '#EEEEEE'
    return '#F9F9F9'
  }};
  color: ${(props: IStyle) => {
    if (props.disabled) return '#BDBDBD'
    return '#9C948C'
  }};
  border: ${(props: IStyle) => {
    if (props.disabled) return '1px solid #F5F5F5'
    return ' 1px solid #DCDCE4'
  }};

&:hover {
    background: #8f888133;
  }
`

export const Picker = styled(SketchPicker)`
  position: absolute;
  left: 0;
  top: 50px;
  z-index: 20;
`
