import { ProgressBar } from 'components'
import { icons, images, ribbons } from 'helper'
import { MouseEvent } from 'react'
import { IImages } from 'helper/images'
import { IRibbons } from 'helper/ribbons'
import { IIcons } from 'helper/icons'
import EsgCard from './style'

interface ICardProps {
  extraIcon?: keyof IIcons
  ribbon?: keyof IRibbons
  green?: boolean
  aluguelVerde?: boolean
  image?: keyof IImages
  imageUrl?: string
  children: string
  disabled?: boolean
  value: string
  months: number
  parcel: number | string
  rentability?: string
  progress: number
  paid: string
  isReservation?: boolean
  isCanceled?: boolean
  isBlocked?: boolean
  isChanged?: {
    value: string
    parcel: string
    paid: string
  }
  tip?: string
  meta?: string
  deadline?: number
  onClick?: (_e: MouseEvent<HTMLElement>) => void
}

export default function EsgCardComponent(props: ICardProps) {
  const {
    // isNew,
    extraIcon,
    isReservation,
    isChanged,
    children,
    image,
    imageUrl,
    value,
    months,
    ribbon,
    rentability,
    progress,
    isBlocked,
    // News
    tip,
    meta,
    aluguelVerde,
  } = props

  return (
    <EsgCard {...props}>
      {image ? (
        <div
          className="image"
          style={{ backgroundImage: `url(${images[image]})` }}
        />
      ) : (
        <div
          className="image"
          style={{ backgroundImage: `url(${imageUrl || images.blank})` }}
        />
      )}
      {ribbon && <img className="ribbon" src={ribbons[ribbon]} alt="ribbon" />}
      {rentability && (
        <span className="rentability">
          Rentabilidade: <b>{rentability}% a.a.</b>
        </span>
      )}
      {extraIcon && (
        <div className="extra-icon">
          <img src={icons[extraIcon]} alt="card-extra-icon" />
        </div>
      )}

      {isBlocked && (
        <div className="block-icon">
          <img src={icons.bigLockOutlined} alt="block-icon" />
        </div>
      )}
      <span className="title">{children}</span>

      <div className="row">
        <span>
          {isChanged ? isChanged.value : 'Saldo investido:'}
          <b>{value}</b>
        </span>
        {aluguelVerde ? (
          <span>
            Contrato: <b>{meta}</b>
          </span>
        ) : (
          <span>
            Prazo: <b>Até {months} meses</b>
          </span>
        )}
      </div>
      <ProgressBar progress={progress} isReservation={isReservation} />
      {aluguelVerde ? (
        <div className="row">
          <span>
            <b>{tip}</b> Realizado
          </span>
          <span>
            Prazo: <b>{months} meses</b>
          </span>
        </div>
      ) : (
        <div className="row">
          <span>{tip}</span>
          <span>{meta}</span>
        </div>
      )}
    </EsgCard>
  )
}
