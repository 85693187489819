// import { MouseEvent } from 'react'
import { FileInput } from 'components'
import { icons } from 'helper'
import { UploadImg, Ancor, ButtonIcon } from './style'
import { Outlined } from 'assets/Icons'
import { useContext, useEffect, useState } from 'react'
import { SapiensContext } from 'context/sapiens'

interface IUploadImgProps {
  iconName?: string
  placeholder?: string
  setPreview?: any
  setPreview64?: any
  name?: string
  id?: string
  accept?: string
  noWeigth?: boolean 
}

export default function uploadImg({
  iconName = 'pictureBlack',
  placeholder = 'Upload de imagem',
  setPreview = () => {},
  setPreview64 = () => {},
  name='uploadImg',
  id="uploadImg",
  accept="image/*",
  noWeigth=false,
}: IUploadImgProps) {
  const [hasFile, setHasFile] = useState<any>(false)
  const [size, setSize] = useState<number>(10)
  // const { setPreview, setPreview64 } = useContext(SapiensContext)

  const clearInput = () => {
    const inputElement: any = document.getElementById(id)
    if (inputElement) {
      inputElement.value = ''
    }
    setPreview('')
    setPreview64('')
    setHasFile(false)
  }

  // useEffect(() => {

  // }, [size])

  return (
    <Ancor>
      <UploadImg htmlFor={name} complete={hasFile} noWeigth={noWeigth}>
        <div className="row">
          <img src={Outlined(iconName)} className="icon" />
          <p className="placeholder">{hasFile?.name || placeholder}</p>
        </div>
        <FileInput
          setSize={(size: number) => setSize(size)}
          id={id}
          name={name}
          getFile={(file: any) => setHasFile(file)}
          setPreview={setPreview}
          setPreview64={setPreview64}
          accept={accept}
        />
        <div className="row">
          <p className="weight">
            {noWeigth ? '' : hasFile ? `${Math.round(hasFile.size / 1024)}KB` : 'Máx.: 10MB'}
          </p>
          {!hasFile && (
            <img src={Outlined('uploadBlack')} alt="upload" className="icon" />
          )}
        </div>
      </UploadImg>
      {hasFile && (
        <ButtonIcon
          src={Outlined('deleteBlack')}
          alt="upload"
          className="icon"
          onClick={() => clearInput()}
          style={{ zIndex: 21 }}
        />
      )}

      {/* {size > 10000000 && } */}
    </Ancor>
  )
}
