import { IMAGES } from 'configs/configs.js'

export const basic = `${IMAGES.URL}/ribbon/basic.svg`
export const crypto = `${IMAGES.URL}/ribbon/crypto.svg`
export const personal = `${IMAGES.URL}/ribbon/personal.svg`
export const pro = `${IMAGES.URL}/ribbon/pro.svg`
export const soon = `${IMAGES.URL}/ribbon/soon.svg`
export const problack = `${IMAGES.URL}/ribbon/problack.svg`
export const basicblack = `${IMAGES.URL}/ribbon/basicblack.svg`
export const personalblack = `${IMAGES.URL}/ribbon/personalblack.svg`
export const soonblack = `${IMAGES.URL}/ribbon/soonblack.svg`
