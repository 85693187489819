import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'

interface Props {
  name: string
  children: JSX.Element
}

const Checkbox: React.FC<Props> = ({ name, children }) => {
  const inputRefs = useRef<any>(null)

  const { fieldName, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (ref: HTMLInputElement) => {
        if (ref.checked) {
          return ref.value
        }
        return ''
      },
      clearValue: (ref: HTMLInputElement) => {
        ref.checked = false
      },
      setValue: (ref: HTMLInputElement, values: string) => {
        if (values === ref.id) {
          ref.checked = true
        }
      },
    })
  }, [fieldName, registerField])

  return (
    <>
      {React.Children.map(children, (child: any) =>
        React.cloneElement(child, {
          name: fieldName,
          type: 'checkbox',
          ref: (ref: HTMLInputElement) => {
            inputRefs.current = ref
          },
        })
      )}
    </>
  )
}

export default Checkbox
