import { useEffect } from 'react'
import { CONNECTION } from '../configs/configs.js'

const useSocket = (callback: any, unmountSock?: any) => {
  useEffect(() => {
    import('socket.io-client')
      .then(({ default: io }) => io(CONNECTION.SOCKET_URL))
      .then((socket) => callback(socket))
      .catch((error) => {
        console.error(error.message)
        callback({})
      })
  }, [])

  useEffect(() => {
    return () => {
      unmountSock?.connected && unmountSock.off()
    }
  }, [unmountSock])
}

export default useSocket
