import { IObject } from 'interfaces'

const buildStatus = (rawData: any, item: IObject) =>
  (rawData || []).map((dt: IObject) => ({
    selected: dt.id === String(item.status.id),
    value: dt.id,
    label: dt.name,
    color: dt.color,
    rule: dt.moveable,
  }))

export default { buildStatus }
