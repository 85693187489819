import { BugCard } from 'components'
import { SapiensContext } from 'context/sapiens'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect, useState } from 'react'
import { CardsContainer } from 'styles'
import { Container, DivRow } from './style'

interface SelectList {
  label: string
  color: string
}

interface Card {
  createdAt: string
  id: string
  message: string
  subject: {
    label: string
    color: string
  }
  type: number
  updatedAt: string
}

interface IProps {
  color: string
  status: string
  user: string
}

export default (props: IProps) => {
  const { status, color, user } = props
  const { newCard, setNewCard } = useContext(SapiensContext)
  const [cards, setCards] = useState<any>([])
  const [selectList, setSelectetList] = useState<any>([])
  const api = useFetch('report')

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const r = await api.fetch('get', {}, `report?type=bug`)
        if (r.fail) throw new Error()
        const { response } = r.data
        const filteredCards = response.map((item: Card) => {
          return {
            date: new Date(item.createdAt),
            status: item.message,
            place: item.subject.label,
            color: item.subject.color,
          }
        })
        setCards(filteredCards)
        const list = await api.fetch('get', {}, `report/list-topics`)
        if (list.fail) throw new Error()
        const { response: res } = list.data
        const select = res.map((item: SelectList) => {
          return {
            label: item.label,
            value: item.label,
            color: item.color,
          }
        })
        setSelectetList(select)
      } catch (e) {
        console.error(e)
      }
    }
    fetchCards()
  }, [newCard])

  return (
    <Container>
      <p className="bold-text" style={{ marginBottom: '7px' }}>
        Relatório de atendimento{' '}
      </p>
      <p className="bold-text" style={{ marginBottom: '10px' }}>
        {user}
      </p>
      <DivRow width={108} marginBottom={55}>
        <div className="ball-icon" style={{ background: color }} />
        <p>{status}</p>
      </DivRow>

      <DivRow width={350} marginBottom={25}>
        <p className="bold-text">Relatar Problemas</p>
        <button type="button" className="add" onClick={() => setNewCard(true)}>
          Adicionar +
        </button>
      </DivRow>
      <CardsContainer
        overflow
        scrollTrack="#f9f9f9"
        withoutMarginLeft
        width={'800px'}
        height={cards.length === 0 ? '800px' : ''}
      >
        {newCard && <BugCard isNewCard selectList={selectList} type="bug" />}
        <BugCard isNewCard={false} reports={cards} />
      </CardsContainer>
    </Container>
  )
}
