import { Card, PageTitle } from 'components'
import icons from 'helper/icons'
import {
  toCapitalizeCase,
  numberToMoney,
  numberToCellphone,
  maskCPForCNPJ,
} from 'helper/utils'
import useFetch from 'hooks/useFetch'
import { IObject } from 'interfaces'
import { useEffect, useState } from 'react'
import { SubContentContainer, FlexContainer, CardsWrap } from '../style'

interface IImovel {
  id: any
}

interface IData {
  [key: string]: any
}

export default (props: IImovel) => {
  const [propertyData, setPropertyData] = useState<IData>({})
  const [addressData, setAddressData] = useState<IData>({})
  const [ownerData, setOwnerData] = useState<IData>({})
  const { id } = props

  const api = useFetch(`/product/aluguel-verde/contract/property?id=${id}`)

  useEffect(() => {
    api.fetch('get').then((res) => {
      if (res.fail) throw new Error()
      const { response } = res.data
      setPropertyData(response)
      setAddressData(response.address)
      setOwnerData(response.owner)
    })
  }, [])

  return (
    <SubContentContainer>
      <CardsWrap subcard>
        <FlexContainer>
          <img className="icon" src={icons.homeSolid} alt="card icon" />
          <PageTitle>Dados do imóvel</PageTitle>
        </FlexContainer>
        <Card
          inverted
          value="Tipo de imóvel"
          icon="homeSolid"
          blackIcon
          detailsCard
          large
          withoutMarginLeft
        >
          {propertyData?.type ? toCapitalizeCase(propertyData?.type) : '--'}
        </Card>
        <Card
          inverted
          value="Endereço"
          icon="targetOutlined"
          blackIcon
          detailsCard
          extraWide
        >
          {addressData?.address ? toCapitalizeCase(addressData?.address) : '--'}
        </Card>
        <Card
          inverted
          value="Cidade"
          icon="calendarSapiensOutlined"
          blackIcon
          detailsCard
          withoutMarginLeft
        >
          {addressData?.city ? toCapitalizeCase(addressData?.city) : '--'}
        </Card>
        <Card
          inverted
          value="UF"
          icon="calendarSapiensOutlined"
          blackIcon
          detailsCard
        >
          {addressData?.state || '--'}
        </Card>
        <Card
          inverted
          value="Bairro"
          icon="calendarSapiensOutlined"
          blackIcon
          detailsCard
          large
        >
          {addressData?.neighborhood
            ? toCapitalizeCase(addressData?.neighborhood)
            : '--'}
        </Card>
        <Card
          inverted
          value="CEP"
          icon="calendarSapiensOutlined"
          blackIcon
          detailsCard
          withoutMarginLeft
        >
          {addressData?.zipcode}
        </Card>
        <Card
          inverted
          value="Número"
          icon="calendarSapiensOutlined"
          blackIcon
          detailsCard
          withoutMarginLeft
        >
          {addressData?.addressNumber}
        </Card>
        <Card
          inverted
          value="Complemento"
          icon="calendarSapiensOutlined"
          blackIcon
          detailsCard
          large
        >
          {addressData?.complement
            ? toCapitalizeCase(addressData?.complement)
            : '--'}
        </Card>
        <Card
          inverted
          value="Valor do aluguel"
          icon="calendarSapiensOutlined"
          blackIcon
          detailsCard
          withoutMarginLeft
        >
          {propertyData?.rentValue
            ? `R$ ${numberToMoney(Number(propertyData?.rentValue) / 100)}`
            : '--'}
        </Card>
        <Card
          inverted
          value="Valor do condomínio"
          icon="calendarSapiensOutlined"
          blackIcon
          detailsCard
          withoutMarginLeft
        >
          {propertyData?.condominiumValue
            ? `R$ ${numberToMoney(
                Number(propertyData?.condominiumValue) / 100
              )}`
            : '--'}
        </Card>
        <Card
          inverted
          value="Valor do IPTU"
          icon="calendarSapiensOutlined"
          blackIcon
          detailsCard
          withoutMarginLeft
        >
          {propertyData?.iptuValue
            ? `R$ ${numberToMoney(Number(propertyData?.iptuValue) / 100)}`
            : '--'}
        </Card>
        <FlexContainer>
          <img className="icon" src={icons.clientOutlined} alt="card icon" />
          <PageTitle>Dados do proprietário</PageTitle>
        </FlexContainer>
        <Card
          inverted
          value="Nome"
          icon="mailOutlined"
          blackIcon
          large
          detailsCard
          withoutMarginLeft
        >
          {ownerData?.name || '--'}
        </Card>
        <Card
          inverted
          value="CPF"
          icon="mailOutlined"
          blackIcon
          large
          detailsCard
          withoutMarginLeft
        >
          {ownerData?.documentNumber
            ? maskCPForCNPJ(ownerData?.documentNumber)
            : '--'}
        </Card>
        <Card
          inverted
          value="Data de nascimento"
          icon="calendarSapiensOutlined"
          blackIcon
          large
          detailsCard
          withoutMarginLeft
        >
          {ownerData?.birthday || '--'}
        </Card>
        <Card
          inverted
          value="E-mail"
          icon="mailOutlined"
          blackIcon
          large
          detailsCard
          withoutMarginLeft
        >
          {ownerData?.email || '--'}
        </Card>
        <Card
          inverted
          value="Telefone"
          icon="calendarSapiensOutlined"
          blackIcon
          large
          detailsCard
          withoutMarginLeft
        >
          {numberToCellphone(ownerData?.phoneNumber) || '--'}
        </Card>
      </CardsWrap>
    </SubContentContainer>
  )
}
