import styled from 'styled-components'

interface IStyleProps {
  green?: boolean
  greenLoad?: boolean
}

export default styled.img`
  animation: loading-gif-spin infinite 2.5s linear;
  pointer-events: none;
  filter: ${(props: IStyleProps) => {
    if (props.green) return ''
    if (props.greenLoad)
      return 'invert(64%) sepia(96%) saturate(402%) hue-rotate(86deg) brightness(93%) contrast(112%)'
    return 'invert(100%) sepia(0%) saturate(7496%) hue-rotate(289deg) brightness(105%) contrast(105%)'
  }};

  @keyframes loading-gif-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
