import styled from 'styled-components'
import { ITheme } from 'styles/Themes'

interface IStyleProps {
  black?: boolean
  disabled?: boolean
  green?: boolean
  wide?: boolean
  value?: number
  ribbon?: string
  theme: ITheme
  isCanceled?: boolean
  isBlocked?: boolean
}

export default styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 190px;
  width: 315px;
  border-radius: 10px;
  font-size: 10px;
  opacity: ${(props: IStyleProps) => {
    if (props.isCanceled || props.isBlocked) return '0.2'
    return ''
  }};
  cursor: ${(props: IStyleProps) => {
    if (props.disabled) return ''
    return 'pointer'
  }};
  box-shadow: 0px 3px 4px 0px ${(props: IStyleProps) => props.theme.boxShadow};

  background: ${(props: IStyleProps) =>
    props.theme.card.backgroundColor.secondary};
  z-index: 0;

  padding-bottom: 5px;

  .image {
    background-size: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-height: 110px;
    z-index: 0;
    filter: brightness(50%);
  }

  .extra-icon {
    position: absolute;
    filter: invert(100%) sepia(49%) saturate(2%) hue-rotate(333deg)
      brightness(104%) contrast(101%);
    background: transparent;
    height: 25px;
    width: 25px;
    margin-left: 267px;
    bottom: 95px;
    margin-top: 80px;
    z-index: 2;
  }

  .block-icon {
    position: absolute;
    z-index: 3;
    margin: 50px 139px 75px 149px;
  }

  .title {
    position: absolute;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    z-index: 2;
    color: ${(props: IStyleProps) => props.theme.card.fontColor.secondary};
    position: relative;
    top: -20px;
    left: 20px;
    z-index: 2;
  }

  .ribbon {
    position: absolute;
    top: -4px;
    left: -4px;
    width: 57px;
    height: 60px;
    z-index: 2;
  }

  span {
    font-size: 10px !important;
    font-weight: bold;
    height: auto;
    z-index: 2;
  }

  .rentability {
    position: absolute;
    right: 20px;
    top: 15px;
    line-height: 12px;
    letter-spacing: 0em;
    color: ${(props: IStyleProps) => props.theme.card.fontColor.secondary};

    b {
      background: ${(props: IStyleProps) => {
        if (props.ribbon === 'pro')
          return `${props.theme.card.ribbon.backgroundColor.pro}`
        if (props.ribbon === 'soon')
          return `${props.theme.card.ribbon.backgroundColor.soon}`
        if (props.isCanceled) return '#070707'
        return `${props.theme.card.ribbon.backgroundColor.basic}`
      }};
      padding: 3px 8px;
      border-radius: 100px;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin: 5px 20px 10px 20px;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: ${(props: IStyleProps) => props.theme.card.fontColor.esg};

    b {
      font-weight: 700;
      color: ${(props: IStyleProps) => props.theme.card.fontColor.esgBold};
    }
  }
`
