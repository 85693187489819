import { images, icons } from 'helper'
import { MouseEvent } from 'react'
import { IImages } from 'helper/images'
import { IIcons } from 'helper/icons'
import FundingCard from './style'

interface ICardProps {
  children: string
  image?: keyof IImages
  imageUrl?: string
  green?: boolean
  disabled?: boolean
  minValue: number
  months: number
  flavor: string
  modality: string
  onClick?: (_e: MouseEvent<HTMLElement>) => void
}

const flavors: { [key: string]: [string, keyof IIcons] } = {
  eco: ['Neutralização Ecológica', 'leafSolid'],
}

export default function FundingCardComponent(props: ICardProps) {
  const { children, image, minValue, months, flavor, modality, imageUrl } =
    props
  return (
    <FundingCard {...props}>
      {image && (
        <div
          className="image"
          style={{ backgroundImage: `url(${images[image]})` }}
        />
      )}
      {imageUrl && (
        <div
          className="image"
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
      )}

      <span className="title">{children}</span>

      <div className="row">
        <span>
          Invest. Min: <b>R$ {minValue}</b>
        </span>
        <span>
          Prazo: <b>Até {months} meses</b>
        </span>
      </div>
      <div className="row">
        <span className="flavor">
          <img src={icons[flavors[flavor][1]]} alt="flavor icon" />
          {flavors[flavor][0]}
        </span>
        <span>
          Modalidade: <b>{modality}</b>
        </span>
      </div>
    </FundingCard>
  )
}
