import styled from 'styled-components'

interface IStyleProps {
  color?: string
  marginLeft?: boolean
  subcard?: boolean
}

export const ContentContainer = styled.div`
  position: absolute;
  height: 90%;
  width: 100%;
  .black-icon {
    filter: invert(0%) sepia(24%) saturate(0%) hue-rotate(239deg)
      brightness(100%) contrast(95%);
  }

  .title-page {
    font-weight: 700;
    font-size: 20px;
    margin: 0;
  }

  .txt {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    margin: 0;
  }

  .column-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    margin: 0;
    color: ${(props: IStyleProps) => {
      if (props.color === 'blue') return '#03989E'
      if (props.color === 'green') return '#0BF569'
      if (props.color === 'red') return '#D61212'
      return '#2B2B2B'
    }};
  }

  .image-button {
    cursor: pointer;
  }
`
export const SubContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 100%;
  height: 100%;
  padding: 0;
  width: 94%;
  /* background-color: #f5f4f3; */
  margin-top: 60px;
  margin-left: 50px;
`
export const Img = styled.img`
  filter: ${(props: IStyleProps) => {
    if (props.color === 'blue')
      return 'invert(40%) sepia(37%) saturate(5511%) hue-rotate(158deg) brightness(95%) contrast(98%);'
    if (props.color === 'green')
      return 'invert(74%) sepia(42%) saturate(1286%) hue-rotate(83deg) brightness(99%) contrast(95%);'
    if (props.color === 'red')
      return 'invert(17%) sepia(84%) saturate(2742%) hue-rotate(351deg) brightness(105%) contrast(102%);'
    return 'invert(15%) sepia(61%) saturate(10%) hue-rotate(330deg) brightness(97%) contrast(97%);'
  }};

  margin-left: ${(props: IStyleProps) => {
    if (props.marginLeft) return '20px'
    return '0'
  }};

  max-width: 16px;
`
export const CardsWrap = styled.div`
  display: flex;
  position: absolute;
  flex-direction: row;
  max-height: ${(props: IStyleProps) => (props.subcard ? '90%' : '70%')};
  height: auto;
  flex-wrap: wrap;
  width: ${(props: IStyleProps) => (props.subcard ? '95%' : '75%')};
  overflow-y: scroll;
  padding: 0 10px 10px 0;
  margin: 20px 0 20px 0;
  gap: 20px;

  ::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }
`
export const FlexContainer = styled.div`
  display: flex;
  margin-left: 20px;
`

export const Span = styled.div`
  display: flex;
  width: 217px;
  color: #070707;
  max-height: 26px;
  text-align: center;
  margin-top: 10px;
  align-items: center;
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.2px;
  img {
    margin-right: 14px;
    position: relative;
    left: 0;
    width: 16px;
  }
`
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 72px;
  width: 175px;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0px 3px 4px 0px #00000040;
  background: '#ffffff';
  .title {
    color: '#070707';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    margin: 10px 0 0 0;
    letter-spacing: 0.2px;
  }
  .iconClickable {
    max-width: 24px;
    height: 24px;
    margin: 0 0 12px 25px;
  }
  .selectIcon {
    max-width: 15.79px;
    max-height: 15.79px;
    margin: 0 0 50px 150px;
  }
  span {
    font-weight: 300;
    font-style: normal;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.2px;
  }
`
export const Status = styled.span`
  max-width: 300px;
  margin-top: 10px;
  display: flex;
  align-items: baseline;
  margin-left: ${(props: IStyleProps) => {
    return props.marginLeft ? '10px' : '0'
  }};
`
