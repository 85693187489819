import styled from 'styled-components'

interface IStyleProps {
  extended?: boolean
  withoutMarginLeft?: boolean
  smallMarginTop?: boolean
  addPaddingTop?: boolean
  noMarginTop?: boolean
}

const TRANSITION_TIME = '1s'

export default styled.div`
  transition: margin-left ${TRANSITION_TIME};
  margin-top: ${(props: IStyleProps) => {
    if (props.noMarginTop) return '0'
    if (props.smallMarginTop) return '36px'
    return '64px'
  }};
  margin-left: ${(props: IStyleProps) => {
    if (props.extended) return '260px'
    if (props.withoutMarginLeft) return '0'
    return '81px'
  }};
  padding-top: ${(props: IStyleProps) => {
    if (props.addPaddingTop) return '30px'
    return '0'
  }};
  padding-bottom: 20px;
`
