import { useEffect, useState } from 'react'
import { AccountStatus, ColorSelect, Button } from 'components'
import { icons } from 'helper'
import { onlyNumeric } from 'helper/utils'
import { useFetch } from 'hooks'
import { IObject } from 'interfaces'
import { ExtendedContainer } from './style'

interface IChatProps {
  name: string
  title: string
  subtitle?: string
  document?: string
  status?: {
    color: string
    name: string
  }
  socket?: IObject
  room?: string
  stage?: IObject
  subjectId?: string
}

export default (props: IChatProps) => {
  const {
    name,
    title,
    stage,
    subtitle,
    status,
    document,
    socket,
    room = 'cg',
    subjectId,
  } = props

  const [message, setMessage] = useState<string>('')
  const [messageList, setMessageList] = useState<any>([])

  const api = useFetch('chat/send-message')
  const getMessagesApi = useFetch()

  useEffect(() => {
    const url = subjectId
      ? `chat?subjectId=${subjectId}`
      : `chat?document=${document}&room=${room}`

    getMessagesApi.fetch('get', {}, url).then(({ data }) => {
      const m = subjectId ? data?.response.reverse() : data?.response
      setMessageList(m || [])
    })
  }, [])

  const onSubmit = async () => {
    const buffer = message;
    setMessage('');
    setMessageList((m = []) => [...m, { origin: 1, message: buffer }]);
    api.fetch('post', { document, room, message: buffer })
      .catch((err: any) => {
        setMessageList((m = []) => m.pop());
        console.error(err.message);
      });
  }

  useEffect(() => {
    if (!socket || !socket.connected) return
    socket.on(`adm_refresh_${onlyNumeric(document || '')}`, (data: object) => {
      setMessageList((m = []) => [...m, data])
    })
  }, [socket])

  return (
    <ExtendedContainer>
      <div className="left-menu-container">
        <div>
          <p className="bold-text">{title}</p>
          <p className="bold-text">{name}</p>
          <p className="bold-text">{subtitle}</p>
          {status && (
            <AccountStatus color={status.color} status={status.name} />
          )}
        </div>
        {stage && (
          <div>
            <p className="bold-text">{stage.title}</p>
            <ColorSelect selection={stage.options} width="200px" />
          </div>
        )}
        <div style={{ height: '23%' }} />
      </div>
      <div className="main-container">
        <div className="chat-window">
          {messageList.map((item: any, i: number) => {
            return item.origin === 0 ? (
              <div className="mess-conteiner-user" key={`message_${i}`}>
                <div className="user-message">{item.message}</div>
              </div>
            ) : (
              <div className="mess-conteiner-sapiens" key={`message_${i}`}>
                <img src={icons.chatLeafLogo} alt="leaf-logo" />
                <div className="sapiens-message">{item.message}</div>
              </div>
            )
          })}
        </div>
        <div className="input-container">
          <textarea
            name="message"
            placeholder="Digite aqui..."
            onChange={(ev) => setMessage(ev.target.value)}
            value={message}
          />
          <Button
            onClick={onSubmit}
            color="white"
            $loading={false}
            secondary
            xSmall
            rounded
          >
            ENVIAR
          </Button>
        </div>
      </div>
    </ExtendedContainer>
  )
}
