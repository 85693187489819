import { icons } from 'helper'
import LoadGif from './style'

interface IGifProps {
  black?: boolean
  confirm?: boolean
  greenLoad?: boolean
}

export default function LoadGifComponent(props: IGifProps) {
  const { black, confirm, ...rest } = props
  return (
    <LoadGif
      green={black}
      src={confirm ? icons.confirmLoadGreenOutlined : icons.loadGifOutlined}
      alt="loading"
      {...rest}
    />
  )
}
