import {
  Card,
  ResumeInputCard,
  PagePathing,
  ClickableCard,
  PageTitle,
} from 'components'
import Tooltip from 'react-tooltip'
import icons, { IIcons } from 'helper/icons'
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { Row } from 'styles'
import { useFetch } from 'hooks'
import Imovel from 'pages/GestaoProdutos/AluguelVerde/DetalhesContrato/Imovel'
import Cliente from 'pages/GestaoProdutos/AluguelVerde/DetalhesContrato/Cliente'
import Seguro from 'pages/GestaoProdutos/AluguelVerde/DetalhesContrato/Seguro'
import { numberToMoney } from 'helper/utils'
import { CardsWrap, ContentContainer, Img, Status } from './style'

interface IData {
  [key: string]: any
}

interface IDetailContractProps {
  title: string
  isChangable?: boolean
  id?: any
  column: {
    name: string
    color: string
    icon: keyof IIcons
  }
  setExtendedMenu: Dispatch<SetStateAction<ReactElement | boolean>>
}

export default (props: IDetailContractProps) => {
  const {
    setExtendedMenu,
    isChangable,
    title,
    id,
    column: { name, color, icon },
  } = props

  const [contractData, setContractData] = useState<IData>({})

  const api = useFetch(`product/aluguel-verde/contract?id=${id}`)

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const r = await api.fetch('get')
        if (r.fail) throw new Error()
        const { response } = r.data
        setContractData(response)
      } catch (e) {
        console.error(e)
      }
    }
    fetchUsers()
  }, [])

  return (
    <ContentContainer color={color}>
      <PagePathing withoutMarginLeft>
        {`Gestão de produtos > Aluguel Verde > Contratos > ${title}`}
      </PagePathing>
      <PageTitle detailsMargin withoutMarginLeft>
        {title}
        {isChangable && (
          <span style={{ marginLeft: 20 }}>
            <Img
              src={icons.lockClosedOutlined2}
              alt="lock-icon"
              data-for="lock"
              data-tip="Finalizar captação"
              className="image-button"
            />
            <Tooltip place="bottom" id="lock" effect="solid" />
            <Img
              src={icons.blockedSolid}
              alt="trash-icon"
              className="black-icon image-button"
              data-for="trash"
              data-tip="Cancelar captação"
              marginLeft
            />
            <Tooltip place="bottom" id="trash" effect="solid" />
          </span>
        )}
      </PageTitle>
      <Status marginLeft>
        <Status>
          <Img src={icons.contractOutlined} alt="lock-icon" />
          <span style={{ marginLeft: 10, marginBottom: 0 }}>
            Contrato {contractData?.contractType?.toUpperCase()}
          </span>
        </Status>
        <Status marginLeft>
          <Img src={icons[icon]} alt="lock-icon" color={color} />
          <span style={{ marginLeft: 10, marginBottom: 0 }}>
            {name.substring(0, name.length - 1)}
          </span>
        </Status>
      </Status>
      <CardsWrap>
        <Row gap={20}>
          <Card
            inverted
            variation={''}
            value="Tipo de contrato"
            icon="homeSolid"
            blackIcon
            detailsCard
          >
            Residencial
          </Card>
          <Card
            inverted
            variation={''}
            value="Tipo de serviço"
            icon="calendarSapiensOutlined"
            blackIcon
            detailsCard
          >
            {contractData.contractType || '--'}
          </Card>
          <Card
            inverted
            variation={''}
            value="Forma de pagamento"
            icon="calendarSapiensOutlined"
            blackIcon
            detailsCard
          >
            {contractData.paymentType || '--'}
          </Card>
          <Card
            inverted
            variation={''}
            value="Qtd. parcelas"
            icon="calendarSapiensOutlined"
            blackIcon
            detailsCard
          >
            {`${contractData.installmentsCard || '--'}x`}
          </Card>
        </Row>
        <Row gap={20}>
          <Card
            inverted
            variation={''}
            value="Valor do contrato"
            icon="mailOutlined"
            blackIcon
            large
            detailsCard
          >
            {`R$ ${
              contractData.contractValue
                ? numberToMoney(contractData.contractValue)
                : '--'
            }`}
          </Card>
          <Card
            inverted
            variation={''}
            value="Valor da cobertura"
            icon="mailOutlined"
            blackIcon
            large
            detailsCard
          >
            {`R$ ${
              contractData.coverageAmount
                ? numberToMoney(contractData.coverageAmount / 100)
                : '--'
            }`}
          </Card>
          <Card
            inverted
            variation={''}
            value="Cobertura usada"
            icon="mailOutlined"
            blackIcon
            large
            detailsCard
          >
            {`R$ ${
              contractData.coverageAmount
                ? numberToMoney(contractData.coverageUsed / 100)
                : '--'
            }`}
          </Card>
        </Row>
        <Row gap={20}>
          <ClickableCard
            onClick={() => {
              setExtendedMenu(<Imovel id={contractData.propertyId} />)
            }}
            green
            icon="homeSolid"
            large
            boldTitle
          >
            Imóvel
          </ClickableCard>
          <ClickableCard
            onClick={() => {
              setExtendedMenu(<Cliente id={id} />)
            }}
            green
            icon="clientOutlined"
            large
            boldTitle
          >
            Cliente
          </ClickableCard>
          <ClickableCard
            onClick={() => {
              setExtendedMenu(<Seguro />)
            }}
            green
            icon="shieldOutlined"
            large
            boldTitle
          >
            Seguro
          </ClickableCard>
        </Row>
      </CardsWrap>
    </ContentContainer>
  )
}
