import { useContext } from 'react'
import { ThemeProvider } from 'styled-components'
import UserRoutes from './routes'
import { GlobalStyle } from './styles'
import { SapiensContext } from './context'

export default function App() {
  const { theme } = useContext(SapiensContext)
  return (
    <ThemeProvider theme={theme}>
      <UserRoutes />
      <GlobalStyle />
    </ThemeProvider>
  )
}
