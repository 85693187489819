module.exports = {
  CONNECTION: {
    // -- PRODUÇÃO --
    URL: 'https://api.sapiensbank.com.br/manager/',
    SOCKET_URL: 'https://api.sapiensbank.com.br:8186/',
    // -----------------------------------------------

    // -- HOMOLOGAÇÃO --
    // URL: 'http://localhost:8085/manager/',
    // SOCKET_URL: 'http://localhost:9086/',
    // -----------------------------------------------

    // -- SANDBOX --
    // URL: 'https://77df-168-197-157-26.ngrok-free.app/manager/',
    // SOCKET_URL: 'https://api.sapiensbank.com.br:9086/',
    // -----------------------------------------------
  },
  IMAGES: {
    URL: 'https://sapiensbank.com.br/assets/icons',
  },
  STORAGE: {
    NAME: 'sapiens-web',
  },
  STATES: [
    ['Acre', 'AC'],
    ['Alagoas', 'AL'],
    ['Amapá', 'AP'],
    ['Amazonas', 'AM'],
    ['Bahia', 'BA'],
    ['Ceará', 'CE'],
    ['Distrito Federal', 'DF'],
    ['Espírito Santo', 'ES'],
    ['Goiás', 'GO'],
    ['Maranhão', 'MA'],
    ['Mato Grosso', 'MT'],
    ['Mato Grosso do Sul', 'MS'],
    ['Minas Gerais', 'MG'],
    ['Pará', 'PA'],
    ['Paraíba', 'PB'],
    ['Paraná', 'PR'],
    ['Pernambuco', 'PE'],
    ['Piauí', 'PI'],
    ['Rio de Janeiro', 'RJ'],
    ['Rio Grande do Norte', 'RN'],
    ['Rio Grande do Sul', 'RS'],
    ['Rondônia', 'RO'],
    ['Roraima', 'RR'],
    ['Santa Catarina', 'SC'],
    ['São Paulo', 'SP'],
    ['Sergipe', 'SE'],
    ['Tocantins', 'TO'],
  ],
  PAGES: {
    NEW_CLIENT: {
      INPUT_LIST: [
        { type: 'text', placeholder: 'CPNJ', name: 'cnpj', isRequired: true },
        {
          type: 'text',
          placeholder: 'Razão social',
          name: 'social',
          isRequired: true,
        },
        {
          type: 'text',
          placeholder: 'Nome fantasia',
          name: 'fantasy',
          isRequired: true,
        },
        {
          type: 'email',
          placeholder: 'Email',
          name: 'email',
          isRequired: true,
        },
        {
          type: 'phone',
          placeholder: 'Telefone',
          name: 'cellphone',
          isRequired: true,
        },
        {
          type: 'text',
          placeholder: 'CEP',
          name: 'postalCode',
          group: 'address',
          isRequired: true,
        },
        {
          type: 'text',
          placeholder: 'Endereço',
          name: 'street',
          group: 'address',
          isRequired: true,
        },
        {
          type: 'number',
          placeholder: 'Número',
          name: 'number',
          group: 'address',
          isRequired: true,
        },
        {
          type: 'text',
          placeholder: 'Complemento',
          name: 'complement',
          group: 'address',
          isRequired: false,
        },
        {
          type: 'text',
          placeholder: 'Bairro',
          name: 'district',
          group: 'address',
          isRequired: true,
        },
        {
          type: 'select',
          placeholder: 'UF',
          class: 'half',
          name: 'state',
          group: 'address',
          isRequired: true,
        },
        {
          type: 'text',
          placeholder: 'Cidade',
          class: 'half',
          name: 'city',
          group: 'address',
          isRequired: true,
        },
      ],
    },
    NEW_PRODUCT: {
      INPUT_LIST: [
        {
          type: 'number',
          placeholder: 'Ivestimento em reais',
          name: 'investimento_minimo',
        },
        { type: 'number', placeholder: 'Prazo em meses', name: 'prazo' },
        {
          type: 'number',
          placeholder: 'Rentabilidade em %',
          name: 'rentabilidade',
        },
        {
          type: 'text',
          placeholder: 'Documento CPF ou CNPJ',
          name: 'documento',
        },
      ],
    },
    TRANSFER: {
      INPUT_LIST: [
        { type: 'text', placeholder: 'Instituição de origem', name: 'origin' },
        { type: 'number', placeholder: 'Valor', name: 'amount' },
      ],
    },
    TRANSFER2: {
      INPUT_LIST: [
        { type: 'select', placeholder: 'Usuário ou Empresa', name: 'cpf' },
      ],
    },
    INCOME: {
      INPUT_LIST: [
        {
          type: 'autocomplete',
          placeholder: 'Documento ou nome',
          name: 'user',
          isRequired: true,
          autofill: [],
        },
        {
          type: 'select',
          placeholder: 'Investimento',
          name: 'investPurchasesId',
          isRequired: true,
        },
        {
          type: 'input',
          placeholder: 'Valor R$',
          name: 'amount',
          isRequired: true,
        },
      ],
    },
  },
}
