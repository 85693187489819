import { icons } from 'helper'
import { IIcons } from 'helper/icons'
import { useState } from 'react'
import styled from 'styled-components'
import { ResumeInputCard, ResMaskedInput } from './style'

interface ICardProps {
  isMask?: string
  children: string
  icon?: keyof IIcons
  small?: boolean
  green?: boolean
  secondary?: boolean
  disabled?: boolean
  onClick?: any
  placeholder: string
  handleChangeInput?: any
  names: string
  clickable?: boolean
  medium?: boolean
  blocks?: any
  eager?: boolean
  smallIcon?: boolean
}

const TextDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`

export default function ResumeInputCardComponent(props: ICardProps) {
  const [disabled, setDisabled] = useState(true)
  const {
    placeholder,
    children,
    icon,
    handleChangeInput,
    isMask,
    names,
    clickable = true,
    blocks,
  } = props

  return (
    <ResumeInputCard {...props}>
      {clickable ? (
        disabled ? (
          <img
            className="editIcon"
            src={icons.editOutlined}
            alt="edit icon"
            onClick={() => setDisabled((curr) => !curr)}
          />
        ) : (
          <p onClick={() => setDisabled((curr) => !curr)}>OK</p>
        )
      ) : (
        <div />
      )}
      <TextDiv>
        {icon && (
          <img className="iconClickable" src={icons[icon]} alt="card icon" />
        )}
        {isMask ? (
          disabled ? (
            <ResMaskedInput
              defaultValue={placeholder}
              mask={isMask}
              blocks={blocks}
              placeholder={placeholder}
              name={names}
              disabled
            />
          ) : (
            <ResMaskedInput
              eager
              mask={isMask}
              blocks={blocks}
              defaultValue={placeholder}
              onAccept={(e: any) => handleChangeInput(e)}
              placeholder={placeholder}
              name={names}
              disabled={disabled}
            />
          )
        ) : disabled ? (
          <input placeholder={placeholder} disabled value={placeholder} />
        ) : (
          <input
            placeholder={placeholder}
            value={placeholder}
            onChange={(e) => handleChangeInput(e.target.value)}
          />
        )}
      </TextDiv>
      <span className="title">{children}</span>
    </ResumeInputCard>
  )
}
