import { ResumeInputCard, PageTitle } from 'components'
import icons from 'helper/icons'
import { IObject } from 'interfaces'
import { toCapitalizeCase } from 'helper/utils'
import useFetch from 'hooks/useFetch'
import { useEffect, useState } from 'react'
import { FlexContainer } from './style'

export default (props: IObject) => {
  const { id } = props

  const [residentsData, setResidentsData] = useState([])
  const [inquilino, setInquilino] = useState<IObject>()
  const [corresponsavel, setCorresponsavel] = useState<IObject>()

  const api = useFetch(`/product/aluguel-verde/contract/residents?id=${id}`)

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const r = await api.fetch('get')
        if (r.fail) throw new Error()
        const { response } = r.data
        setInquilino(response.lodger)

        // corresponsavel
        const [corresp] = response.residentList.filter((resident: any) => {
          return resident.isCoresponsible
        })
        setCorresponsavel(corresp)

        // residentes
        const residentes = response.residentList.filter((resident: any) => {
          return !resident.isCoresponsible
        })
        residentes.shift()
        setResidentsData(residentes)
      } catch (e) {
        console.error(e)
      }
    }
    fetchUsers()
  }, [])

  return (
    <>
      <FlexContainer>
        <img className="icon" src={icons.clientOutlined} alt="card icon" />
        <PageTitle>Dados do inquilino</PageTitle>
      </FlexContainer>
      <ResumeInputCard
        placeholder={
          inquilino?.name
            ? `${toCapitalizeCase(inquilino.name)} ${toCapitalizeCase(
                inquilino.lastName
              )}`
            : '--'
        }
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        Nome
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={inquilino?.documentNumber || '--'}
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        CPF
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={inquilino?.birthday || '--'}
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        Data de nascimento
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={inquilino?.email || '--'}
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        E-mail
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={inquilino?.phoneNumber || '--'}
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        Telefone
      </ResumeInputCard>
      {residentsData?.map((resident: any, i: number) => {
        return (
          <>
            <FlexContainer>
              <img
                className="icon"
                src={icons.clientOutlined}
                alt="card icon"
              />
              <PageTitle>{`Morador ${i + 1}`}</PageTitle>
            </FlexContainer>
            <ResumeInputCard
              placeholder={
                resident.name ? toCapitalizeCase(resident.name) : '--'
              }
              icon="leafOutlined"
              clickable={false}
              names="resumo-meta-captacao"
            >
              Nome
            </ResumeInputCard>
            <ResumeInputCard
              placeholder={resident?.documentNumber || '--'}
              icon="leafOutlined"
              clickable={false}
              names="resumo-meta-captacao"
            >
              CPF
            </ResumeInputCard>
            <ResumeInputCard
              placeholder={resident?.birthday || '--'}
              icon="leafOutlined"
              clickable={false}
              names="resumo-meta-captacao"
            >
              Data de nascimento
            </ResumeInputCard>
            <ResumeInputCard
              placeholder={resident?.mainEmail || '--'}
              icon="leafOutlined"
              clickable={false}
              names="resumo-meta-captacao"
            >
              E-mail
            </ResumeInputCard>
            <ResumeInputCard
              placeholder={resident?.mainPhone || '--'}
              icon="leafOutlined"
              clickable={false}
              names="resumo-meta-captacao"
            >
              Telefone
            </ResumeInputCard>
          </>
        )
      })}
      <FlexContainer>
        <img className="icon" src={icons.clientOutlined} alt="card icon" />
        <PageTitle>Corresponsável</PageTitle>
      </FlexContainer>
      <ResumeInputCard
        placeholder={
          corresponsavel?.name ? toCapitalizeCase(corresponsavel.name) : '--'
        }
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        Nome
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={corresponsavel?.documentNumber || '--'}
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        CPF
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={corresponsavel?.birthday || '--'}
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        Data de nascimento
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={corresponsavel?.mainEmail || '--'}
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        E-mail
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={corresponsavel?.mainPhone || '--'}
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        Telefone
      </ResumeInputCard>
    </>
  )
}
