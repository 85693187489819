import { MouseEvent } from 'react'
import styled from 'styled-components'

interface IProps {
  selected?: boolean
  onClick?: (_e: MouseEvent<HTMLElement>) => void
}

export const Container = styled.div`
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: center;
  gap: 2px;
  border-radius: 5px;
  background: transparent;
  // box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  width: fit-content;
`

export const Button = styled.button`
  border: none;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  border-radius: 5px;
  background: ${(props: IProps) => {
    return props.selected ? '#3E788A' : '#F5F5F5'
  }};
  
  color: ${(props: IProps) => {
    return props.selected ? '#F5F5F5' : '#757575'
  }};

  /* COMPONENTE */
  // box-shadow: 0px 0px 10px 0px #9AC1BC;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
