import { Dispatch, ReactElement, SetStateAction, useContext } from 'react'
import { SapiensContext } from 'context'
import { ClickableCard, PageTitle } from 'components'
import { IObject } from 'interfaces'
import { Content, CardsWrap } from './style'

import Faturamento from './Faturamento'
import FEF from './FEF'

interface IAVProps {
  setExtendedMenu: Dispatch<SetStateAction<ReactElement | boolean>>
}

export default (props: IAVProps) => {
  const { setExtendedMenu } = props
  const { buffer } = useContext(SapiensContext)
  return (
    <Content>
      <PageTitle withoutMarginLeft>Aluguel Verde</PageTitle>
      <CardsWrap>
        <ClickableCard icon="contractOutlined" disabled>
          Contratos
        </ClickableCard>
        <ClickableCard icon="contractOutlined" disabled>
          Parcelamento de aluguel
        </ClickableCard>
        <ClickableCard icon="contractOutlined" disabled>
          Parcelamento da vistoria de saída
        </ClickableCard>
        <ClickableCard icon="contractOutlined" disabled>
          Parcelamento de rescisão
        </ClickableCard>
      </CardsWrap>
      {/*
        <CardsWrap>
          <ClickableCard
            icon="contractOutlined"
            onClick={() => setExtendedMenu(<Faturamento />)}
          >
            Faturamento
          </ClickableCard>
          <ClickableCard icon="cartaoOutlined" disabled>
            Cartão
          </ClickableCard>
          <ClickableCard icon="contractOutlined" disabled>
            Contratos
          </ClickableCard>
          <ClickableCard icon="contractOutlined" disabled>
            Nota fiscal
          </ClickableCard>
          {'AluGreen' in buffer ? (
            buffer?.AluGreen?.map((contract: IObject, i: number) => (
              <ClickableCard
                icon="contractOutlined"
                onClick={() => {
                  setExtendedMenu(<FEF contractId={contract.id} complete />)
                }}
              >
                {buffer?.AluGreen?.length > 1 ? `FEF #${i + 1}` : 'FEF'}
              </ClickableCard>
            ))
          ) : (
            <ClickableCard disabled icon="contractOutlined">
              FEF
            </ClickableCard>
          )}
        </CardsWrap>
      */}
    </Content>
  )
}
