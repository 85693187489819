import ReactDOM from 'react-dom'
import { StrictMode } from 'react'
import App from './App'
import { AuthProvider, SapiensProvider } from './context'

ReactDOM.render(
  <StrictMode>
    <AuthProvider>
      <SapiensProvider>
        <App />
      </SapiensProvider>
    </AuthProvider>
  </StrictMode>,
  document.getElementById('root')
)
