import * as p from 'assets/Banners'

export interface IImages {
  [key: string]: string
}

const images: IImages = {
  agro: p.agro,
  blank: p.blank,
  city: p.city,
  dash: p.bannerDashboard,
  girl: p.girl,
  logoMenu: p.logoMenu,
  logoSapiens: p.logoSapiens,
  ocean: p.ocean,
  solarwind: p.solarwind,
  wires: p.wires,
}

export default images
