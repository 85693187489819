import { Anchor, Outlined } from './style'

interface ITokenIcon {
  outlined?: string
  color?: string
  secondColor?: string
}

export default function ({
  outlined = '',
  color = '#757575',
  secondColor = '#9E9E9E',
}: ITokenIcon) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0L22.3923 6V18L12 24L1.6077 18V6L12 0Z"
        fill="url(#paint0_linear_3224_78355)"
      />
      <path
        d="M7.6335 4.42916L7.63357 4.42912C8.80944 3.74432 9.91388 3.12275 10.7452 2.67327C11.1614 2.44821 11.5056 2.26823 11.7546 2.14547C11.8558 2.09561 11.9375 2.05702 12 2.02929C12.0625 2.05702 12.1442 2.09561 12.2454 2.14547C12.4944 2.26823 12.8386 2.44821 13.2548 2.67327C14.0861 3.12275 15.1906 3.74432 16.3664 4.42912L16.3665 4.42916L20.4062 6.78092V12.0351V17.2874L16.181 19.7148L11.9541 22.1432L7.7733 19.7355L7.77326 19.7355L3.59375 17.3293V12.0556V6.78092L7.6335 4.42916ZM12.1019 1.98742C12.113 1.98374 12.1113 1.98506 12.1003 1.98794C12.1009 1.98775 12.1014 1.98758 12.1019 1.98742ZM11.8997 1.98794C11.8887 1.98506 11.887 1.98374 11.8981 1.98742C11.8986 1.98758 11.8991 1.98775 11.8997 1.98794Z"
        stroke={color}
        strokeOpacity="0.2"
      />
      <path
        d="M7.6335 4.42916L7.63357 4.42912C8.80944 3.74432 9.91388 3.12275 10.7452 2.67327C11.1614 2.44821 11.5056 2.26823 11.7546 2.14547C11.8558 2.09561 11.9375 2.05702 12 2.02929C12.0625 2.05702 12.1442 2.09561 12.2454 2.14547C12.4944 2.26823 12.8386 2.44821 13.2548 2.67327C14.0861 3.12275 15.1906 3.74432 16.3664 4.42912L16.3665 4.42916L20.4062 6.78092V12.0351V17.2874L16.181 19.7148L11.9541 22.1432L7.7733 19.7355L7.77326 19.7355L3.59375 17.3293V12.0556V6.78092L7.6335 4.42916ZM12.1019 1.98742C12.113 1.98374 12.1113 1.98506 12.1003 1.98794C12.1009 1.98775 12.1014 1.98758 12.1019 1.98742ZM11.8997 1.98794C11.8887 1.98506 11.887 1.98374 11.8981 1.98742C11.8986 1.98758 11.8991 1.98775 11.8997 1.98794Z"
        stroke={color}
        strokeOpacity="0.2"
      />

      <svg
        width="12"
        height="12"
        xmlns="http://www.w3.org/2000/svg"
        x="6"
        y="6"
        xmlnsXlink="https://www.w3.org/1999/xlink"
      >
        {/* {outlined && (
          <image
            href={`data:image/svg+xml;base64,${outlined}`}
            height={12}
            width={12}
            // style={{
            //   filter:
            //   'invert(100%) sepia(100%) saturate(0%) hue-rotate(80deg) brightness(109%) contrast(103%)',
            // }}
            />
        )} */}
      </svg>
      <defs>
        <linearGradient
          id="paint0_linear_3224_78355"
          x1="5.25"
          y1="20.625"
          x2="24"
          y2="-9.49949e-07"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={secondColor} stopOpacity="0.9375" />
        </linearGradient>
      </defs>
    </svg>
  )
}
