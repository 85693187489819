import { KanbanSubMenu, PagePathing, PageTitle } from 'components'
import { Outlet } from 'react-router-dom'
import { Row } from 'styles'
import icons from 'helper/icons'

export default function Suporte() {
  const categories = [
    ['Sapiensbank', 'cg', icons.leafSolid, false],
    ['Aluguel Verde', 'av', icons.houseGreenOutlined, false],
    // ['Empréstimos', 'ep', icons.loanOutlined, false],
    // ['Criptoativos', 'ca', icons.cryptoOutlined, false],
    // ['Landingpages', 'lp', icons.clientPJSolid, false],
  ]

  return (
    <>
      <PagePathing>Suporte</PagePathing>
      <PageTitle>Suporte</PageTitle>
      <Row>
        <KanbanSubMenu categories={categories} />
        <Outlet />
      </Row>
    </>
  )
}
