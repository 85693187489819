import { Card, ClickableCard, PagePathing, PageTitle } from 'components'
import { useState, useEffect } from 'react'
import { CardsContainer, Content } from 'styles'
import useFetch from 'hooks/useFetch'
import { icons } from 'helper'

export default function AluguelVerdeProduto() {
  const [cardList, setCardList] = useState<any>([])
  const [cardList2, setCardList2] = useState<any>([])

  const api = useFetch('product/aluguel-verde/resume')

  useEffect(() => {
    const fetchInputs = async () => {
      try {
        const r = await api.fetch('get')
        if (r.fail) throw new Error(r.errors.message)
        const [d1, d2] = r.data.response
        setCardList(d1)
        setCardList2(d2)
      } catch (err) {
        console.log(err)
      }
    }

    fetchInputs()
  }, [])

  return (
    <Content withoutMarginLeft noMarginTop>
      <PagePathing>{`Gestão de produtos > Aluguel Verde`}</PagePathing>
      <PageTitle>
        <>
          <img
            className="icon"
            src={icons.newHouseGreen2}
            alt="card icon"
            style={{ marginRight: 20 }}
          />
          Aluguel Verde
        </>
      </PageTitle>
      <CardsContainer paddingLeftTop>
        {cardList.map((card: any) => {
          return (
            <Card
              variation={`(+R$ ${
                card.variation || '0,00'
              }) Investidos esta semana`}
              value={card.value || ''}
              wide={card.size === 'l'}
              icon={card.icon}
              inverted
              ribbon={card.ribon}
              showRibbon
              large
              greenIcon={card.icon === 'greenCheckOutlined'}
            >
              {card.label}
            </Card>
          )
        })}
      </CardsContainer>
      <CardsContainer>
        <ClickableCard
          to="/gestaoProdutos/aluguelVerde/contratos"
          green
          icon="checkedContractOutlined"
          large
        >
          Contratos
        </ClickableCard>
        {cardList2.map((card: any) => {
          return (
            <Card
              value={card.value || ''}
              icon={card.icon}
              inverted
              plain
              large={card.size === 'l'}
              greenIcon={
                card.icon === 'greenCheckOutlined' ||
                card.icon === 'trophySolid'
              }
            >
              {card.label}
            </Card>
          )
        })}
      </CardsContainer>
    </Content>
  )
}
