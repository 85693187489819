import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const CardsWrap = styled.div`
  display: flex;
  position: absolute;
  height: auto;
  flex-wrap: wrap;
  max-width: 70%;
  max-height: 60%;
  overflow: scroll;
  padding: 0 50px 10px 0;
  margin: 20px 0 20px 0;
  gap: 20px;

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: white;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }
`
