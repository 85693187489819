import { useRef, useEffect, useCallback, useContext } from 'react'
import { useField } from '@unform/core'
import { SapiensContext } from 'context'

interface Props {
  name: string
  getFile?: any
  setPreview?: any
  setPreview64?: any
  accept?: string
  setSize?: any
}
type InputProps = JSX.IntrinsicElements['input'] & Props

export default function ImageInput({
  name,
  getFile = () => {},
  setPreview = () => {},
  setPreview64 = () => {},
  setSize = () => {},
  accept="image/*",
  ...rest
}: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const { fieldName, registerField } = useField(name)
  // const { setPreview, setPreview64 } = useContext(SapiensContext)
  const handlePreview = useCallback((e: any) => {
    const file: any = e.target.files?.[0]

    if (!file) {
      setPreview('')
    }

    getFile(file)

    setSize(file.size)

    const blobToBase64 = (blob: any, callback?: any) => {
      const reader = new FileReader()
      reader.onload = () => {
        const dataUrl: any = reader.result
        const base64 = dataUrl?.split(',')[1]
        callback(base64)
      }
      return reader.readAsDataURL(blob)
    }

    blobToBase64(file, setPreview64)
    
    const previewURL = URL.createObjectURL(file)
    setPreview(previewURL)
  }, [])
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        ref.value = ''
        setPreview('')
      },
      setValue(_: HTMLInputElement, value: string) {
        setPreview(value)
      },
    })
  }, [fieldName, registerField])
  return (
    <input
    key={name}
      type="file"
      accept={accept}
      ref={inputRef}
      onChange={handlePreview}
      {...rest}
    />
  )
}
