import styled from 'styled-components'

interface IStyleProps {
  height?: string
  width?: string
  color?: string
  opacity?: string
}

export const Card = styled.div`
  background: ${(props: IStyleProps) => {
    if (props.color) return `${props.color}`
    return '#C9F8DC'
  }};
  height: ${(props: IStyleProps) => {
    if (props.height) return `${props.height}`
    return '142px'
  }};
  width: ${(props: IStyleProps) => {
    if (props.width) return `${props.width}`
    return '315px'
  }};

  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .bottom-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .icons-container {
    display: flex;
    flex-direction: row;
  }

  .money {
    color: #070707;
    font-weight: 700;
    font-size: 22px;
    line-height: 19x;
  }

  .bold {
    font-weight: 700;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    margin-right: 22px;
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    font-size: 12px;
    visibility: hidden;
    width: 120px;
    background-color: #000000;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text */
    top: 135%;
    left: 50%;
    margin-left: -60px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
  }

  /* Tooltip arrow */
  .tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    padding: 0;
    opacity: ${(props: IStyleProps) => {
      if (props.opacity) return `${props.opacity}`
      return '1'
    }};
  }

  .hide-options {
    position: absolute;
    width: 220px;
    height: 92px;
    top: 83%;
    z-index: 220;
  }
  .send-option {
    padding: 20px;
    display: flex;
    width: 215px;
    height: 45px;
    left: 0px;
    top: 0px;
    background: #ffffff;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    color: #9c948c;
    justify-content: flex-start;
    border: 0;
  }

  .send-option:hover {
    background: #f5f4f3;
  }

  /* Tooltip arrow */
  .send-hover + .hide-options::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 20%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #ffffff transparent;
  }

  .hover-icon {
    margin-right: 20px;
    filter: invert(67%) sepia(12%) saturate(240%) hue-rotate(349deg)
      brightness(88%) contrast(85%);
  }
`
