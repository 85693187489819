import { CodeInput, Button } from 'components'
import { useAuth, useFetch } from 'hooks'
import { icons } from 'helper'
import { useContext } from 'react'
import { SapiensContext } from 'context'

import TwoWayPasswordContainer from './style'

export default function TwoWayPassword() {
  const auth = useAuth()
  const { loading } = useContext(SapiensContext)
  const api = {
    resend: useFetch('send-code'),
  }

  const handleResendCode = async () => {
    try {
      const response = await api.resend.fetch('get')
      if (response.fail) throw new Error(response.errors.message)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <TwoWayPasswordContainer>
      <div className="title">Confirmação de segurança por token</div>
      <span className="text">
        Digite aqui a chave de segurança de 4 dígitos para confirmar a operação
        vinculada ao número <b>{auth.user?.user?.cellphone}</b>.
      </span>

      <CodeInput type="text" name="code" maxLength={4} required autoFocus />

      <div className="resendbox">
        <span>Não recebeu o código?</span>
        <u onClick={handleResendCode} role="button">
          <img src={icons.smsSolid} alt="chat icon" />
          Reenviar código
        </u>
      </div>

      <Button $loading={loading} rounded>
        CONFIRMAR
      </Button>
    </TwoWayPasswordContainer>
  )
}
