import styled from 'styled-components'

export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 65%;
  margin-bottom: 5%;
  /* border: 1px solid red; */
  width: 100%;
  gap: 50px;
  padding: 0px 0 150px 0;
  overflow-x: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: white;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }
`

export const SubTitle = styled.h2`
  font-size: 16px;
  font-weight: 500;
  /* margin: 0; */
  margin-left: 10px;
`

export const Plus = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #9C948C;
  margin: 0 -10px 20px -10px;
`

export const Error = styled.p`
  color: #E41D1D;
  font-size: 10px;
  font-weight: 700;
  line-height: 18px;
  margin: -16px 0 0 0;
`