import { useContext, useState, useEffect } from 'react'
import { Modal } from 'components'
import useFetch from 'hooks/useFetch'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { icons } from 'helper'
import { SapiensContext } from 'context/sapiens'
import {
  CarouselContainer,
  Slide,
  SlideContainer,
  SlideLegend,
  StyledCarousel,
} from './style'

export default () => {
  const { setShowCarousel, showCarousel, buffer } = useContext(SapiensContext)
  const [imageIndex, setImageIndex] = useState<number>(0)
  const api = useFetch('/')
  const [data, setData] = useState<any>([])

  useEffect(() => {
    if (!showCarousel) return
    const promisses =
      showCarousel === 'document'
        ? [
            api.fetch('get', {}, `/user/document/${buffer?.userId}/f`),
            api.fetch('get', {}, `/user/document/${buffer?.userId}/b`),
          ]
        : [api.fetch('get', {}, `/user/document/${buffer?.userId}/s`)]

    Promise.all(promisses).then((res: any) => {
      setData([])
      res.forEach((item: any) => {
        const d = {
          src: `data:image/jpeg;base64,${item.data.response}`,
          label: 'Selfie',
        }
        setData((c: any) => [...c, d])
      })
    })
  }, [showCarousel])

  return (
    <Modal
      height={482}
      width={738.66}
      setShowModal={() => setShowCarousel(undefined)}
      showModal={!!showCarousel}
      hideMenu
      removeChildrenPadding
      zIndex={225}
    >
      <CarouselContainer
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <StyledCarousel
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          onChange={(index) => setImageIndex(index)}
          renderArrowPrev={(clickHandler: () => void, hasPrev: boolean) =>
            hasPrev && (
              <button
                onClick={clickHandler}
                type="button"
                className="carousel-button"
              >
                <img
                  className="arrow arrow-prev"
                  onClick={clickHandler}
                  src={icons.nextGreenArrowSolid}
                  alt="arrow"
                />
              </button>
            )
          }
          renderArrowNext={(clickHandler: () => void, hasNext: boolean) =>
            hasNext && (
              <button
                onClick={clickHandler}
                type="button"
                className="carousel-button arrow-next"
              >
                <img
                  className="arrow"
                  onClick={clickHandler}
                  src={icons.nextGreenArrowSolid}
                  alt="arrow"
                />
              </button>
            )
          }
        >
          {data.map((item: any) => {
            return (
              <SlideContainer>
                <Slide src={item.src} alt={item.label} />
              </SlideContainer>
            )
          })}
        </StyledCarousel>
        <SlideLegend>{data[imageIndex]?.label || 'Carregando...'}</SlideLegend>
      </CarouselContainer>
    </Modal>
  )
}
