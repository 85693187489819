import styled from 'styled-components'

export const ExtendedContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 93%;
  height: 93%;
  padding: 34px 0 0 50px;
  width: 94%;
  background-color: #f9f9f9;

  .cards-container {
    width: 350px;
    gap: 14px;
    display: flex;
    flex-direction: column;
  }
`

export const CardsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 80%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: #fbfbfb;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
`
