import styled from 'styled-components'
import { colorConverter } from 'helper/utils'

interface IStyleProps {
  color?: string
  marginLeft?: boolean
  width?: string
}

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 15px;
  .black-icon {
    filter: invert(0%) sepia(24%) saturate(0%) hue-rotate(239deg)
      brightness(100%) contrast(95%);
  }

  .title-page {
    font-weight: 700;
    font-size: 20px;
    margin: 0;
  }

  .buttons {
    border: 0;
    background-color: transparent;
  }

  .tiny-icon {
    margin: 0;
  }

  .txt {
    align-self: center;
    /* padding-bottom: 3px; */
    font-weight: 400;
    font-size: 14px;
    margin: 0;
  }

  .column-name {
    /* padding-bottom: 3px; */
    align-self: center;
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    color: ${(props: IStyleProps) => {
      if (props.color === 'blue') return '#03989E'
      if (props.color === 'green') return '#0BF569'
      if (props.color === 'red') return '#D61212'
      return '#2B2B2B'
    }};
  }

  .image-button {
    cursor: pointer;
  }
`
export const Img = styled.img`
  filter: ${(props: IStyleProps) => {
    return colorConverter(props.color || '#000000')?.filter
  }};
  margin-top: 3px;
  margin-left: ${(props: IStyleProps) => {
    if (props.marginLeft) return '20px'
    return '0'
  }};
  max-width: 16px;
`
export const IconContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-right: 10px;
`

export const Button = styled.button`
  width: auto;
  background-color: transparent;
  border: none;
`

export const CardsWrap = styled.div`
  display: flex;
  position: fixed;
  top: 170px;
  flex-direction: row;
  max-height: 70%;
  flex-wrap: wrap;
  /* min-width: 70%; */
  max-width: 70%;
  overflow: scroll;
  padding: 0 50px 10px 10px;
  margin: 20px 0 20px 0;
  gap: 20px;

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: white;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }
`
