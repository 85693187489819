import { useEffect, useState, useCallback, useContext, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { KanbanSubMenu, PagePathing, PageTitle, Modal } from 'components'
import SecurityConfirm from 'components/SecurityConfirm'
import { Content, Row } from 'styles'
import { Outlined } from 'assets/Icons'
import useFetch from 'hooks/useFetch'

import Home from './Home'
import Status from './Status'
import NovoCupom from './NovoCupom'
import ResumoNovoCupom from './ResumoNovoCupom'
import KanbanStatusCampanha from './StatusCampanha'

import { SapiensContext } from 'context/sapiens'

import './style.css'
import { IObject } from 'interfaces'

const def = [
  ['Geral', 'resume', Outlined('dotedCircleGrey'), false],
  ['Status da campanha', 'campaign', Outlined('dotedCircleGrey'), false],
  ['Status de cupom', 'status', Outlined('smallTicketGrey'), false],
]

export default function GestaoCupomDashboard() {
  const { showModalNovoCupom, setShowModalNovoCupom, extended } =
    useContext(SapiensContext)

  const [campainKanban, setCampaignKanban] = useState<any>([])
  const [selectedCampagin, setSelectedCampaign] = useState<string>('')
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [categories, setCategories] = useState<any>(def)
  const [listCampaign, setListCampagin] = useState<any>([])

  const [currentPage, setCurrentPage] = useState('')
  const location = useLocation()
  const api = useFetch('cupom/campaign-list')
  const apiCurrency = useFetch('cupom/currency-list')

  const campaignList = useRef<any>([])
  const currencyList = useRef<any>([])

  const nextPage = useCallback(
    () => setPageNumber((oldValue: number) => oldValue + 1), []
  )

  useEffect(() => {
    api.fetch('get').then((res: IObject) => {
      if (res.fail) throw new Error()
      const { data } = res
      const arr = data.response.map((label: any, i: number) => {
        return { label: label.name, value: i, kanban: label.kanban, to: 'campaign' }
      })

      setListCampagin(arr)
      setCategories((cur: IObject) =>
        cur.map((c: any) => {
          const [_, pg] = c
          if (pg === 'campaign') c.push(arr)
          return c
        })
      )
      if (arr.length === 0)
        return (campaignList.current = [
          { label: 'Abertura de conta', value: 1 },
        ])
      campaignList.current = arr
    })

    apiCurrency.fetch('get').then((res: IObject) => {
      if (res.fail) throw new Error()
      const { data } = res
      const arr = data.response.map((curr: any, index: any) => {
        return {
          label: curr.tick,
          price: curr.value,
          value: index,
          type: curr.type,
        }
      })
      currencyList.current = arr
    })

    setShowModalNovoCupom(false)
  }, [])

  useEffect(() => {
    const [page] = location.pathname.split('/').reverse()
    setCurrentPage(page)
    if (page === 'campaign') {
      if (listCampaign.length === 0) return;
      setCampaignKanban(listCampaign[0].kanban)
    }
    setSelectedCampaign('')

  }, [location.pathname, listCampaign])

  useEffect(() => {
    if (selectedCampagin === '') return;
    const camp = listCampaign.filter((camp: any) => {
      if (camp.label === selectedCampagin) return camp
    })
    setCampaignKanban(camp[0].kanban)
  }, [selectedCampagin])


  return (
    <>
      <Content extended={extended}>
        <PagePathing>Gestão de cupons</PagePathing>
        <Row gap={0}>
          <img src={Outlined('ticketBlack')} alt="ticket-icon" />
          <PageTitle withoutMarginLeft noHeight>
            Gestão de Cupom
          </PageTitle>
        </Row>
        <KanbanSubMenu categories={categories} onClickSub={setSelectedCampaign} selectedSub={selectedCampagin}/>
        {{
          resume: <Home setShowModal={setShowModalNovoCupom} />,
          status: <Status />,
          campaign: <>{campainKanban.length === 0 ? <></> : <KanbanStatusCampanha data={campainKanban} />}</>
        }[currentPage] || <Home setShowModal={setShowModalNovoCupom} />}
      </Content>
      <Modal
        FS
        setShowModal={setShowModalNovoCupom}
        showModal={showModalNovoCupom}
      >
        {
          [
            <NovoCupom
              handleNext={nextPage}
              campaignList={campaignList.current}
              currencyList={currencyList.current}
            />,
            <ResumoNovoCupom handleNext={nextPage} />,
            <SecurityConfirm path="newCupom" />,
          ][pageNumber]
        }
      </Modal>
    </>
  )
}
