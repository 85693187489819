import PagePathing from './style'

interface IPagePathingComponent {
  withoutMarginLeft?: boolean
  children: any
  noHeight?: boolean
}

export default function PagePathingComponent(props: IPagePathingComponent) {
  const { children } = props
  return <PagePathing {...props}>{children}</PagePathing>
}
