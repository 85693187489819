import styled from 'styled-components'
import { ITheme } from 'styles/Themes'
import { colorConverter } from 'helper/utils'

interface IStyleProps {
  disable?: boolean
  theme: ITheme
  extended?: boolean
}

const TRANSITION_TIME = '1s'
const GREEN = colorConverter('#0BF569')?.filter
const GREY = colorConverter('#EBEAE8')?.filter

export const RightMenu = styled.div`
  z-index: 5;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 55px;
  align-items: center;

  background-color: ${(props: IStyleProps) => props.theme.rightMenu.background};
  box-shadow: 0 4px 4px 0
    ${(props: IStyleProps) => props.theme.rightMenu.shadow};
  height: 100%;
  width: 92px;

  box-sizing: border-box;
  padding: 40px 0 0 0;

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  .closeModal {
    width: 13px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  img {
    width: 20px;
    cursor: pointer;
  }
`

export const ExtendableMenu: any = styled.div`
  transition: width ${TRANSITION_TIME};
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${(props: IStyleProps) =>
    props.theme.rightMenu.extendable.background};
  box-shadow: 0 5px 5px 0
    ${(props: IStyleProps) => props.theme.rightMenu.shadow};
  max-height: 100%;
  width: ${(props: IStyleProps) => {
    if (props.extended) return '900px'
    return '18px'
  }};

  box-sizing: border-box;

  .extend-button {
    margin-right: ${(props: IStyleProps) => {
      if (props.extended) return '900px'
      return '20px'
    }};
    position: absolute;
    z-index: 4;
    transition: transform, width, margin-right ${TRANSITION_TIME};
    transform: rotate(180deg);
    transform: ${(props: IStyleProps) => !props.extended && 'none'};
    cursor: pointer;
    filter: ${(props: IStyleProps) => (props.extended ? GREEN : GREY)};
  }
`

export const Background = styled.div`
  z-index: 3;
  background: #00000026;
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Row = styled.div`
  display: flex;
`
