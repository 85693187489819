import { useState, useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import * as Style from './style'
import { UUID } from 'helper/utils'

interface Props {
  title?: string | undefined
  tag?: string | undefined
  name?: string | undefined
  disabled?: boolean | undefined
  value?: string | number
  style?: { [key: string]: string | number}
  onChange?: (value: any) => void
}

export default function (props: Props) {
  const { title, tag, name, value, style, onChange = () => {} } = props
  const { fieldName, registerField, defaultValue, error } = useField(name || UUID())
  const inputRef = useRef(null)
  const [currentError, setCurrentError] = useState<String | null>()
  const [focused, setFocused] = useState<boolean | null>(false)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    })
  }, [registerField, fieldName])

  useEffect(() => {
    if (error) setCurrentError(error)
  }, [error])

  const isFocus = () => {
    setFocused(inputRef.current === document.activeElement);
  };

  return (
    <Style.Container focus={focused} style={style} >
      <Style.HeaderContainer>
        <section>{title}</section>
        {
          tag && <label>{tag}</label>
        }
      </Style.HeaderContainer>
      <Style.InputContainer>
        <Style.Input
          ref={inputRef}
          defaultValue={defaultValue}
          rows={4}
          onFocus={isFocus}
          onChange={(e) => onChange(e.target.value)}
          value={value}
        />
      </Style.InputContainer>
      {currentError && <Style.InputError>{currentError}</Style.InputError>}
    </Style.Container>
  )
}
