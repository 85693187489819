import { ClickableCard, PagePathing, PageTitle } from 'components'
import { IIcons } from 'helper/icons'
import { CardsContainer, Column, Row } from 'styles'

const response: {
  cardList: {
    icon: keyof IIcons
    size: string
    label: string
    to?: string
    disabled?: boolean
  }[]
} = {
  cardList: [
    {
      icon: 'esgOutlined',
      size: 'sm',
      label: 'Investimentos ESG',
      to: 'investimentosEsg',
    },
    {
      icon: 'houseGreenOutlined',
      size: 'sm',
      label: 'Aluguel verde',
    },
    {
      icon: 'loanOutlined',
      size: 'sm',
      label: 'Empréstimos',
      disabled: true,
    },
    {
      icon: 'cryptoOutlined',
      size: 'sm',
      label: 'Criptoativos',
      disabled: true,
    },
    {
      icon: 'billOutlined',
      size: 'sm',
      label: 'Pagar boletos',
      disabled: true,
    },
    {
      icon: 'creditCardOutlined',
      size: 'sm',
      label: 'Cartão virtual',
      disabled: true,
    },
  ],
}

const listaRotas = ['investimentosEsg', 'aluguelVerde']

export default function GestaoProdutos() {
  return (
    <>
      <PagePathing>Gestão de Produtos</PagePathing>
      <PageTitle>Produtos Sapiensbank</PageTitle>
      <Column gap={24}>

      <Row gap={20}>
      <ClickableCard
          to="investimentosEsg"
          green
          icon="esgOutlined"
          // smallIcon
          >
          Investimentos
        </ClickableCard>
      <ClickableCard
          green
          icon="houseGreenOutlined"
          // smallIcon
          >
          Aluguel verde
        </ClickableCard>
      </Row>
      <Row gap={20}>
      <ClickableCard
          icon="loanOutlined"
          // disabled
          // smallIcon
          >
          Empréstimos
        </ClickableCard>
        <ClickableCard
          icon="cryptoOutlined"
          // disabled
          // smallIcon
          >
          Criptoativos
        </ClickableCard>
        <ClickableCard
          icon="coinOutlined"
          // disabled
          // smallIcon
          >
            Sapiens Crédito
        </ClickableCard>
      </Row>
      </Column>
      {/* <CardsContainer width="100%">
        {response.cardList.map((card, i) => {
          return (
            <ClickableCard
              to={listaRotas[i]}
              icon={card.icon}
              disabled={card.disabled}
            >
              {card.label}
            </ClickableCard>
          )
        })}
      </CardsContainer> */}
    </>
  )
}
