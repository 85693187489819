import { Form } from '@unform/web'
import * as c from 'components'
import { SapiensContext } from 'context'
import { useContext, useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Content } from 'styles'
import { useState } from 'react'
import TokenIcon from 'components/Card/TokenPoster/TokenIcon'
import DetailsForm from 'pages/GestaoProdutos/InvestimentosEsg/NovaCaptacao/DetailsForm'
import LayoutForm from 'pages/GestaoProdutos/InvestimentosEsg/NovaCaptacao/LayoutForm'
import BasicInfoForm from 'pages/GestaoProdutos/InvestimentosEsg/NovaCaptacao/BasicInfoForm'

const cardmock = 
  {
    min: '1.000,00',
    colorTags: ['Pre-fixado', '24 meses', 'IPCA + 15% a.a.'],
    // icon: 'OETokenBasicPink',
    createdAt: '2023',
    name: 'CCB sapiensbank 1',
    tick: 'CCBS1',
    color: '#CB6BE6',
    image: 'https://sapiensbank.com.br/assets/womanWithLaptopPoster.png',
    rate: 0,
    chart: [
      { rate: 0, title: '', color: '#CB6BE6' },
      { rate: 0.01, title: '', color: '#757575' },
    ],
    guarantee: 'FGC-CDB',
    buttonText: 'Investir',
  }
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 20px;
`

const Row = styled.div`
  display: flex;
  gap: 10px;
  margin: 5px;
`

const suggestions = [
  'Batata',
  'Batatão',
  'Bolo',
  'Chocolate',
  'Danoninho',
  'Lasanha',
  'Paçoca',
  'Salsicha',
  'Trakinas',
]

export default function Sandbox() {
  const [showModalDetalhes, setShowModalDetalhes] = useState(false)
  const [extendedMenu, setExtendedMenu] = useState<any>(false)
  const formRef = useRef<HTMLFormElement | any>(null)
  const handleSubmit = (data: any) => {
    console.log(data)
  }
  const { extended, toggleDarkMode } = useContext(SapiensContext)
  return (
    <Content extended={extended}>
      <c.Modal
        showModal={showModalDetalhes}
        setShowModal={setShowModalDetalhes}
        FS
        extendedMenuData={extendedMenu}
      >
       <BasicInfoForm />
      </c.Modal>
      <Row>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <Column>
            {/* #BUTTON */}
            <TokenIcon outlined=''/>
            <c.Button $loading={false} onClick={() => setShowModalDetalhes(true)}>
              Default
            </c.Button>
            <c.Button $loading>Loading</c.Button>
            <Link to="/confirm">CONFIRM PAGE</Link>
            <Row>
              <c.Button $loading={false} small secondary>
                Small Secondary
              </c.Button>
              <c.Button $loading={false} small>
                Small
              </c.Button>
            </Row>
            <c.Button $loading={false} rounded>
              Rounded
            </c.Button>
            <c.Button $loading={false} outlined>
              Outlined
            </c.Button>
            <c.Button $loading={false} disabled>
              Disabled
            </c.Button>
            <Row>
              {/* #CHECKBOX */}
              <c.Checkbox value="1" name="checkboxA" />
              <c.Checkbox
                value="1"
                name="checkboxA"
                label="default"
                defaultChecked
              />
              <c.Checkbox value="1" name="checkboxC" green />
              <c.Checkbox
                value="1"
                name="checkboxC"
                green
                label="green"
                defaultChecked
              />
            </Row>
            {/* #SELECT */}
            <c.Select
              name="Select"
              placeholder="Placeholder"
              options={[
                { value: '1', label: 'option 1' },
                { value: '2', label: 'option 2' },
                { value: '3', label: 'option 3' },
              ]}
            />
            {/* #INPUT */}
            <c.Input placeholder="Default" type="text" name="inputA" />
            <c.MaskedInput name="currency" placeholder="Money" type="money" />
            <c.FileInput name="file" />
            <c.AutoComplete
              suggestions={suggestions}
              name="inputAutocomplete"
              placeholder="Autocomplete"
              type="text"
            />

            <c.Input
              placeholder="Rounded"
              type="text"
              name="inputRounded"
              rounded
            />
            <c.Input
              placeholder="Error"
              type="text"
              name="inputError"
              initialError="Error"
            />
            <c.Input
              placeholder="Eye Icon"
              type="text"
              name="inputEye"
              icon="eye"
            />
            <c.Input
              placeholder="Password Icon"
              type="text"
              name="inputPassword"
              icon="password"
            />
            <c.Input
              placeholder="Upload Icon"
              type="text"
              name="inputIcon"
              icon="upload"
            />
            <c.Input
              disabled
              placeholder="Disabled"
              type="text"
              name="inputA"
              icon="upload"
            />
            <c.UploadImg
              noWeigth
              name={'uploadFile'}
              id={'uploadFile'}
              // iconName="pdfBlack"
              placeholder={'Upload File'}
              // accept=".pdf"
              setPreview64={() => {}}
            />
            <c.ColorPicker />
            <c.Slider />
            <c.Selector
              options={[
                { label: 'Base' },
                { label: 'Otimista' },
                { label: 'Pessimista' },
              ]}
            />
          </Column>
        </Form>
        <Column>
          {/* #CLICKABLECARD */}
          <Row>
            <c.ClickableCard icon="cryptoOutlined" to="">
              Clickable
            </c.ClickableCard>
            <c.ClickableCard icon="esgOutlined" secondary to="">
              Secondary
            </c.ClickableCard>
            <c.ClickableCard icon="esgOutlined" green to="">
              Green
            </c.ClickableCard>
            <c.ClickableCard icon="sapiensCalendarOutlined" disabled to="">
              Disabled
            </c.ClickableCard>
          </Row>
          {/* CARD */}
          <Row>
            <c.Card value="4" icon="houseGreenOutlined" variation="+123">
              Default
            </c.Card>
            <c.Card value="12" icon="phoneOutlined" variation="+123" secondary>
              Secondary
            </c.Card>
            <c.Card value="1" icon="bugSolid" variation="+123" green>
              Green
            </c.Card>
          </Row>
          <Row>
            <c.Card icon="loanOutlined" value="123123123" wide variation="+123">
              Wide
            </c.Card>
            <c.Card
              icon="coinOutlined"
              value="123123123"
              wide
              variation="+123"
              inverted
            >
              Invert
            </c.Card>
          </Row>
          <Row>
            <c.Card
              icon="currencyOutlined"
              value="99999"
              wide
              variation="+123"
              secondary
            >
              Black
            </c.Card>
          </Row>
          <Row>
            <c.FundingCard
              modality="Pré-Fixado"
              minValue={1000}
              months={36}
              image="agro"
              flavor="eco"
            >
              Funding
            </c.FundingCard>
            <c.EsgCard
              paid={'R$ 1.234,00'}
              rentability="IPCA +10"
              value={'R$ 1.000,00'}
              months={36}
              image="solarwind"
              parcel={20}
              progress={12}
              ribbon="pro"
            >
              ESG
            </c.EsgCard>
          </Row>
          <Row>
            <c.KanbanCard
              column={0}
              name="Kanban"
              cpf="123.456.789-00"
              email="dani@bol.com.br"
              color="red"
            />
            <c.KanbanCard
              column={1}
              name="Kanban"
              cpf="123.456.789-00"
              email="dani@bol.com.br"
              color="red"
            />
            <c.KanbanCard
              column={2}
              name="Kanban"
              cpf="123.456.789-00"
              email="dani@bol.com.br"
              isDragging
              color="red"
            />
          </Row>
          <Row>
            <c.FiscalCard
              total={100}
              parcel={2}
              totalParcel={12}
              nfse={4567}
              date={new Date()}
              height="90px"
              width="270px"
            />
            <c.FiscalCard
              total={100}
              parcel={2}
              totalParcel={12}
              isPayment="Pagamento realizado"
              date={new Date()}
              height="90px"
              width="270px"
            />
          </Row>
          <Row>
            <c.FiscalCard
              total={100}
              parcel={2}
              totalParcel={12}
              isPayment="Falha no Pagamento"
              date={new Date()}
              height="90px"
              width="270px"
              color="rgba(203, 108, 230, 0.2)"
              opacity="0.25"
            />
            <c.FiscalCard
              total={100}
              parcel={2}
              totalParcel={12}
              isPayment="Aguardando pagamento"
              date={new Date()}
              height="90px"
              width="270px"
              color="#F9F9F9"
              opacity="0.25"
            />
          </Row>
          <Row>
            <c.BugCard
              isNewCard={false}
              reports={[
                {
                  date: new Date(),
                  status: 'Problema relatado',
                  place: 'financeiro',
                  color: '#055C28',
                },
              ]}
            />
          </Row>
          <Row>
            <c.BugCard isNewCard />
            <c.TokenPoster onPress={() => {}} item={cardmock} i={1}/>
          </Row>
        </Column>
      </Row>
    </Content>
  )
}
