import styled from 'styled-components'

interface IStyleProps {
  gap?: number
  width?: number
  margin?: string
  noMarginLeft?: boolean
  alignStart?: boolean
  marginLeft?: number
}

export default styled.div`
  margin: ${(props: IStyleProps) => {
    if (props.margin) return props.margin
    return '0'
  }};
  margin-left: ${(props) => {
    if (props.marginLeft) return `${props.marginLeft}px`
    if (props.noMarginLeft) return 0
    return '30px'
  }};
  height: auto;
  width: ${(props: IStyleProps) => {
    if (props.width) return `${props.width}px`
    return '100%'
  }};
  display: flex;
  flex-direction: row;
  align-items:  ${(props: IStyleProps) => {
    if (props.alignStart) return `flex-start`
    return 'center'
  }};
  gap: ${(props: IStyleProps) => {
    if (props.gap) return `${props.gap}px`
    return '0'
  }};

  .with-margin {
    margin-left: 60px;
  }
`
