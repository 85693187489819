import { createGlobalStyle } from 'styled-components'
import { ITheme } from './Themes'

interface IStyleProps {
  theme: ITheme
}

export default createGlobalStyle`
* {
  font-family: Roboto;
}

#root,
html,
body {
  background-color: ${(props: IStyleProps) => props.theme.backgroundColor};
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  &::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 50px;
    border: none;
  }

  a {
    text-decoration: none;
    cursor: default;
  }
}
`
