import { EsgCard, Modal, PagePathing } from 'components'
import icons, { IIcons } from 'helper/icons'
import { useFetch } from 'hooks'
import { useEffect, useState } from 'react'
import { Row, Content } from 'styles'
import DetalhesContrato from '../DetalhesContrato'
import { PageTitle, KanbanContainer, Img, ColumnTitle } from './style'

const kanbanStructure = [
  {
    label: 'Abertos',
    key: 'oppened',
    icon: 'lockOpenOutlined',
    color: '#03989E',
  },
  {
    label: 'Ativos',
    key: 'active',
    icon: 'checkOutlined',
    color: '#0BF569',
  },
  {
    label: 'Sinistros',
    key: 'sinister',
    icon: 'checkOutlined',
    color: '#CB6BE6',
  },
  {
    label: 'Cancelados',
    key: 'canceled',
    icon: 'canceledOutlined',
    color: '#D61212',
  },
  {
    label: 'Finalizados',
    key: 'closed',
    icon: 'lockClosedOutlined',
    color: '#2B2B2B',
  },
]

interface IDetailProps {
  purchaseId: number
  productId: number
  title: string
  productIcon: string
  productAttach: string
  column: {
    name: string
    color: string
    icon: keyof IIcons
  }
  isChangable?: boolean
}

export default () => {
  const api = useFetch('product/aluguel-verde/contract-list')
  const [data, setData] = useState<{ [key: string]: any }>({})
  const [showModal, setShowModal] = useState(false)
  const [extendedMenu, setExtendedMenu] = useState<any>(false)
  const [detaiProps, setDetailProps] = useState<IDetailProps>({
    purchaseId: 0,
    productId: 0,
    title: '',
    productIcon: '',
    productAttach: '',
    isChangable: false,
    column: {
      name: '',
      color: '',
      icon: 'agroGreenOutlined',
    },
  })

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const r = await api.fetch('get')
        if (r.fail) throw new Error()
        const { response } = r.data
        setData(response)
      } catch (e) {
        console.error(e)
      }
    }
    fetchUsers()
  }, [])

  const getPercentage = (min: string, total: string) => {
    const newMin = min.split(' ')[1].replace('.', '').replace(',', '.')
    const newTotal = total.split(' ')[1].replace('.', '').replace(',', '.')
    return Number(((Number(newTotal) * 100) / Number(newMin)).toFixed(2))
  }

  return (
    <Content withoutMarginLeft noMarginTop>
      <div style={{ marginLeft: '30px' }}>
        <PagePathing withoutMarginLeft>
          {'Gestão de produtos > Aluguel verde > Contratos'}
        </PagePathing>
        <Row gap={14} margin="21px 0 29px 0">
          <img src={icons.checkedContractOutlined} alt="lock-icon" />
          <PageTitle>Contratos</PageTitle>
        </Row>
      </div>

      <KanbanContainer className="kanbancontainer">
        {kanbanStructure.map((pipeline) => (
          <div className="column" key={pipeline.key}>
            <Row gap={14}>
              <Img
                src={icons[pipeline.icon]}
                alt="column-icon"
                color={pipeline.color}
              />
              <ColumnTitle color={pipeline.color}>{pipeline.label}</ColumnTitle>
            </Row>
            <div className="cards-container">
              {data[pipeline.key]?.map((item: any) => {
                return (
                  <EsgCard
                    aluguelVerde
                    tip={item.tip}
                    meta={item.meta}
                    onClick={() => {
                      setDetailProps({
                        ...item,
                        isChangable: true,
                        title: `Aluguel Verde ${item.type} #${
                          item.round < 10 ? `0${item.round}` : item.round
                        }`,
                        column: {
                          name: pipeline.label,
                          color: pipeline.color,
                          icon: pipeline.icon,
                        },
                      })
                      setShowModal(true)
                    }}
                    isBlocked={item.status === 1}
                    isChanged={{
                      value: 'Valor da cobertura: ',
                      parcel: ' Realizado',
                      paid: '',
                    }}
                    paid={''}
                    parcel={getPercentage(item.min, item.total)}
                    progress={Number(item.tip.split('%')[0])}
                    value={item.min}
                    rentability={item.profitability}
                    imageUrl={`https://sapiensbank.com.br/assets/${item.image}`}
                    months={item.deadline}
                    ribbon={item.type.toLowerCase()}
                  >
                    {`Aluguel Verde ${item.type} #${
                      item.round < 10 ? `0${item.round}` : item.round
                    }`}
                  </EsgCard>
                )
              })}
            </div>
          </div>
        ))}
      </KanbanContainer>
      <Modal
        FS
        setShowModal={setShowModal}
        showModal={showModal}
        extendedMenuData={extendedMenu}
      >
        <DetalhesContrato {...detaiProps} setExtendedMenu={setExtendedMenu} />
      </Modal>
    </Content>
  )
}
