import styled from 'styled-components'

interface IStyleProps {
  withoutMarginLeft?: boolean
  detailsMargin?: boolean
  noHeight?: boolean
}

export default styled.h2`
  min-height: ${(props) => {
    if (props.noHeight) return 'auto'
    return '40px'
  }};
  font-size: 20px;
  overflow: hidden;
  width: 600px;
  margin-top: ${(props: IStyleProps) => {
    if (props.detailsMargin) return '10px'
    return '20px'
  }};
  margin-right: 0;
  margin-bottom: ${(props: IStyleProps) => {
    if (props.detailsMargin) return '2px'
    return '20px'
  }};
  margin-left: ${(props: IStyleProps) => {
    if (props.withoutMarginLeft) return `10px`
    return '30px'
  }};
`
