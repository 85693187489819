import { useContext, useEffect, useState } from 'react'
import { Kanban, Modal } from 'components'
import { SapiensContext } from 'context'
import useFetch from 'hooks/useFetch'
import useSocket from 'hooks/useSocket'
import DetalhesModal from 'pages/DetalhesModal'

import { IKanbanColumns, IKanbanData, IObject } from 'interfaces'

export default function AluguelVerde() {
  const { searchParam, socket, setSocket } = useContext(SapiensContext)

  const [kanbanData, setKanbanData] = useState<IKanbanData>({})
  const [filteredData, setFilteredData] = useState({})
  const [userId, setUserId] = useState()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [extendedMenu, setExtendedMenu] = useState<any>(false)

  const userApi = useFetch(`product/aluguel-verde?type=owner`)

  useEffect(() => {
    userApi.fetch('get').then((res: { [key: string]: any }) => {
      if (res.status === 200) setKanbanData(res.data.response)
    })
  }, [])

  useEffect(() => {
    setFilteredData(kanbanData)
  }, [kanbanData])

  useEffect(() => {
    const shownData = Object.entries(kanbanData).reduce(
      (obj: { [key: string]: IKanbanColumns }, [columnId, { items }]) => {
        obj[columnId] = {
          ...kanbanData[columnId],
          items: items.filter(
            (item) =>
              item.name.includes(searchParam) ||
              item.cpf.includes(searchParam) ||
              item.email.includes(searchParam)
          ),
        }
        return obj
      },
      {}
    )
    setFilteredData(shownData)
  }, [searchParam])

  useSocket((io: IObject) => {
    if (setSocket) setSocket(io)
  }, socket)

  const onDropCard = async (id: string, col: number) => {
    userApi
      .fetch('put', { id, status: kanbanData[col].id })
      .then((res: { [key: string]: any }) => {
        if (res.status === 200) return setKanbanData(res.data.response)
        return false
      })
      .finally(() => {
        socket?.emit('refresh', { command: 'av_property' })
      })
  }

  const onCardClick = async (item: { [key: string]: any }) => {
    try {
      setUserId(item.accountId)
      setShowModal(true)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Kanban
        data={filteredData}
        setData={setKanbanData}
        onDropCard={onDropCard}
        onCardClick={onCardClick}
        withoutIcon
      />
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        FS
        extendedMenuData={extendedMenu}
      >
        <DetalhesModal userId={userId} setExtendedMenu={setExtendedMenu} />
      </Modal>
    </>
  )
}
