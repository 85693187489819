import styled from 'styled-components'

interface IStyleProps {
  login?: boolean
}

export default styled.div`
  display: flex;
  width: calc(100vw - 255px);
  width: ${(props: IStyleProps) => props.login && '100vw'};
  height: 100vh;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
`
