import { useState } from 'react'
import * as Style from './style'

interface IOption {
  label: string
  value?: any
}

interface IStyle {
  [key: string]: string | number 
}

interface IProps {
  style?: IStyle
  buttonStyle?: IStyle
  options: {
    [key: number]: IOption,
    map:(item?: any, index?: number) => any;
  } 
  onSelect?: (_e: any) => void
}

export default function (props: IProps) {
  const { style, options, buttonStyle, onSelect = () => {} } = props
  const [selected, setSelected] = useState<number>(0)

  const handleSelect = (value: any, index: number) => {
    onSelect(value || index);
    setSelected(index);
  }

  return (
    <Style.Container style={style} >
      {
        options.map((option: IOption, i: number) => (
          <Style.Button
            style={buttonStyle}
            selected={selected === i}
            onClick={() => handleSelect(option.value, i)}
          >
            { option.label }
          </Style.Button>
        ))
      }
    </Style.Container>
  )
}
