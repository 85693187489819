import { AccountStatus, PageTitle } from 'components'
import HistoryCard from 'components/Card/HistoryCard'
import useFetch from 'hooks/useFetch'
import { useEffect, useState } from 'react'
import { ExtendedContainer, CardsWrap } from './style'

interface IHistoryProps {
  id: number
  name: string
  userType: string
  status: string
  color: string
}
export default (props: IHistoryProps) => {
  const { id, name, status, userType, color } = props
  const [extract, setExtract] = useState([])
  const api = useFetch('user')

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const r = await api.fetch('get', {}, `user/historic/${id}`)
        if (r.fail) throw new Error()
        const { response } = r.data

        console.table(response)

        setExtract(response.reverse())
      } catch (e) {
        console.error(e)
      }
    }
    fetchUsers()
  }, [])

  return (
    <ExtendedContainer>
      <PageTitle withoutMarginLeft>{name}</PageTitle>
      <AccountStatus status={status} type={userType} color={color} />
      <PageTitle withoutMarginLeft>Extrato:</PageTitle>
      {extract.length > 0 && (
        <CardsWrap>
          {extract.map((item: { [key: string]: any }) => {
            return (
              <HistoryCard
                icon={item.icon}
                signal={item.signal}
                value={item.value}
                label={item.label}
                date={item.date}
              />
            )
          })}
        </CardsWrap>
      )}
    </ExtendedContainer>
  )
}
