import styled from 'styled-components'

export const TextPreview = styled.p`
  width: 307px;
  max-width: 307px;
  height: 22px;
  max-height: 22px;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
`

export const FormDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 50px;
`
