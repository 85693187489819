import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import { CONNECTION } from 'configs/configs.js'

import { useAuth } from '.'

interface IMethod {
  [key: string]: () => Promise<{ data: any; status: number; fail: false }>
}

const useFetch = (endpoint = '/') => {
  const { token, setToken, signout } = useAuth()

  const [curEndpoint, setEndpoint] = useState<string>(endpoint)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navigate = useNavigate()

  const parseError = (error: any) => {
    const { response, isAxiosError } = error
    if (response && response.data) {
      const { data } = response
      if (response.status === 501) {
        signout(() => navigate('/login'))
      }
      return data || []
    }
    if (isAxiosError) {
      return { message: 'Falha ao enviar dados ao servidor' }
    }
    return []
  }

  // TODO - Acrescentei o parâmetro URL provisóriamente até descobrir o motivo
  // pelo qual o setEndpoint não estar funcionando
  const fetch = async (request: any, data?: any, url?: string) => {
    const URL = `${CONNECTION.URL}${url || curEndpoint}`
    try {
      setIsLoading(true)
      const method: IMethod = {
        post: async () => {
          const r = await axios.post(URL, data, {
            headers: { Authorization: token },
          })
          if (r.data?.token) setToken(r.data.token)
          else if (curEndpoint === 'login') setToken(r.data.response)
          return { data: r.data, status: r.status, fail: false }
        },
        put: async () => {
          const r = await axios.put(URL, data, {
            headers: { Authorization: token },
          })
          if (r.data?.token) setToken(r.data.token)

          return { data: r.data, status: r.status, fail: false }
        },
        get: async () => {
          const r = await axios.get(URL, {
            headers: { Authorization: token },
          })
          if (r.data?.token) setToken(r.data.token)

          return { data: r.data, status: r.status, fail: false }
        },
        delete: async () => {
          const r = await axios.delete(URL, {
            headers: { Authorization: token },
            data,
          })
          if (r.data?.token) setToken(r.data.token)

          return { data: r.data, status: r.status, fail: false }
        },
      }
      const response = await method[request]()
      setIsLoading(false)
      return response
    } catch (error) {
      setIsLoading(false)
      return { data: null, fail: true, errors: parseError(error) }
    }
  }

  return {
    setEndpoint, // TODO - Por algum motivo, isto não está atualizando
    fetch,
    isLoading,
  }
}

export default useFetch
