import { useContext, useState, useEffect } from 'react'
import {
  Select,
  PageTitle,
  PagePathing,
  Input,
  ButtonNext,
  MaskedInput,
} from 'components'
import * as Yup from 'yup'
import { FormDiv, SubTitle, Plus, Error } from './style'
import { options as opt } from './factory'
import { Row } from 'styles/index'
import { Form } from '@unform/web'
import { moneyToNumber, numberToMoney } from 'helper/utils'
import { SapiensContext } from 'context/sapiens'

const schemaNewCupom = Yup.object()
  .shape({
    fullName: Yup.string().min(1).required(),
    tick: Yup.string().min(1).required(),
    name: Yup.string().min(1).required(),
    modalCaption: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.number().required(),
    }),
    tipoInvest: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.number().required(),
    }),
    garantia: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.number().required(),
    }),
    cetip: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.number().required(),
    }),
    metaCaption: Yup.number().required(),
    price: Yup.number().required(),
    supply: Yup.number().min(1).required(),
    minimumInvest: Yup.number().required(),
    periodoLanc: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.number().required(),
    }),
    prazo: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.number().required(),
    }),
    pagamentoJuros: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.number().required(),
    }),
    liquidez: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.number().required(),
    }),
    indexador: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.number().required(),
    }),
    profitability: Yup.number().min(1).required(),
    impostoRenda: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.number().required(),
    }),
    coatizationPre: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.number().required(),
    }),
    coatizationPos: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.number().required(),
    }),
    cupom: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.number().required(),
    }),
  })
  .typeError('Este input deve ser preenchido corretamente')

  const now = new Date()

const BasicInfoForm = ({ handleNext, indexList }: any) => {
  const { newCaption, setNewCaption } = useContext(SapiensContext)
  const [options, setOptions] = useState(opt)
  const [allowedNext, setAllowedNext] = useState<boolean>(false)
  const [paymentDate, setPaymentDate] = useState<string | undefined>(undefined)
  const [dateError, setDateEror] = useState<string>('')

  const returnNumber = (num: any) => (num < 10 ? `0${num}` : `${num}`)

  const addPrazo = (date: any, prazo: number, juros: string) => {
    switch (juros) {
      case 'd':
        return date.setDate(date.getDate() + 2)
      case 'm':
        return date.setMonth(date.getMonth() + 1)
      case 'sm':
        return date.setMonth(date.getMonth() + 6)
      case 'a':
        return date.setMonth(date.getMonth() + 12)
      case 'v':
        return date.setMonth(date.getMonth() + prazo)
      default:
        return date.setMonth(date.getMonth() + 1) 
    }
  }

  useEffect(() => {
    if (
      !newCaption?.periodoLanc ||
      !newCaption?.pagamentoJuros ||
      !newCaption?.prazo
    )
      return;
    setDateEror('')
    // console.log('data de hoje', now)
    const addDays = new Date().setDate(
      now.getDate() + newCaption.periodoLanc.num + newCaption.coatizationPre.num
    )
    const payDate = addPrazo(new Date(addDays), newCaption?.prazo.num, newCaption?.pagamentoJuros.num)
    console.log(payDate)
    const d = returnNumber(new Date(payDate).getDate())
    const m = returnNumber(new Date(payDate).getMonth() + 1)
    const y = returnNumber(new Date(payDate).getFullYear())
    setPaymentDate(`${d}/${m}/${y}`)
    if (d === '29' || d === '30' || d === '31') return setDateEror('l')
  }, [newCaption?.coatizationPre])

  useEffect(() => {
    const validateInputs = async () => {
      try {
        await schemaNewCupom.validate(newCaption, { abortEarly: false })
        setAllowedNext(true)
      } catch (err) {
        console.log(err)
        setAllowedNext(false)
      }
    }
    validateInputs()
  }, [newCaption])

  useEffect(() => {
    if (
      (newCaption?.pagamentoJuros?.value === 2 ||
        newCaption?.pagamentoJuros?.value === 3) &&
      newCaption?.prazo?.value === 0
    ) {
      setNewCaption((c: any) => ({
        ...c,
        pagamentoJuros: null,
      }))
    }
    if (newCaption?.prazo?.value === 0) {
      const filter = options?.juros.filter(
        (i: any) => i.value !== 2 && i.value !== 3
      )
      setOptions((c: any) => ({
        ...c,
        juros: filter,
      }))
    } else {
      setOptions((c: any) => ({
        ...c,
        juros: opt.juros,
      }))
    }
  }, [newCaption?.prazo, newCaption?.pagamentoJuros])

  useEffect(() => {
    if (newCaption.price && newCaption.supply) {
      setNewCaption((c: any) => ({
        ...c,
        metaCaption: newCaption.supply * newCaption.price,
      }))
    }
  }, [newCaption?.price, newCaption?.supply])

  useEffect(() => {
    if (newCaption?.pagamentoJuros && newCaption?.pagamentoJuros?.value !== 4) {
      setNewCaption((c: any) => ({
        ...c,
        indexador: {
          label: 'Pré fixado (Basic)',
          value: 0,
          flag: 'Pré-fixado',
        },
      }))
    }
  }, [newCaption?.pagamentoJuros])

  useEffect(() => {
    if (newCaption?.indexador?.value === 0) {
      setNewCaption((c: any) => ({
        ...c,
        index: null,
      }))
    }
  }, [newCaption?.indexador])

  useEffect(() => {
    if (newCaption?.modalCaption && newCaption?.modalCaption?.value === 0) {
      setNewCaption((c: any) => ({
        ...c,
        impostoRenda: { label: 'Descontado na fonte', value: 0 },
      }))
    }
  }, [newCaption?.modalCaption])

  const handleUnmask = (string: string, split: string) => string.split(split)[0]

  return (
    <>
      <PagePathing
        withoutMarginLeft
      >{`Gestão de produtos >  Investimentos  > Nova captação`}</PagePathing>
      <PageTitle withoutMarginLeft>Nova Captação</PageTitle>
      <FormDiv>
        <Form onSubmit={handleNext}>
          <SubTitle>Informações do produto</SubTitle>
          <Row gap={14} marginLeft={10}>
            <Input
              value={newCaption.fullName}
              className="new-caption-input"
              onChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  fullName: e.target.value,
                }))
              }}
              placeholder={'Nome completo do produto'}
              type="text"
              name={'fullName'}
            />
            <Input
              value={newCaption.name}
              className="new-caption-input"
              onChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  name: e.target.value,
                }))
              }}
              placeholder={'Nome resumido'}
              type="text"
              name={'name'}
            />
            <Input
              value={newCaption.tick}
              className="new-caption-input"
              onChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  tick: e.target.value,
                }))
              }}
              placeholder={'Sigla do produto'}
              type="text"
              name={'tick'}
            />
          </Row>
          <Row gap={14} marginLeft={10}>
            <Select
              value={newCaption.modalCaption}
              // value={novoCupom.campaignType}
              onChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  modalCaption: e,
                }))
              }}
              name="modalCaption"
              options={options.modalCaption}
              placeholder="Modalidade de captação"
              className="new-caption-select"
            />
            <Select
              // value={novoCupom.campaignType}
              value={newCaption.tipoInvest}
              onChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  tipoInvest: e,
                }))
              }}
              name="tipoInvest"
              options={options.tipoInvest}
              placeholder="Tipo de investimento"
              className="new-caption-select"
            />
            <Select
              small
              value={newCaption.garantia}
              // value={novoCupom.campaignType}
              onChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  garantia: e,
                }))
              }}
              name="garantia"
              options={options.garantia}
              placeholder="Garantia"
              className="new-caption-select"
            />

            <Select
              // value={novoCupom.campaignType}
              value={newCaption.cetip}
              small
              onChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  cetip: e,
                }))
              }}
              name="cetip"
              options={options.cetip}
              placeholder="Selo CETIP"
              className="new-caption-select"
            />
          </Row>
          <SubTitle>Informações da captação</SubTitle>
          <Row gap={14} marginLeft={10}>
            <MaskedInput
              defaultValue={newCaption.price}
              type="money"
              handleChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  price: Number(moneyToNumber(e)),
                }))
              }}
              placeholder="Preço da cota"
              name="price"
            />
            <Input
              value={newCaption.supply}
              type="number"
              onChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  supply: Number(e.target.value),
                }))
              }}
              placeholder="Total de cotas"
              name="supply"
              className="new-caption-input"
            />
            <MaskedInput
              // defaultValue={newCaption.price}
              disabled
              type="money"
              handleChange={() => {}}
              placeholder="Meta da captação"
              name="metaCaption"
              defaultValue={
                newCaption.metaCaption
                  ? `R$ ${numberToMoney(newCaption.metaCaption)}`
                  : ''
              }
            />
          </Row>
          <Row gap={14} marginLeft={10}>
            <MaskedInput
              defaultValue={newCaption.minimumInvest}
              type="money"
              handleChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  minimumInvest: Number(moneyToNumber(e)),
                }))
              }}
              placeholder="Investimento mínimo"
              name="minimumInvest"
            />
            <Select
              value={newCaption.periodoLanc}
              onChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  periodoLanc: e,
                }))
              }}
              name="periodoLanc"
              options={options.periodoLanc}
              placeholder="Período de lançamento"
              className="new-caption-select"
            />
          </Row>
          <SubTitle>Rentabilidade</SubTitle>
          <Row gap={14} marginLeft={10}>
            <Select
              value={newCaption.prazo}
              // value={novoCupom.campaignType}
              onChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  prazo: e,
                }))
              }}
              name="prazo"
              options={options.prazo}
              placeholder="Prazo de contrato"
              className="new-caption-select"
            />
            <Select
              // value={novoCupom.campaignType}
              value={newCaption.pagamentoJuros}
              onChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  pagamentoJuros: e,
                }))
              }}
              name="pagamentoJuros"
              options={options.juros}
              placeholder="Pagamento dos juros"
              className="new-caption-select"
            />
            <Select
              // value={novoCupom.campaignType}
              value={newCaption.liquidez}
              onChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  liquidez: e,
                }))
              }}
              name="liquidez"
              options={options.liquidez}
              placeholder="Liquidez"
              className="new-caption-select"
            />
          </Row>
          <Row gap={14} marginLeft={10}>
            <Select
              // value={novoCupom.campaignType}
              disabled={
                newCaption?.pagamentoJuros &&
                newCaption?.pagamentoJuros.value !== 4
              }
              onChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  indexador: e,
                }))
              }}
              name="indexador"
              options={options.indexador}
              placeholder="Indexador"
              className="new-caption-select"
              value={newCaption?.indexador}
            />
            {/* <Row gap={5} noMarginLeft> */}

            <Select
              value={newCaption?.index}
              onChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  index: e,
                }))
              }}
              disabled={newCaption?.indexador?.value === 0}
              name="index"
              options={indexList}
              placeholder="Índice"
              className="new-caption-select"
              small
            />
            <Plus>+</Plus>
            <MaskedInput
              defaultValue={newCaption.profitability}
              type="jurosaa"
              handleChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  profitability: Number(handleUnmask(e, '%')),
                }))
              }}
              placeholder="Juros a.a."
              name="profitability"
              small
            />
            {/* </Row> */}
            <Select
              disabled={
                newCaption?.modalCaption &&
                newCaption?.modalCaption?.value === 0
              }
              value={newCaption?.impostoRenda}
              onChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  impostoRenda: e,
                }))
              }}
              name="impostoRenda"
              options={options.ir}
              placeholder="Imposto de renda"
              className="new-caption-select"
            />
          </Row>
          <Row gap={14} marginLeft={10}>
            <Select
              initError={dateError}
              // value={novoCupom.campaignType}
              value={newCaption?.coatizationPre}
              onChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  coatizationPre: e,
                }))
              }}
              name="coatizationPre"
              options={options.coatizationPre}
              placeholder="Período de cotização de entrada"
              className="new-caption-select"
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <MaskedInput
                disabled
                type="date"
                handleChange={(e: any) => {
                  setPaymentDate(e)
                }}
                placeholder="Data do Primeiro Pagamento"
                name="cupomExpiration"
                defaultValue={paymentDate}
                error={dateError}
              />
              {dateError && (
                <Error>
                  Cálculo inválido. Variáveis devem resultar em datas entre
                  01-28
                </Error>
              )}
            </div>
            <Select
              // value={novoCupom.campaignType}
              value={newCaption?.coatizationPos}
              onChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  coatizationPos: e,
                }))
              }}
              name="coatizationPos"
              options={options.coatizationPos}
              placeholder="Período de cotização de saída"
              className="new-caption-select"
            />
          </Row>
          <SubTitle>Cupom</SubTitle>
          <Row gap={14} marginLeft={10}>
            <Select
              // value={novoCupom.campaignType}
              value={newCaption?.cupom}
              onChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  cupom: e,
                }))
              }}
              name="cupom"
              options={options.cupom}
              placeholder="Uso do cupom"
              className="new-caption-select"
            />
            {/* {newCaption?.cupom?.label ==='Aceita Cupons específicos' && (
              <Select
              // value={novoCupom.campaignType}
              onChange={(e: any) => {
                setNewCaption((c: any) => ({
                  ...c,
                  cupomList: e,
                }))
              }}
              name="cupomList"
              options={options.cupom}
              placeholder="Lista de cupons"
              className="new-caption-select"
            />
            )} */}
          </Row>
          <ButtonNext disabled={!allowedNext && dateError === ''} />
        </Form>
      </FormDiv>
    </>
  )
}

export default BasicInfoForm
