import { Row } from 'styles'
import {
  Select,
  PageTitle,
  PagePathing,
  Input,
  MaskedInput,
  ButtonNext,
} from 'components'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import { useContext, useEffect, useRef, useState } from 'react'
import { SapiensContext } from 'context/sapiens'
import { FormDiv, DateContainer, CalendarIcon } from './style'
import { Outlined } from 'assets/Icons'
import { UUID, moneyToNumber } from 'helper/utils'
import { IObject } from 'interfaces'

const schemaNewCupom = Yup.object()
  .shape({
    campaignType: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.number().required(),
    }),
    cupomName: Yup.string().required(),
    cupomCode: Yup.string().min(5).max(8).required(),
    cupomExpiration: Yup.string().min(8).required(),
    cupomValue: Yup.number().required(),
    cupomSupply: Yup.number().min(1).required(),
  })
  .typeError('Este input deve ser preenchido corretamente')

export default function NovoCupom({ handleNext, campaignList }: any) {
  const { setNovoCupom, novoCupom } = useContext(SapiensContext)
  const [allowNextPage, setAllowNextPage] = useState(false)
  
  const formRef = useRef<HTMLFormElement | any>(null)
  useEffect(() => {
    const code = UUID().split('-').map((v) => v[Math.floor(Math.random() * 4)]).join('');
    setNovoCupom((c: IObject) => ({ ...c, cupomCode: code.toUpperCase() }))
  }, []);

  useEffect(() => {
    const validateInputs = async () => {
      try {
        await schemaNewCupom.validate(novoCupom, { abortEarly: false })
        if (novoCupom.campaignType === 'Tipo de Campanha') return; 
        setAllowNextPage(true)
      } catch (err) {
        console.log(err)
        setAllowNextPage(false)
      }
    }
    validateInputs()
  }, [novoCupom])

  const handleChangePage = async () => {
    formRef.current.setErrors({})
    try {
      setNovoCupom(novoCupom)
      handleNext()
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: {
          [key: string]: { path: string; message: string }
        } = {}
        err.inner.forEach((error: any) => {
          validationErrors[error.path] = error.message
        })
        formRef.current.setErrors(validationErrors)
      } else {
        console.log(err)
      }
    }
  }

  return (
    <>
      <PagePathing
        withoutMarginLeft
      >{`Gestão de cupons > Novo cupom`}</PagePathing>
      <PageTitle withoutMarginLeft>Novo Cupom</PageTitle>
      <FormDiv>
        <Form
          onSubmit={handleChangePage}
          ref={formRef}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Row gap={14}>
              <Select
                value={novoCupom.campaignType}
                onChange={(e: any) => {
                  setNovoCupom((c: IObject) => ({
                    ...c,
                    campaignType: e,
                  }))
                }}
                name="campaignType"
                options={campaignList}
                placeholder="Tipo de campanha"
                className="new-cupom-select"
              />
              <Input
                className="new-cupom-input"
                onChange={(e: any) => {
                  setNovoCupom((c: IObject) => ({
                    ...c,
                    cupomName: e.target.value,
                  }))
                }}
                placeholder={'Nome do cupom'}
                type="text"
                name={'cupomName'}
              />
            </Row>
            <Row gap={14}>
              <Input
                className="new-cupom-input"
                disabled
                placeholder={'Código do cupom'}
                type="text"
                name={'cupomCode'}
                value={novoCupom.cupomCode}
              />
              <MaskedInput
                type="money"
                handleChange={(e: any) => {
                  setNovoCupom((c: IObject) => ({
                    ...c,
                    cupomValue: moneyToNumber(e),
                  }))
                }}
                placeholder="Valor do cupom"
                name="cupomValue"
              />
            </Row>
            <Row gap={14}>
              <DateContainer>
                <MaskedInput
                  type="date"
                  handleChange={(e: any) => {
                    setNovoCupom((c: IObject) => ({
                      ...c,
                      cupomExpiration: e,
                    }))
                  }}
                  placeholder="Prazo de expiração do cupom"
                  name="cupomExpiration"
                />
                <CalendarIcon
                  src={Outlined('calendarGrey')}
                  alt="calendar-icon"
                />
              </DateContainer>
              <Input
                type="number"
                onChange={(e: any) => {
                  setNovoCupom((c: IObject) => ({
                    ...c,
                    cupomSupply: e.target.value,
                  }))
                }}
                placeholder="Quantidade limite de cupom"
                name="cupomSupply"
                className="new-cupom-input"
              />
            </Row>
          </div>
          <div>
            <ButtonNext disabled={!allowNextPage} />
          </div>
        </Form>
      </FormDiv>
    </>
  )
}
