import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
  useContext,
} from 'react'
import {
  PagePathing,
  PageTitle,
  Card,
  ClickableCard,
  AccountStatus,
  ButtonNext,
} from 'components'
import { SapiensContext } from 'context/sapiens'
import useFetch from 'hooks/useFetch'
// import HistoryMenu from './HistoryMenu'
import { CardsWrap, Wrapper } from '../style'
import { Row } from 'styles/index'

// PAGES

const CONTAINER_WIDTH = 80

const mock = {
  cupomCode: 'abc123',
  cupomValue: 'R$ 250,00',
  campaignType: 'Abertura de conta',
  cupomExpiration: '10/09/2023',
  supply: '10/100',
  limit: 'R$ 100.000,00',
}

export default function DetalhesCupom({ data }: any) {
  return (
    <Wrapper>
      <div className="content-wrapper">
        <PagePathing withoutMarginLeft>Detalhes de cupom</PagePathing>
        <PageTitle detailsMargin withoutMarginLeft>
          Cupom Conta Aberta
        </PageTitle>
        <div className="margem">
          <AccountStatus
            status={data?.status?.name}
            // type={data.userType}
            color={data?.status?.color}
          />
        </div>
        <CardsWrap width={`${CONTAINER_WIDTH}%`}>
          <Row gap={16} noMarginLeft>
            <Card value={data?.code} icon="ticketBlack">
              Código do cupom
            </Card>
            <Card wide value={data?.max} icon="coinOutlined">
              Valor do cupom
            </Card>
            <Card
              wide
              value={data?.campaignName}
              icon="crosshairOutlined"
              // detailsCard
            >
              Tipo de campanha
            </Card>
          </Row>
          <Row gap={16} margin="16px 0 0 0" noMarginLeft>
            <Card
              value={data?.expiresAt}
              icon="calendarOutlined"
              // detailsCard
            >
              Prazo de expiração
            </Card>
            <Card
              wide
              value={data?.amount}
              icon="crosshairOutlined"
              // detailsCard
            >
              Qtd disponível
            </Card>
            <Card
              wide
              value={data?.max}
              icon="databaseBlack"
              // detailsCard
            >
              Limite de cupom
            </Card>
          </Row>
        </CardsWrap>
      </div>
    </Wrapper>
  )
}
