import styled from "styled-components"

export const Subtitles = styled.h2`
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin: 0;
`
export const Content = styled.h4`
  color: #9e9e9e;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin: 0;

  span {
    text-decoration-line: underline;
    color: #3e788a;
  }
`
export const SmallContent = styled.h5`
  display: flex;
  margin: 0;
  color: #bdbdbd;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  span {
    font-weight: 700;
    color: #757575;
  }
`

export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: white;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }
`

export const Wrap = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 70px;
  margin-left: 10px;
  
` 