import * as r from 'assets/Ribbon'

export interface IRibbons {
  basic: string
  crypto: string
  personal: string
  pro: string
  soon: string
  basicblack: string
  problack: string
  personalblack: string
  soonblack: string
}

const ribbons: IRibbons = {
  basic: r.basic,
  crypto: r.crypto,
  personal: r.personal,
  pro: r.pro,
  soon: r.soon,
  basicblack: r.basicblack,
  problack: r.problack,
  personalblack: r.personalblack,
  soonblack: r.soonblack,
}

export default ribbons
