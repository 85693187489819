import styled from 'styled-components'

interface IStyleProps {
  width?: number
  marginBottom?: number
  height?: string
  background?: string
}

interface IButtonStyleProps {
  active?: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  height: 95%;
  width: 94%;
  padding: 50px 0 0 50px;
  background-color: #f5f4f3;
  gap: 5px;

  .page-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    margin: 0;
    text-align: left;
  }

  .column-title {
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    margin: 20px 30px 0 30px;
  }
  .input-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 13px;
    align-items: center;
    width: 180px;
    height: 40px;
    background: #ffffff;
    border-radius: 5px;
    margin: 20px 0px;
    padding: 0px 12px;
  }

  .lupa {
    filter: invert(97%) sepia(2%) saturate(3884%) hue-rotate(192deg)
      brightness(114%) contrast(66%);
  }
`

export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  width: ${(props: IStyleProps) => {
    return `${props.width}px`
  }};
  height: ${(props: IStyleProps) => {
    if (props.height) return props.height
    return 'auto'
  }};
  margin-bottom: ${(props: IStyleProps) => {
    return `${props.marginBottom}px`
  }};
  background-color: ${(props: IStyleProps) => {
    if (props.background) return `${props.background}`
    return 'transparent'
  }};
`
export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 5px;
  flex-direction: column;
  height: 95%;
  margin: 30px 10px 20px 30px;
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 8px;
    background-color: ${(props: IStyleProps) => {
      if (props.background) return `${props.background}`
      return 'transparent'
    }};
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: ${(props: IStyleProps) => {
      if (props.background) return `${props.background}`
      return 'transparent'
    }};
    border: 0;
    margin-left: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }
`
export const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;

  box-sizing: border-box;
  padding: 10px 12px;

  margin: 20px 0px;

  height: 40px;
  width: 180px;

  .leftIcon {
    position: relative;
    height: 25px;
    left: 0;
    margin-right: 12px;
    filter: invert(98%) sepia(1%) saturate(1173%) hue-rotate(259deg)
      brightness(113%) contrast(66%);
  }
`
export const MenuButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  font-size: 10px;
  font-style: normal;
  font-weight: ${(props: IButtonStyleProps) => {
    if (props.active) return '700'
    return '400'
  }};
  line-height: 15px;
  letter-spacing: 0.01em;
  text-align: left;

  color: ${(props: IButtonStyleProps) => {
    if (props.active) return '#f9f9f9'
    return '#4c4c4c'
  }};

  background-color: ${(props: IButtonStyleProps) => {
    if (props.active) return '#191919 !important'
    return '#f9f9f9'
  }};

  border: 1px solid #f5f4f3;
  border-right: 0;
  height: 30px;

  overflow: hidden;
  width: 180px;

  img {
    transition: width 1s;
    margin: 0 10px 0 20px;
    width: 10px;
    height: 10px;

    filter: ${(props: IButtonStyleProps) => {
      if (props.active)
        return 'invert(96%) sepia(99%) saturate(1%) hue-rotate(167deg) brightness(104%) contrast(95%)'
      return 'invert(31%) sepia(0%) saturate(0%) hue-rotate(269deg) brightness(90%) contrast(91%)'
    }};
  }
`
export const SearchBar = styled.input`
  height: 40px;
  width: 180px;

  outline: none;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  box-sizing: border-box;

  color: #4c4c4c;
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.01em;

  ::placeholder {
    color: #d4d4d4;
  }
`
