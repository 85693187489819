import ButtonNext from 'components/ButtonNext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect, useState } from 'react'
import { KanbanCard, PagePathing, Select } from 'components'
import { Form } from '@unform/web'
import { maskCPForCNPJ } from 'helper/utils'
import { SapiensContext } from 'context/sapiens'
import { ContentContainer, PageText, CardsContainer } from './style'

// interface IGetUserResponse {
//   icon?: string
//   id?: string
//   moveable?: boolean
//   name?: string
//   items: {
//     birthday: string | null
//     cpf: string | null
//     created: string | null
//     email: string | null
//     id: string | null
//     name: string | null
//     nickname: string | null
//     step: string | null
//   }[]
// }

// interface IuserList {
//   label: string
//   value: string
// }

export default function CaptacaoPersonal({ handleNext }: any) {
  const { selected, setSelected } = useContext(SapiensContext)
  const [usersList, setUsersList] = useState([])
  const [options, setOptions] = useState<any>([])
  const [selectedUsers, setSelectedUsers] = useState<any>([])

  const api = useFetch('user')

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const r = await api.fetch('get')
        if (r.fail) throw new Error()
        const { response }: any = r.data
        // console.log(response)
        const keys: any = Object.values(response).filter((i: any) => {
          return i.name === 'Conta Ativa'
        })
        setUsersList(keys[0].items)
        const arr = keys[0].items.map((item: any) => {
          return {
            label: item.name,
            value: maskCPForCNPJ(item.cpf),
          }
        })
        setOptions(arr)
        // console.log(options)
      } catch (e) {
        console.log(e)
      }
    }
    fetchUsers()
  }, [])

  const handleSelect = (item: any) => {
    const found = usersList.find((i: any) => {
      return i.cpf === item.value
    })
    selectedUsers.push(found)
    const filteredOp = options.filter((i: any) => {
      return i.value !== item.value
    })
    setOptions(filteredOp)
  }

  const handleRemove = (e: any) => {
    const filterSelected = selectedUsers.filter((user: any) => {
      return user.cpf !== e.cpf
    })
    setSelectedUsers(filterSelected)
    options.push({
      label: e.name,
      value: e.cpf,
    })
  }

  const handleNextCall = () => {
    const sry = selectedUsers.map((i: any) => {
      return i.cpf
    })
    setSelected({
      ...selected,
      documentList: sry,
    })
    handleNext()
  }

  return (
    <div>
      <PagePathing
        withoutMarginLeft
      >{`Gestão de produtos > Investimentos ESG > Nova captação > Resumo para confirmação`}</PagePathing>
      <PageText color="20px">Captação personal</PageText>
      <ContentContainer>
        <div
          style={{
            marginRight: '50px',
          }}
        >
          <PageText color="18px">Selecionar clientes</PageText>
          <Form
            onSubmit={() => {
              return null
            }}
          >
            <Select
              name="Select"
              placeholder="CPF ou CNPJ"
              options={options.sort((a: any, b: any) => {
                if (a.label > b.label) {
                  return 1
                }
                if (a.label < b.label) {
                  return -1
                }
                return 0
              })}
              onChange={(e) => handleSelect(e)}
            />
          </Form>
        </div>
        <CardsContainer>
          {/* {console.log(selectedUsers)} */}
          {selectedUsers.map((user: any) => {
            return (
              <div style={{ marginRight: '14px', marginTop: '14px' }}>
                <KanbanCard
                  color="#191919"
                  trash
                  column={0}
                  name={user.name}
                  cpf={maskCPForCNPJ(user.cpf)}
                  email={user.email}
                  onClickTrash={handleRemove}
                />
              </div>
            )
          })}
        </CardsContainer>
        <ButtonNext
          onClick={() => handleNextCall()}
          disabled={selectedUsers.length === 0}
        />
      </ContentContainer>
    </div>
  )
}
