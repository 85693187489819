import styled from 'styled-components'

export const Subtitles = styled.h2`
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-top: 15px;
  margin-bottom: 0;
`
export const FormDiv = styled.div`
  overflow-x: hidden;
  height: ${window.innerHeight - 290}px;
  width: 100%;
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: white;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
`
