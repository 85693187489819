import { PagePathing, PageTitle, KanbanSubMenu } from 'components'
import { SapiensContext } from 'context'
import { useContext, useEffect, useState } from 'react'
import { Content, CardsContainer } from 'styles'
import { icons } from 'helper'
import { useLocation } from 'react-router-dom'

import KanbanSaques from './Saques'
import KanbanRentabilidade from './Rentabilidade'
import KanbanParcelamentoAluguel from './ParcelamentoAluguel'
import KanbanFee from './Fee'
import KanbanBonus from './Bonus'

export default function GestaoContratos() {
  const { extended } = useContext(SapiensContext)
  const location = useLocation()

  const [currentPage, setCurrentPage] = useState('')

  const categories = [
    ['Saques', `saques`, icons.withdrawSolid, false],
    ['Rentabilidades', `rentabilidades`, icons.stonksOutlined, false],
    ['Parcelamento de aluguel', `parcelamentos`, icons.houseGreenOutlined, false],
    ['Fee de parceiros', `fee`, icons.moneyRunSolid, false],
    ['Bônus de performance', `bonus`, icons.moneyRunSolid, false],
  ]

  useEffect(() => {
    const [page] = location.pathname.split('/').reverse()
    setCurrentPage(page)
  }, [location.pathname])

  return (
    <Content extended={extended}>
      <div>
        <PagePathing>Movimentações</PagePathing>
        <PageTitle>Movimentações</PageTitle>
        <KanbanSubMenu categories={categories} />
      </div>
      <CardsContainer marginLeft>
        {
          {
            saques: <KanbanSaques />,
            rentabilidades: <KanbanRentabilidade />,
            parcelamentos: <KanbanParcelamentoAluguel />,
            fee: <KanbanFee />,
            bonus: <KanbanBonus />,
          }[currentPage]
        }
      </CardsContainer>
    </Content>
  )
}
