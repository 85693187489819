import {
  numberToMoney,
  addDaysToDate,
  addMonthsToDate,
  datetimeToDate,
} from 'helper/utils'

export interface IObject {
  [key: string]: any
}

export interface IArray {
  [key: number]: any
  replace: (str: string | RegExp, rule: any) => any
}

export interface IListArray {
  [key: number]: any
  map: (item?: any, index?: number) => any
}

export default class {
  data: IObject
  indexList: []

  constructor(data: IObject) {
    this.data = data
    this.indexList = []
  }

  static cardList(): IListArray {
    return [
      {
        title: 'Detalhes do investimento',
        cards: [
          ['Data de resgate', '^burnAt'],
          ['Rentabilidade bruta', '%profitability% a.a.'],
          ['Rentabilidade bruta do CDI', '^profBaseCDI% do CDI'],
          ['Rentabilidade líquida a.a.', '^profLiquid% a.a.'],
          ['Rentabilidade líquida do CDI', '^profLiqCDI% a.a.'],
          ['Taxa do DI futuro', '^diTax% a.a.'],
          ['Lucro acima da poupança', '^profBasePoup%'],
          ['Investimento mínimo', 'R$ ^minInvest'],
          ['Pagamento dos juros', '^recurrence'],
          ['Indexador', '^feeType'],
          ['Garantia', '%guarantee'],
          ['Selo CETIP certifica', '%cetip'],
          ['Liquidez', '^paymentType'],
        ],
      },
      {
        title: 'Cenário Base',
        cards: [
          ['Exposição máxima', '^maxExp'],
          ['Rentabilidade Bruta CDI', '^profBaseB% do CDI'],
          ['Prazo', '%deadline meses'],
          ['Rentabilidade - & TIR', '100%'],
          ['Múltiplo', '^multB x'],
        ],
      },
      {
        title: 'Cenário Otimista',
        cards: [
          ['Exposição máxima', '^maxExp'],
          ['Rentabilidade Bruta CDI', '^profBaseO% do CDI'],
          ['Prazo', '%deadline meses'],
          ['Rentabilidade - & TIR', '100%'],
          ['Múltiplo', '^multO x'],
        ],
      },
      {
        title: 'Cenário Pessimista',
        cards: [
          ['Exposição máxima', '^maxExp'],
          ['Rentabilidade Bruta CDI', '^profBaseP% do CDI'],
          ['Prazo', '%deadline meses'],
          ['Rentabilidade - & TIR', '100%'],
          ['Múltiplo', '^multP x'],
        ],
      },
      {
        title: 'Garantia e mitigante',
        cards: [
          ['Certificado de depósito bancário', '^guaranteeCCB'],
          ['Patrimônio da empresa', '^guaranteePatr'],
          ['Patrimônio dos sócios', '^guaranteeSoc'],
        ],
      },
      {
        title: 'Detalhes da operação',
        cards: [
          ['Liquidação'],
          ['Tributação'],
          ['Legislação'],
          ['Composição'],
          ['Cenário Macro Econômico'],
          ['Risco Operacional'],
          ['Risco Projetado'],
          ['Fluxo de operações'],
          ['Fluxo de recebimento'],
          // ['Contratos'],
        ],
      },
    ]
  }

  #getCetip(): string {
    return ['Sim', 'Não'][this.data.cetip.value]
  }

  #discountIR(value: number): number {
    const recurrence = this.data.pagamentoJuros.num || 'm'
    let deadline = this.data.deadline || 36
    switch (recurrence) {
      case 's':
        deadline = deadline * 7
        break
      case 'm':
        deadline = deadline * 30.42
        break
      case 'sm':
        deadline = deadline * 182.5
        break
      case 'a':
        return value - value * 0.15
    }

    if (deadline < 180) return value - value * 0.225
    else if (deadline < 360) return value - value * 0.2
    else if (deadline < 720) return value - value * 0.175
    else return value - value * 0.15
  }

  #burnAt(): Date {
    const now = new Date()
    const deadline = this.data.prazo.num || 12
    const tradeAt = this.data.coatizationPre.num + 1
    const icoAt = this.data.periodoLanc.num
    const cotPos = this.data.coatizationPos.num
    const addMonth = now.setMonth(now.getMonth() + deadline)
    const addDays = new Date(addMonth).setDate(new Date(addMonth).getDate() + tradeAt + icoAt + cotPos)
    return new Date(addDays)
  }

  #base (index: string): number {
    index = index.toUpperCase()
    const c: any = this.indexList.find((i: IObject) => {
      return i.name === index
    });

    if (!c) return 0
    const profitability = (this.data.profitability || 0) * 100
    return Number((profitability / c?.aa).toFixed(2));
  }

  #oBase () {
    const c: any = this.indexList.find((i: IObject) => i.name === 'SELIC');
    const value = c * 1.3;
    const cdi = this.#base('CDI');
    return cdi * value;
  }

  #dif() : number {
    const c: any = this.indexList.find((i: IObject) => i.name === 'SELIC');
    return Number((c?.aa * 1.1).toFixed(2));
  }

  #recurrence(type: number = 0): string {
    const recurrence = this.data.pagamentoJuros.num || 'm'
    switch (recurrence) {
      case 'd':
        return ['Diário', 'Dias'][type]
      case 's':
        return ['Semanal', 'Semanas'][type]
      case 'm':
        return ['Mensal', 'Meses'][type]
      case 'sm':
        return ['Semestral', 'Semestres'][type]
      case 'a':
        return ['Anual', 'Anos'][type]
      case 'v':
        return ['No Vencimento', ''][type]
      default:
        return ['Mensal', 'Meses'][type]
    }
  }

  #feeType(): string {
    return this.data.indexador.flag
  }

  #paymentType(): string {
    return ['No vencimento', 'Na recorrência', 'Diário', 'Anual'][
      this.data.liquidez.value || 0
    ]
  }

  #guarantee(type: string): string {
    return (this.data.garantia.flag || '')
      .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .replace(/ /gi, '') === type.toLowerCase() ? 'Sim' : 'Não'
  }

  getFormated(list: IArray): any {
    return (
      list
        // Direto
        .replace(/%profitability/g, this.data.profitability || 0)
        .replace(/%guarantee/g, this.data.garantia.flag || 'Nenhuma')
        .replace(/%cetip/g, this.#getCetip())
        .replace(/%deadline/g, this.data.prazo.num || 36)
        .replace(/%recurrence/g, this.#recurrence(1))

        // Calculado
        .replace(/\^burnAt/g, datetimeToDate(this.#burnAt()))
        .replace(/\^profBaseCDI/g, this.#base('CDI'))
        .replace(
          /\^profLiquid/g,
          this.#discountIR(this.data.profitability || 0).toFixed(2)
        )
        .replace(/\^profLiqCDI/g, this.#discountIR(this.#base('CDI')).toFixed(2))
        .replace(/\^diTax/g, this.#dif())
        .replace(/\^profBasePoup/g, (this.#base('POUPANCA') - 100).toFixed(2))
        .replace(/\^minInvest/g, numberToMoney(this.data.minimumInvest || 1))
        .replace(/\^recurrence/g, this.#recurrence())
        .replace(/\^feeType/g, this.#feeType())
        .replace(/\^paymentType/g, this.#paymentType())
        .replace(/\^guaranteeCCB/g, this.#guarantee('cdb-fgc'))
        .replace(/\^guaranteePatr/g, this.#guarantee('ativos'))
        .replace(/\^guaranteeSoc/g, this.#guarantee('avalsocios'))

        // Cenários
        .replace(/\^maxExp/g,`R$ ${numberToMoney((this.data.price * this.data.supply).toFixed(2))}`)
        .replace(/\^multB/g, (this.#base('CDI') / 100).toFixed(2))
        .replace(/\^multP/g, ((this.#base('CDI') / 100) * .97).toFixed(2))
        .replace(/\^multO/g, ((this.#base('CDI') / 100) * 1.03).toFixed(2))
        .replace(/\^profBaseB/g, this.#base('CDI').toFixed(2))
        .replace(/\^profBaseP/g, (this.#base('CDI') * .97).toFixed(2))
        .replace(/\^profBaseO/g, (this.#base('CDI') * 1.03).toFixed(2))
    )
  }
}
