import styled from 'styled-components'
import { ITheme } from 'styles/Themes'

interface IStyleProps {
  black?: boolean
  disabled?: boolean
  green?: boolean
  wide?: boolean
  value?: number
  theme: ITheme
}

export default styled.div`
  display: flex;
  flex-direction: column;
  height: 190px;
  width: 315px;
  cursor: ${(props: IStyleProps) => {
    if (props.disabled) return ''
    return 'pointer'
  }};
  box-shadow: 0px 3px 4px 0px ${(props: IStyleProps) => props.theme.boxShadow};

  border-radius: 10px;
  background: ${(props: IStyleProps) =>
    props.theme.card.backgroundColor.secondary};

  .image {
    background-size: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 315px;
    height: 120px;
    filter: brightness(50%);
  }

  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    z-index: 1;
    color: ${(props: IStyleProps) => props.theme.card.fontColor.secondary};
    position: relative;
    top: -20px;
    left: 20px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin: 10px 20px;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    background: ${(props: IStyleProps) =>
      props.theme.card.backgroundColor.secondary};
    color: ${(props: IStyleProps) => props.theme.card.fontColor.esg};

    b {
      color: ${(props: IStyleProps) => props.theme.card.fontColor.esgBold};
      font-weight: 700;
    }

    .flavor {
      font-size: 10px;

      img {
        margin-right: 3px;
        width: 10px;
        height: 10px;
        filter: ${(props: IStyleProps) => props.theme.card.icon.secondary};
      }
    }
  }
`
