import styled from 'styled-components'

export const TextPreview = styled.p`
  width: 307px;
  max-width: 307px;
  height: 22px;
  max-height: 22px;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
`

export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 7%;
  /* border: 1px solid red; */
  gap: 50px;
`

export const DateContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`

export const CalendarIcon = styled.img`
  position: absolute;
  top: 16px;
  right: 20px;

`