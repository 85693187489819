import styled from 'styled-components'
import { getColor } from 'helper/filterColor'

interface IProps {
  complete?: any
  noWeigth?: boolean
  error?: boolean
}

export const UploadImg = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 0;
  height: 56px;
  width: 315px;
  max-width: 315px;
  padding: 20px 17px 20px 20px;
  box-sizing: border-box;
  border: ${(props: IProps) => {
    if (props.error) return '1px solid #E41D1D'
    if (props.complete) return `1px solid #3E788A`
    return '1px dashed #9c948c'
  }};
  border-radius: 5px;
  background: #f9f9f9;
  &:hover {
    background: #8f888133;
  }
  &:disabled {
    &::placeholder {
      color: #fff;
    }
    background: lightGray;
    border: 0;
  }

  .weight {
    color: #bdbdbd;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    /* margin: 0 16px 0 0; */
    margin: ${(props: IProps) => {
      if (props.complete) return `0 40px 0 0`
      return '0 16px 0 0'
    }};
  }

  .placeholder {
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    margin: 0 0 0 16px;
    /* border: 1px solid red; */
    width: ${(props: IProps) => {
      if (props.noWeigth) return `195px`
      return '140px'
    }};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .row {
    display: flex;
    align-items: center;
  }

  input {
    display: none;
  }

  .icon {
    ${(props: IProps) => {
      if (props.error) return getColor('#E41D1D')
      return getColor('#3E788A')
    }} 
  }
`

export const Ancor = styled.div`
  position: relative;
  width: 315px;
`

export const ButtonIcon = styled.img`
  position: absolute;
  z-index: 21;
  ${(props: IProps) => {
      if (props.error) return getColor('#E41D1D')
      return getColor('#3E788A')
    }} 
  top: 17px;
  right: 17px;
`
export const Error = styled.p`
color: #E41D1D;
font-size: 10px;
font-weight: 500;
margin: 6px 0 0 0;
`