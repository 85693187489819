import { Slider, Container, Value} from './style'
import { useState } from 'react'

interface SliderProps {
  min?: string
  max?: string
  getStep?: any
  defaultValue?: number
}
export default function ({ getStep = () => {}, min = '0', max = '100', defaultValue=50 }: SliderProps) {
  const [step, setStep] = useState(String(defaultValue))
  return (
    <Container>
      <Value rate={step}>{`${step}%`}</Value>
      <Slider
        value={step}
        type="range"
        id="opacity"
        name="opacity"
        min={min}
        max={max}
        onChange={(e) => {
          getStep(e.target.value)
          setStep(e.target.value)
        }}
        rate={step}
      />
    </Container>
  )
}
