import * as i from 'assets/Icons'
import { images } from '.'

export interface IMenuIcons {
  [key: string]: string
}

const boxIcons: IMenuIcons = {
  boxchatOutlined: i.Outlined('boxChat'),
  boxchatSolid: i.Solid('boxChatSolid'),
  boxclientOutlined: i.Outlined('boxClient'),
  boxclientSolid: i.Solid('boxClientSolid'),
  boxdashboardOutlined: i.Outlined('boxDashboard'),
  boxdashboardSolid: i.Solid('boxDashboardSolid'),
  boxlogoutOutlined: i.Outlined('boxLogout'),
  boxlogoutSolid: i.Solid('boxLogoutSolid'),
  boxproductOutlined: i.Outlined('boxProduct'),
  boxproductSolid: i.Solid('boxProductSolid'),
  dashboardOutlined: i.Outlined('dashboard'),
  chatOutlined: i.Outlined('chat'),
  clientOutlined: i.Outlined('client'),
  leafOutlined: i.Outlined('leaf'),
  leafSolid: i.Solid('leaf'),
  logo: images.logoMenu,
  logoutOutlined: i.Outlined('logout'),
  houseGreenSolid: i.Solid('houseGreen'),
  houseGreenOutlined: i.Outlined('houseGreen'),
  contractOutlined: i.Outlined('contract'),
  clientsOutlined: i.Outlined('clients'),
  lockOutlined: i.Outlined('lock'),
  newHouseGreenSolid: i.Solid('newHouseGreenSolid'),
  refreshOutlined: i.Outlined('refresh'),
  ticketBlack: i.Outlined('ticketBlack')
}

export default boxIcons
