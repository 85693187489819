import { Container, ColorTab, ColorInput, Picker } from './style'
import { useEffect, useState, useRef } from 'react'


interface IProps {
  placeholder?: string
  getColor?: any
  disabled?: boolean
  initColor?: string
}

export default function ({
  placeholder = 'Selecione a Cor Principal',
  getColor = () => {},
  disabled = false,
  initColor = ''
}: IProps) {
  const [color, setColor] = useState<any>(initColor)
  const [showPicker, setShowPicker] = useState<boolean>(false)
  const divRef = useRef<any>(null)

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShowPicker(false)
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleColor = (color: string) => {
    setColor(color)
    getColor(color)
  }

  useEffect(()  => {
    setColor(initColor)
  }, [initColor])

  return (
    <Container
      
      onClick={() => {
        if (disabled) return
        setShowPicker(true)
      }}
      ref={divRef}
    >
      <ColorTab color={color} disabled={disabled}/>
      <ColorInput disabled={disabled}>{color || placeholder}</ColorInput>
      {showPicker && (
        <Picker color={color} onChange={(color) => handleColor(color.hex)} />
      )}
    </Container>
  )
}
