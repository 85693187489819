import React from 'react'
import { Checkbox, CheckboxContainer } from './style'

const Input = React.forwardRef<
  HTMLInputElement,
  { value: string; label?: string }
>(({ label, id, ...rest }: any, ref: any) => {
  return (
    <CheckboxContainer htmlFor={id}>
      <Checkbox ref={ref} {...rest} id={id} />
      {label}
    </CheckboxContainer>
  )
})

export default Input
