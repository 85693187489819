import styled from 'styled-components'

interface IStyleProps {
  width?: number
  marginBottom?: number
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 95%;
  width: 94%;
  padding: 50px 0 0 50px;
  background-color: #f9f9f9;

  p {
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 0;
  }

  .bold-text {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    margin: 0 0 0 0;
  }

  .add {
    border: none;
    background: #f9f9f9;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #0bf569;
  }

  .ball-icon {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    color: red;
    margin-right: 10px;
  }
`

export const DivRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: ${(props: IStyleProps) => {
    return `${props.width}px`
  }};
  margin-bottom: ${(props: IStyleProps) => {
    return `${props.marginBottom}px`
  }};
`
