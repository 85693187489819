import styled from 'styled-components'

interface IContent {
  focus: boolean | null
}

export const Input = styled.textarea`
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  resize: none;
  border: none;
  width: 100%;

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: #F5F4F3;
    border-radius: 5px;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    &::placeholder {
      color: #fff;
    }
    background: lightGray;
    border: 0;
  }
`

export const InputContainer = styled.div`
  padding: 0 16px 16px 16px;
`;

export const InputError = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: start;
  padding: 5px 20px;
  width: 215px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #f60665;
`

export const Container = styled.div`
  height: 138px;
  width: 315px;
  border-radius: 5px;
  border: ${(props: IContent) => (
    props.focus ? '3px solid #3E788A1A' : '1px solid #3E788A1A'
  )}
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 48px;
  color: var(--blue-021, #3E788A);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  box-sizing: border-box;

  section, label {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  label {
    border-radius: 5px;
    border: 1px solid var(--blue-021, #3E788A);
    padding: 2px 6px;
  }
`;
