import styled from 'styled-components'

interface IStyleProps {
  width?: string
}
export const CardsWrap = styled.div`
  display: flex;
  position: absolute;
  flex-direction: row;
  max-height: 60%;
  height: auto;
  flex-wrap: wrap;
  /* min-width: 70%; */
  max-width: ${(props: IStyleProps) => {
    if (props.width) return `${props.width}`
    return '70%'
  }};
  overflow: scroll;
  padding: 0 50px 10px 10px;
  margin: 20px 0 20px 0;
  gap: 20px;

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: white;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }
  .row {
    display: flex;
    gap: 20px;
    width: 100%;
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-height: 90%;
  min-height: 90%;
  overflow: hidden;

  .content-wrapper {
    max-height: 90%;
    overflow: hidden;
    padding-left: 20px;
  }

  .align-self {
    width: 100%;
    z-index: 3;
  }

  .margem {
    margin-top: 10px;
  }
`
