import { icons } from 'helper'
import { useEffect, useState } from 'react'
import { Card } from './style'
import { numberToMonth, numberToMoney } from '../../../helper/utils'

interface IFiscalProps {
  total: string | number
  parcel: string | number
  totalParcel: string | number
  date: Date
  nfse?: string | number
  height?: string
  width?: string
  isPayment?: string
  color?: string
  opacity?: string
}

export default (props: IFiscalProps) => {
  const { total, parcel, totalParcel, date, nfse, isPayment } = props
  const [displaySend, setDisplaySend] = useState(false)

  const newDate = (data: Date) => {
    const [_, month] = numberToMonth(data.getMonth())
    const day = data.getDate()
    return `${day} ${month}`
  }

  const newHour = (data: Date) => {
    const hour = data.getHours() < 10 ? `0${data.getHours()}` : data.getHours()
    const min =
      data.getMinutes() < 10 ? `0${data.getMinutes()}` : data.getMinutes()
    return `${hour}:${min}`
  }

  return (
    <Card {...props}>
      <p>
        <span className="money">{`R$ ${numberToMoney(String(total))} `}</span>
        {` parcela ${parcel} de ${totalParcel}`}
      </p>
      <p>{`${newDate(date)} às ${newHour(date)}`}</p>
      {isPayment ? (
        <p className="bold">{isPayment}</p>
      ) : (
        <div className="bottom-row">
          <p>
            N°FSe: <span className="bold">{nfse}</span>
          </p>
          <div className="icons-container">
            <div className="tooltip">
              <img src={icons.pdfOutlined} alt="card-icon" />
              <span className="tooltiptext">Vizualizar como PDF</span>
            </div>
            <div
              className="send-hover"
              onMouseOver={() => setDisplaySend(true)}
              onFocus={() => setDisplaySend(true)}
            >
              <img src={icons.paperPlainOutlined} alt="card-icon" />
            </div>
            {displaySend ? (
              <div
                className="hide-options"
                onMouseLeave={() => setDisplaySend(false)}
                onBlur={() => setDisplaySend(false)}
                onMouseOver={() => setDisplaySend(true)}
                onFocus={() => setDisplaySend(true)}
              >
                <button
                  type="button"
                  className="send-option"
                  onMouseOver={() => setDisplaySend(true)}
                  onFocus={() => setDisplaySend(true)}
                  onClick={() => console.log('trigger de email aqui !')}
                >
                  <img
                    src={icons.emailSolid}
                    alt="hover-icon"
                    className="hover-icon"
                  />
                  Enviar por e-mail
                </button>
                <button
                  type="button"
                  className="send-option"
                  onMouseOver={() => setDisplaySend(true)}
                  onFocus={() => setDisplaySend(true)}
                  onClick={() => console.log('trigger de zap aqui !')}
                >
                  <img
                    src={icons.whatsSolid}
                    alt="hover-icon"
                    className="hover-icon"
                  />
                  Enviar por whatsapp
                </button>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      )}
    </Card>
  )
}
