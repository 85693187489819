import { Kanban, Modal, Carousel } from 'components'
import { SapiensContext } from 'context'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect, useState } from 'react'
import DetalhesModal from 'pages/DetalhesModal'
import useSocket from 'hooks/useSocket'
import { clearString } from 'helper/utils'

import { IKanbanColumns, IKanbanData, IObject } from 'interfaces'

export default function KanbanMovimentacoes() {
  const { searchParam, setBuffer } = useContext(SapiensContext)
  const [kanbanData, setKanbanData] = useState<IKanbanData>({})
  const [filteredData, setFilteredData] = useState({})
  const [socket, setSocket] = useState<IObject>({})
  // MODAL
  const [userId, setUserId] = useState()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [extendedMenu, setExtendedMenu] = useState<any>(false)

  const withdrawApi = useFetch('transactions/withdraw')
  const setWithdraw = useFetch('transactions')

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await withdrawApi.fetch('get')
        if (response.fail) throw new Error()
        setKanbanData(response.data.response)
      } catch (e) {
        console.error(e)
      }
    }
    fetchUsers()
  }, [])

  useEffect(() => {
    setFilteredData(kanbanData)
  }, [kanbanData])

  useEffect(() => {
    const shownData = Object.entries(kanbanData).reduce(
      (obj: { [key: string]: IKanbanColumns }, [columnId, { items }]) => {
        obj[columnId] = {
          ...kanbanData[columnId],
          items: items.filter(
            (item) =>
              item.name.includes(searchParam) ||
              item.cpf.includes(searchParam) ||
              item.email.includes(searchParam)
          ),
        }
        return obj
      },
      {}
    )
    setFilteredData(shownData)
  }, [searchParam])

  useSocket((io: IObject) => {
    setSocket(io)
    io.on('adm_refresh_withdraw', async () => {
      try {
        const response = await withdrawApi.fetch('get')
        if (response.fail) throw new Error()
        setKanbanData(response.data.response)
      } catch (e) {
        console.error(e)
      }
    })
  }, socket)

  const onDropCard = async (itemId: number, columnId: number) => {
    try {
      const r = await setWithdraw.fetch('put', {
        id: itemId,
        status: kanbanData[columnId].id,
      })
      if (r.fail) throw new Error()
      setKanbanData(r.data.response)
    } catch (err: any) {
      console.error(err.message)
    }
  }

  const onCardClick = async (item: { [key: string]: any }) => {
    try {
      setBuffer({
        chatStatus: item.status,
        name: item.name,
        document: clearString(item.cpf),
        chatRoom: 'cg',
      })
      setUserId(item.userId)
      setShowModal(true)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Kanban
        data={filteredData}
        setData={setKanbanData}
        onDropCard={onDropCard}
        onCardClick={onCardClick}
        withoutIcon
      />
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        FS
        extendedMenuData={extendedMenu}
      >
        <>
          <Carousel />
          <DetalhesModal userId={userId} setExtendedMenu={setExtendedMenu} />
        </>
      </Modal>
    </>
  )
}
