import styled from 'styled-components'

interface IStyleProps {
  green?: boolean
  wide?: boolean
  extraWide?: boolean
  medium?: boolean
  large?: boolean
  disabled?: boolean
}

export default styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 164px;
  width: ${(props: IStyleProps) => {
    if (props.extraWide) return '413px'
    if (props.wide) return '315px'
    if (props.large) return '305px'
    if (props.medium) return '206px'
    return '196px'
  }};

  border-radius: 5px;
  box-shadow: 0px 3px 4px 0px #00000040;

  background: #ffffff;

  cursor: ${(props: IStyleProps) => {
    if (props.disabled) return ''
    return 'pointer'
  }};

  user-select: none;
`
