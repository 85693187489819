
function fix(value: number, idx: number) {
  let max = 100
  if (idx === 2 /* saturate */) {
    max = 7500
  } else if (idx === 4 /* brightness */ || idx === 5 /* contrast */) {
    max = 200
  }

  if (idx === 3 /* hue-rotate */) {
    if (value > max) {
      value %= max
    } else if (value < 0) {
      value = max + (value % max)
    }
  } else if (value < 0) {
    value = 0
  } else if (value > max) {
    value = max
  }
  return value
}

function clamp(value: number) {
  if (value > 255) {
    value = 255
  } else if (value < 0) {
    value = 0
  }
  return value
}

function css(filters: number[]) {
  function fmt(idx: number, multiplier = 1) {
    return Math.round(filters[idx] * multiplier)
  }
  return `invert(${fmt(0)}%) sepia(${fmt(1)}%) saturate(${fmt(
    2
  )}%) hue-rotate(${fmt(3, 3.6)}deg) brightness(${fmt(4)}%) contrast(${fmt(
    5
  )}%)`
}

class Color {
  r: number

  g: number

  b: number

  constructor(r: number, g: number, b: number) {
    this.r = 0
    this.g = 0
    this.b = 0
    this.set(r, g, b)
  }

  toString() {
    return `rgb(${Math.round(this.r)}, ${Math.round(this.g)}, ${Math.round(
      this.b
    )})`
  }

  set(r: number, g: number, b: number) {
    this.r = clamp(r)
    this.g = clamp(g)
    this.b = clamp(b)
  }

  hueRotate(angle = 0) {
    angle = (angle / 180) * Math.PI
    const sin = Math.sin(angle)
    const cos = Math.cos(angle)

    this.multiply([
      0.213 + cos * 0.787 - sin * 0.213,
      0.715 - cos * 0.715 - sin * 0.715,
      0.072 - cos * 0.072 + sin * 0.928,
      0.213 - cos * 0.213 + sin * 0.143,
      0.715 + cos * 0.285 + sin * 0.14,
      0.072 - cos * 0.072 - sin * 0.283,
      0.213 - cos * 0.213 - sin * 0.787,
      0.715 - cos * 0.715 + sin * 0.715,
      0.072 + cos * 0.928 + sin * 0.072,
    ])
  }

  grayscale(value = 1) {
    this.multiply([
      0.2126 + 0.7874 * (1 - value),
      0.7152 - 0.7152 * (1 - value),
      0.0722 - 0.0722 * (1 - value),
      0.2126 - 0.2126 * (1 - value),
      0.7152 + 0.2848 * (1 - value),
      0.0722 - 0.0722 * (1 - value),
      0.2126 - 0.2126 * (1 - value),
      0.7152 - 0.7152 * (1 - value),
      0.0722 + 0.9278 * (1 - value),
    ])
  }

  sepia(value = 1) {
    this.multiply([
      0.393 + 0.607 * (1 - value),
      0.769 - 0.769 * (1 - value),
      0.189 - 0.189 * (1 - value),
      0.349 - 0.349 * (1 - value),
      0.686 + 0.314 * (1 - value),
      0.168 - 0.168 * (1 - value),
      0.272 - 0.272 * (1 - value),
      0.534 - 0.534 * (1 - value),
      0.131 + 0.869 * (1 - value),
    ])
  }

  saturate(value = 1) {
    this.multiply([
      0.213 + 0.787 * value,
      0.715 - 0.715 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 + 0.285 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 - 0.715 * value,
      0.072 + 0.928 * value,
    ])
  }

  multiply(matrix: any) {
    const newR = clamp(
      this.r * matrix[0] + this.g * matrix[1] + this.b * matrix[2]
    )
    const newG = clamp(
      this.r * matrix[3] + this.g * matrix[4] + this.b * matrix[5]
    )
    const newB = clamp(
      this.r * matrix[6] + this.g * matrix[7] + this.b * matrix[8]
    )
    this.r = newR
    this.g = newG
    this.b = newB
  }

  brightness(value = 1) {
    this.linear(value)
  }

  contrast(value = 1) {
    this.linear(value, -(0.5 * value) + 0.5)
  }

  linear(slope = 1, intercept = 0) {
    this.r = clamp(this.r * slope + intercept * 255)
    this.g = clamp(this.g * slope + intercept * 255)
    this.b = clamp(this.b * slope + intercept * 255)
  }

  invert(value = 1) {
    this.r = clamp((value + (this.r / 255) * (1 - 2 * value)) * 255)
    this.g = clamp((value + (this.g / 255) * (1 - 2 * value)) * 255)
    this.b = clamp((value + (this.b / 255) * (1 - 2 * value)) * 255)
  }

  hsl() {
    // Code taken from https://stackoverflow.com/a/9493060/2688027, licensed under CC BY-SA.
    const r = this.r / 255
    const g = this.g / 255
    const b = this.b / 255
    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    let h
    let s
    const l = (max + min) / 2

    if (max === min) {
      h = 0
      s = 0
    } else {
      const d = max - min
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
      h =
        {
          r: (g - b) / d + (g < b ? 6 : 0),
          g: (b - r) / d + 2,
          b: (r - g) / d + 4,
        }[max] || 0
      h /= 6
    }

    return {
      h: h * 100,
      s: s * 100,
      l: l * 100,
    }
  }
}

class Solver {
  target: Color

  targetHSL: { h: number; s: number; l: number }

  reusedColor: Color

  hsl: any

  constructor(target: Color) {
    this.target = target
    this.targetHSL = target.hsl()
    this.reusedColor = new Color(0, 0, 0)
  }

  solve() {
    const result = this.solveNarrow(this.solveWide())
    return {
      values: result.values,
      loss: result.loss,
      filter: Array.isArray(result.values) ? css(result.values) : null,
    }
  }

  solveWide() {
    const A = 5
    const c = 15
    const a = [60, 180, 18000, 600, 1.2, 1.2]

    let best = { loss: Infinity }
    for (let i = 0; best.loss > 25 && i < 3; i += 1) {
      const initial = [50, 20, 3750, 50, 100, 100]
      const result = this.spsa(A, a, c, initial, 1000)
      if (result.loss < best.loss) {
        best = result
      }
    }
    return best
  }

  solveNarrow(wide: { loss: number; values?: [] }) {
    const A = wide.loss
    const c = 2
    const A1 = A + 1
    const a = [0.25 * A1, 0.25 * A1, A1, 0.25 * A1, 0.2 * A1, 0.2 * A1]
    return this.spsa(A, a, c, wide.values, 500)
  }

  spsa(A: number, a: number[], c: number, values?: number[], iters?: number) {
    const alpha = 1
    const gamma = 0.16666666666666666

    let best = null
    let bestLoss = Infinity
    const deltas = new Array(6)
    const highArgs = new Array(6)
    const lowArgs = new Array(6)
    iters = iters || 0

    for (let k = 0; k < iters; k += 1) {
      const ck = c / (k + 1) ** gamma
      for (let i = 0; i < 6; i += 1) {
        deltas[i] = Math.random() > 0.5 ? 1 : -1
        highArgs[i] = values ? values[i] + ck * deltas[i] : 0
        lowArgs[i] = values ? values[i] - ck * deltas[i] : 0
      }

      const lossDiff = this.loss(highArgs) - this.loss(lowArgs)
      for (let i = 0; i < 6; i += 1) {
        const g = (lossDiff / (2 * ck)) * deltas[i]
        const ak = a[i] / (A + k + 1) ** alpha
        if (values) {
          values[i] = fix(values[i] - ak * g, i)
        }
      }

      const loss = Array.isArray(values) ? this.loss(values) : 0
      if (loss < bestLoss) {
        best = values ? values.slice(0) : 0
        bestLoss = loss
      }
    }
    return { values: best, loss: bestLoss }
  }

  loss(filters: number[]) {
    // Argument is array of percentages.
    const color = this.reusedColor
    color.set(0, 0, 0)

    color.invert(filters[0] / 100)
    color.sepia(filters[1] / 100)
    color.saturate(filters[2] / 100)
    color.hueRotate(filters[3] * 3.6)
    color.brightness(filters[4] / 100)
    color.contrast(filters[5] / 100)

    const colorHSL = color.hsl()
    return (
      Math.abs(color.r - this.target.r) +
      Math.abs(color.g - this.target.g) +
      Math.abs(color.b - this.target.b) +
      Math.abs(colorHSL.h - this.targetHSL.h) +
      Math.abs(colorHSL.s - this.targetHSL.s) +
      Math.abs(colorHSL.l - this.targetHSL.l)
    )
  }
}

export const maskCPForCNPJ = (doc: string) => {
  if (String(doc).length <= 11) {
    return String(doc)
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
  }
  return String(doc).replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '$1 $2 $3/$4-$5'
  )
}

export const clearString = (str: string) =>
  String(str).replace(/[^a-zA-Z0-9 -]/g, '')

export const onlyNumeric = (str: string) => String(str).replace(/[^\d]+/g, '')

export const numberToMoney = (number: number | string) =>
  [...String(Number(number).toFixed(2))]
    .reverse()
    .map((digit, index) => (index > 0 && index % 3 === 0 ? `${digit}.` : digit))
    .reverse()
    .join('')
    .replace('..', ',')

export const moneyToNumber = (number: string) => {
  return number
    .replace('R$ ', '')
    .replaceAll('.', '')
    .replace(',', '.')
}

export const dataToMoney = (data: any) => {
  if (!data) return 'R$ 0,00'
  return `R$ ${numberToMoney(Number(data) / 100)}`
}

export function hexToRgb(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, (_, r, g, b) => {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null
}

export const colorConverter = (color: string) => {
  const rgb: number[] = hexToRgb(color) || []
  const c = new Color(rgb[0] || 0, rgb[1] || 0, rgb[2] || 0)
  const solver = new Solver(c)

  return solver.solve()
}

export const normalDateToNewDate = (date: string) => {
  const [dd, mm, aa] = date.split('/');
  return new Date(`${aa}-${mm}-${dd}`);
}

export const numberToMonth = (number: number) =>
  [
    ['Janeiro', 'JAN'],
    ['Fevereiro', 'FEV'],
    ['Março', 'MAR'],
    ['Abril', 'ABR'],
    ['Maio', 'MAI'],
    ['Junho', 'JUN'],
    ['Julho', 'JUL'],
    ['Agosto', 'AGO'],
    ['Setembro', 'SET'],
    ['Outubro', 'OUT'],
    ['Novembro', 'NOV'],
    ['Dezembro', 'DEZ'],
  ][number - 1]

export const dateToHour = (date: Date) => {
  const [hour, min] = date.toISOString().split('T')[1].split(':')
  return `${hour}:${min}`
}

export const dateToExtend = (date: Date) => {
  const days = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ]
  const months = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ]
  const weekday = days[new Date(date).getDay()]
  const day = new Date(date).getDate()
  const d = day > 9 ? day : `0${day}`
  const month = months[new Date(date).getMonth()]
  const year = new Date(date).getFullYear()
  return `${weekday}, ${d}/${month}/${year}`
}

export const timestampToDate = (date: any) => {
  const [year, month, day] = date.toISOString().split('T')[0].split('-')
  return `${day}/${month}/${year}`
}

export const toCapitalizeCase = (string: string) => {
  return String(string)
    .split(' ')
    .map((word) =>
      word.toLocaleLowerCase().replace(/^\w/, (c) => c.toUpperCase())
    )
    .join(' ')
}

export const numberToCellphone = (number: string) => {
  if (number === null) return null
  const mask = '+55 ($1) $2-$3'
  const pattern = /^(\d{2})(\d{5})(\d{4})$/
  return String(number).replace(pattern, mask)
}

export const handleMoney = (value: number) => {
  return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

export const UUID = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (dt + Math.random()*16)%16 | 0;
    dt = Math.floor(dt/16);
    return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });

  return uuid;
}

export const addDaysToDate = (date: Date, days: number) => {
  const d = new Date(date.getTime());
  d.setDate(d.getDate() + days);
  return d;
};

export const addMonthsToDate = (date: Date, n: number) => {
  if (n === 0) return date;
  const prevDate = new Date(date);
  const actualDate = new Date(date);
  actualDate.setMonth(actualDate.getMonth() + n);

  while (actualDate <= prevDate) actualDate.setFullYear(actualDate.getFullYear() + 1);
  return actualDate;
};

export const datetimeToDate = (datetime: Date) => {
  const [year, month, day] = formatDate(datetime).split('-');
  return `${day}/${month}/${year}`;
};

export const formatDate = (date: Date) => {
  return date.toISOString().split('T')[0];
};

