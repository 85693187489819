import { useContext, useEffect, useState, useCallback, useRef } from 'react'
import useSocket from 'hooks/useSocket'
import { Kanban, Modal } from 'components'
import { SapiensContext } from 'context'
import useFetch from 'hooks/useFetch'
import { useLocation } from 'react-router-dom'
import DetalhesModal from 'pages/DetalhesModal'
import { clearString } from 'helper/utils'

import { IKanbanColumns, IKanbanData, IObject } from 'interfaces'

export default function KanbanSuport() {
  const [kanbanData, setKanbanData] = useState<IKanbanData>({})
  const [filteredData, setFilteredData] = useState({})
  const { searchParam, setBuffer, socket, setSocket } = useContext(SapiensContext)
  const location = useLocation()
  const [UserId, setUserId] = useState()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [extendedMenu, setExtendedMenu] = useState<any>(false)

  const api = useFetch()

  const timer: IObject = useRef();

  const fetchUsers = useCallback(async (page: String) => {
    try {
      const response = await api.fetch('get', {}, `suport?roomId=${page}`)
      if (response.fail) throw new Error()
      setKanbanData(response.data.response)
    } catch (e) {
      console.error(e)
    }
  }, []);

  useEffect(() => {
    setKanbanData({})
    const [page] = location.pathname.split('/').reverse()
    fetchUsers(page)
  }, [location.pathname])

  useEffect(() => {
    setFilteredData(kanbanData)
  }, [kanbanData])

  useEffect(() => {
    const shownData = Object.entries(kanbanData).reduce(
      (obj: { [key: string]: IKanbanColumns }, [columnId, { items }]) => {
        obj[columnId] = {
          ...kanbanData[columnId],
          items: items.filter(
            (item) =>
              item.name.includes(searchParam) ||
              item?.lastMessage?.includes(searchParam)
          ),
        }
        return obj
      },
      {}
    )
    setFilteredData(shownData)
  }, [searchParam])

  useSocket((io: IObject) => {
    if (setSocket) setSocket(io);
    const [page] = location.pathname.split('/').reverse()
    io.on('adm_refresh_support', () => {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        fetchUsers(page)
      }, 1000)
    });
  }, socket)

  const onCardMove = async (item: { [key: string]: any }, status: number) => {
    try {
      const [page] = location.pathname.split('/').reverse()
      const { id } = item
      const response = await api.fetch(
        'put',
        { status, id },
        `suport?roomId=${page}`
      )
      if (response.fail) throw new Error()
      setKanbanData(response.data.response)
    } catch (e) {
      console.error(e)
    }
  }

  const onCardClick = async (item: { [key: string]: any }) => {
    try {
      const [page] = location.pathname.split('/').reverse()
      setBuffer({
        chatUser: {
          name: item.name,
          document: clearString(item.document),
          chatRoom: page,
          subjectId: String(item.id),
        },
      })
      setUserId(item.userId)
      setShowModal(true)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Kanban
        data={filteredData}
        setData={setKanbanData}
        onCardMove={onCardMove}
        onCardClick={onCardClick}
      />
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        FS
        extendedMenuData={extendedMenu}
      >
        <DetalhesModal userId={UserId} setExtendedMenu={setExtendedMenu} />
      </Modal>
    </>
  )
}
