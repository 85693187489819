import { useContext, useEffect, useState } from 'react'
import { Kanban, Modal } from 'components'
import { SapiensContext } from 'context'
import useFetch from 'hooks/useFetch'
import useSocket from 'hooks/useSocket'
import DetalhesModal from 'pages/DetalhesModal'
import { clearString } from 'helper/utils'

import { IKanbanColumns, IKanbanData, IObject } from 'interfaces'

export default function KanbanMovimentacoes() {
  const { searchParam, setBuffer, socket, setSocket } =
    useContext(SapiensContext)

  const [kanbanData, setKanbanData] = useState<IKanbanData>({})
  const [filteredData, setFilteredData] = useState({})
  const [userId, setUserId] = useState()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [extendedMenu, setExtendedMenu] = useState<any>(false)

  const purchasesApi = useFetch('product/aluguel-verde/fee')
  const payPurchaseApi = useFetch('purchases/pay')
  const changePurchaseStatus = useFetch('/purchase/status')

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await purchasesApi.fetch('get')
        if (response.fail) throw new Error()
        setKanbanData(response.data.response)
      } catch (e) {
        console.error(e)
      }
    }
    fetchUsers()
  }, [])

  useEffect(() => {
    setFilteredData(kanbanData)
  }, [kanbanData])

  useEffect(() => {
    const shownData = Object.entries(kanbanData).reduce(
      (obj: { [key: string]: IKanbanColumns }, [columnId, { items }]) => {
        obj[columnId] = {
          ...kanbanData[columnId],
          items: items.filter(
            (item) =>
              item.name.includes(searchParam) ||
              item.cpf.includes(searchParam) ||
              item.email.includes(searchParam)
          ),
        }
        return obj
      },
      {}
    )
    setFilteredData(shownData)
  }, [searchParam])

  useSocket((io: IObject) => {
    if (!setSocket) return
    setSocket(io)
    io.on('adm_refresh_rentability', () => {
      purchasesApi.fetch('get').then((res) => {
        setKanbanData(res.data)
      })
    })
  }, socket)

  const onDropCard = async (itemId: string, columnId: number) => {
    try {
      let r
      if (kanbanData[columnId].id === '3') {
        r = await payPurchaseApi.fetch('put', { purchaseId: itemId })
        if (r.fail) throw new Error()
      } else {
        r = await changePurchaseStatus.fetch('put', {
          purchaseId: itemId,
          status: kanbanData[columnId].id,
        })
        if (r.fail) throw new Error()
      }
      setKanbanData(r.data.response)
    } catch (err: any) {
      console.error(err.message)
    }
  }

  const onCardClick = async (item: { [key: string]: any }) => {
    try {
      setBuffer({
        chatStatus: item.status,
        name: item.name,
        document: clearString(item.cpf),
        chatRoom: 'cg',
      })
      setUserId(item.userId)
      setShowModal(true)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Kanban
        data={filteredData}
        setData={setKanbanData}
        onDropCard={onDropCard}
        onCardClick={onCardClick}
        withoutIcon
      />
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        FS
        extendedMenuData={extendedMenu}
      >
        <DetalhesModal userId={userId} setExtendedMenu={setExtendedMenu} />
      </Modal>
    </>
  )
}
