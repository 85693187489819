import { KanbanSubMenu, Modal, PagePathing, PageTitle } from 'components'
import SecurityConfirm from 'components/SecurityConfirm'
import { SapiensContext } from 'context/sapiens'
import { ReactElement, useContext, useState } from 'react'

import { Outlet, useLocation } from 'react-router-dom'
import { Row } from 'styles'
import icons from 'helper/icons'
import NovoCliente from './NovoCliente'
import ResumoNovoCliente from './ResumoNovoCliente'

export default function ContaDigital() {
  const location = useLocation()
  const { setShowModalNewClient, showModalNewClient } =
    useContext(SapiensContext)
  const [pageNumber, setPageNumber] = useState<number>(0)

  const newClientPath = (() => {
    const loc = location.pathname.split('/')
    const ind = loc.indexOf('contaDigital')
    return loc[ind + 1]
  })()

  const pages: { [key: number]: ReactElement } = {
    0: (
      <NovoCliente
        handleNext={() => setPageNumber((oldValue: any) => oldValue + 1)}
      />
    ),
    1: (
      <ResumoNovoCliente
        handleNext={() => setPageNumber((oldValue: any) => oldValue + 1)}
      />
    ),
    2: <SecurityConfirm path={'newClient'} />,
  }

  const categories = [
    ['Novo Cliente', `${newClientPath}/novoCliente`, icons.plusSolid, true],
    ['Pessoas Físicas (PF)', 'pf', icons.clientPFSolid, false],
    ['Pessoas Jurídicas (PJ)', 'pj', icons.clientPJSolid, false],
  ]

  return (
    <>
      <PagePathing>{'Gestão de Clientes > Conta Digital'}</PagePathing>
      <PageTitle>Clientes</PageTitle>
      <Row>
        <KanbanSubMenu categories={categories} />
        <Outlet />
      </Row>
      <Modal
        FS
        setShowModal={setShowModalNewClient}
        showModal={showModalNewClient}
      >
        {pages[pageNumber]}
      </Modal>
    </>
  )
}
