import { Card, ClickableCard, PagePathing, PageTitle } from 'components'
import { SapiensContext } from 'context'
import { useFetch } from 'hooks'
import { useContext, useState, useEffect } from 'react'
import { CardsContainer } from 'styles'

export default function GestaoClientesComponent() {
  const { cardsInfo } = useContext(SapiensContext)

  const [infoData, setInfoData] = useState(cardsInfo)

  const api = useFetch('dashboard/resume')

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await api.fetch('get')
        if (response.fail) throw new Error(response.errors.message)
        const infoGestaoClientes = response.data.response.cardList.reduce(
          (obj: any, item: any) => {
            if (item.label === 'Número de clientes') {
              obj.clientNumber = item
            } else if (item.label === 'Capital sob gestão') {
              obj.money = item
            } else if (item.label === 'Capital sob custódia') {
              obj.custody = item
            }
            return obj
          },
          {}
        )
        setInfoData(infoGestaoClientes)
      } catch (err) {
        console.log(err)
      }
    }

    fetchCards()
  }, [])

  return (
    <>
      <PagePathing>Gestão de Clientes</PagePathing>
      <PageTitle>Gestão de clientes</PageTitle>
      <CardsContainer>
        <Card
          icon={infoData.clientNumber.icon}
          value={infoData.clientNumber.value}
        >
          {infoData.clientNumber.label}
        </Card>
        <Card wide icon={infoData.money.icon} value={infoData.money.value}>
          {infoData.money.label}
        </Card>
        <Card wide icon={infoData.custody.icon} value={infoData.custody.value}>
          {infoData.custody.label}
        </Card>{' '}
      </CardsContainer>
      <CardsContainer>
        <ClickableCard icon="userOutlined" to="contaDigital/pf" green>
          Conta Digital
        </ClickableCard>
        <ClickableCard icon="houseGreenOutlined" to="aluguelVerde/inquilinos">
          Aluguel Verde
        </ClickableCard>
        <ClickableCard icon="esgOutlined" to="" disabled>
          One Earth
        </ClickableCard>
        <ClickableCard icon="agroGreenOutlined" to="" disabled>
          Agro Green
        </ClickableCard>
        <ClickableCard icon="windmillOutlined" to="" disabled>
          Clean&Green
        </ClickableCard>
      </CardsContainer>
      {/* </CardsContainer> */}
    </>
  )
}
