import { SapiensContext } from 'context'
import { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { Content } from 'styles'

export default function OutletGestaoClientes() {
  const { extended } = useContext(SapiensContext)
  return (
    <Content addPaddingTop extended={extended}>
      <Outlet />
    </Content>
  )
}
