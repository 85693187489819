import styled from 'styled-components'
import { ITheme } from 'styles/Themes'

interface IStyleProps {
  outlined?: boolean
  rounded?: boolean
  secondary?: boolean
  small?: boolean
  xSmall?: boolean
  type?: string
  color?: string
  theme: ITheme
}

export default styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0;

  font-size: ${(props: IStyleProps) => {
    if (props.xSmall) return '12px'
    return '14px'
  }};

  font-weight: 700;

  border: ${(props: IStyleProps) => {
    if (props.outlined) return `1px solid ${props.theme.button.border.outlined}`
    return 0
  }};
  border-radius: ${(props: IStyleProps) => (props.rounded ? '30px' : '5px')};

  background-color: ${(props: IStyleProps) => {
    if (props.outlined) return `${props.theme.button.backgroundColor.outlined}`
    if (props.secondary)
      return `${props.theme.button.backgroundColor.secondary}`
    return `${props.theme.button.backgroundColor.primary}`
  }};
  color: ${(props: IStyleProps) => {
    if (props.color) return props.color
    if (props.outlined) return `${props.theme.button.fontColor.outlined}`
    if (props.secondary) return `${props.theme.button.fontColor.secondary}`
    return `${props.theme.button.fontColor.primary}`
  }};

  height: ${(props: IStyleProps) => {
    if (props.xSmall) return '25px'
    return '50px'
  }};

  width: ${(props: IStyleProps) => {
    if (props.small) return '150px'
    if (props.xSmall) return '75px'
    return '315px'
  }};

  &:hover {
    background: ${(props: IStyleProps) => {
      if (props.outlined)
        return `${props.theme.button.backgroundColor.hoverOutlined}`
      if (props.secondary)
        return `${props.theme.button.backgroundColor.hoverSecondary}`
      return `${props.theme.button.backgroundColor.hoverPrimary}`
    }};
  }

  &:focus {
    border: ${(props: IStyleProps) => {
      if (props.secondary)
        return `1px solid ${props.theme.button.border.focusedSecondary}`
      return `1px solid ${props.theme.button.border.focusedPrimary}`
    }};
  }

  &:active {
    box-shadow: 0px 4px 4px 0px ${(props: IStyleProps) => props.theme.boxShadow};
  }

  &:disabled {
    background: ${(props: IStyleProps) => {
      if (props.outlined)
        return `${props.theme.button.backgroundColor.outlined}`
      return `${props.theme.button.backgroundColor.disabled}`
    }};
    color: ${(props: IStyleProps) => {
      if (props.outlined)
        return `${props.theme.button.fontColor.outlinedDisabled}`
      return `${props.theme.button.fontColor.disabled}`
    }};
    border: ${(props: IStyleProps) => {
      if (props.outlined)
        return `1px solid ${props.theme.button.border.outlinedDisabled}`
      return 0
    }};
  }
`
