export const adjustColor = (hexColor, brightness, saturation) => {
  // Remove o caractere "#" do valor hexadecimal
  hexColor = hexColor.replace('#', '');

  // Converte o valor hexadecimal para os componentes HSL
  var r = parseInt(hexColor.substring(0, 2), 16) / 255;
  var g = parseInt(hexColor.substring(2, 4), 16) / 255;
  var b = parseInt(hexColor.substring(4, 6), 16) / 255;

  // Converte os componentes RGB para HSL
  var max = Math.max(r, g, b);
  var min = Math.min(r, g, b);
  var h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // A escala de saturação é de 0 a 1
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6; // A escala de matiz é de 0 a 1
  }

  // Ajusta a iluminação e a saturação
  l = brightness;
  s= saturation;

  // Converte os componentes HSL de volta para RGB
  if (s === 0) {
    r = g = b = l;
  } else {
    var hue2rgb = function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

   // Converte os componentes RGB de volta para um valor hexadecimal
   r = Math.round(r * 255);
   g = Math.round(g * 255);
   b = Math.round(b * 255);
 
   var adjustedHex = '#' + ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');
 
   return adjustedHex;
}