import { EsgCard, Modal, PagePathing } from 'components'
import icons, { IIcons } from 'helper/icons'
import { useFetch } from 'hooks'
import { useEffect, useState } from 'react'
import { Row, Content } from 'styles'
import DetalhesCaptação from '../DetalhesCaptação'
import { PageTitle, KanbanContainer, Img, ColumnTitle } from './style'

const kanbanStructure = [
  {
    label: 'Abertas',
    key: 'oppened',
    icon: 'lockOpenOutlined',
    color: '#03989E',
  },
  {
    label: 'Rentabilizando',
    key: 'active',
    icon: 'stonksOutlined',
    color: '#0BF569',
  },
  {
    label: 'Finalizadas',
    key: 'closed',
    icon: 'lockClosedOutlined',
    color: '#2B2B2B',
  },
  {
    label: 'Canceladas',
    key: 'canceled',
    icon: 'canceledOutlined',
    color: '#D61212',
  },
]

interface IDetailProps {
  purchaseId: number
  productId: number
  title: string
  productIcon: string
  productAttach: string
  column: {
    name: string
    color: string
    icon: keyof IIcons
  }
  isChangable?: boolean
}

export default () => {
  const api = useFetch('purchase')
  const [data, setData] = useState<{ [key: string]: any }>({})
  const [showModal, setShowModal] = useState(false)
  const [extendedMenu, setExtendedMenu] = useState<any>(false)
  const [detaiProps, setDetailProps] = useState<IDetailProps>({
    purchaseId: 0,
    productId: 0,
    title: '',
    productIcon: '',
    productAttach: '',
    isChangable: false,
    column: {
      name: '',
      color: '',
      icon: 'agroGreenOutlined',
    },
  })

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const r = await api.fetch('get')
        if (r.fail) throw new Error()
        const { response } = r.data
        setData(response)
      } catch (e) {
        console.error(e)
      }
    }
    fetchUsers()
  }, [])

  return (
    <Content withoutMarginLeft noMarginTop>
      <div style={{ marginLeft: '30px' }}>
        <PagePathing withoutMarginLeft>
          {'Gestão de produtos > Investimentos ESG > Captações'}
        </PagePathing>
        <Row gap={14} margin="21px 0 29px 0">
          <img src={icons.lockOpenOutlined} alt="lock-icon" />
          <PageTitle>Captações</PageTitle>
        </Row>
      </div>

      <KanbanContainer className="kanbancontainer">
        {kanbanStructure.map((pipeline) => (
          <div className="column" key={pipeline.key}>
            <Row gap={14}>
              <Img
                src={icons[pipeline.icon]}
                alt="column-icon"
                color={pipeline.color}
              />
              <ColumnTitle color={pipeline.color}>{pipeline.label}</ColumnTitle>
            </Row>
            <div className="cards-container">
              {data[pipeline.key]?.map((item: any) => {
                return (
                  <EsgCard
                    extraIcon={item.productIcon}
                    tip={item.tip}
                    meta={item.meta}
                    onClick={() => {
                      setDetailProps({
                        ...item,
                        isChangable:
                          pipeline.key === 'oppened' ||
                          pipeline.key === 'active',
                        title: `${item.product} ${item.type} #${
                          item.round < 10 ? `0${item.round}` : item.round
                        }`,
                        column: {
                          name: pipeline.label,
                          color: pipeline.color,
                          icon: pipeline.icon,
                        },
                      })
                      setShowModal(true)
                    }}
                    isBlocked={item.status === 1}
                    isChanged={{
                      value: 'Invest. min.: ',
                      parcel: ' % Captados',
                      paid: '',
                    }}
                    paid={item.meta}
                    parcel={100 - item.progress}
                    progress={100 - item.progress}
                    value={item.min}
                    rentability={item.profitability}
                    imageUrl={item.image}
                    months={item.deadline}
                    ribbon={item.type.toLowerCase()}
                  >
                    {`${item.product} ${item.type} #${
                      item.round < 10 ? `0${item.round}` : item.round
                    }`}
                  </EsgCard>
                )
              })}
            </div>
          </div>
        ))}
      </KanbanContainer>
      <Modal
        FS
        setShowModal={setShowModal}
        showModal={showModal}
        extendedMenuData={extendedMenu}
      >
        <DetalhesCaptação {...detaiProps} setExtendedMenu={setExtendedMenu} />
      </Modal>
    </Content>
  )
}
