import { Row } from 'styles'
import {
  Select,
  PageTitle,
  PagePathing,
  Input,
  MaskedInput,
  ButtonNext,
} from 'components'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import axios from 'axios'

import { useContext, useEffect, useRef, useState } from 'react'
import { SapiensContext } from 'context/sapiens'
import { useLocation } from 'react-router-dom'
import { states } from 'helper/consts'
import { FormDiv } from './style'

const accountType = [
  { label: 'Pessoa física', value: 0 },
  { label: 'Pessoa jurídica', value: 1 },
]

const schemaNewClient = Yup.object()
  .shape({
    firstName: Yup.string().min(3).required(),
    document: Yup.string().min(11).required(),
    secondName: Yup.string().min(3).required(),
    email: Yup.string().min(3).required(),
    cellphone: Yup.string().min(15).required(),
  })
  .typeError('Este input deve ser preenchido corretamente')

const schemaAdress = Yup.object()
  .shape({
    postalCode: Yup.string().min(9).required(),
    district: Yup.string().min(3).required(),
    street: Yup.string().min(3).required(),
    number: Yup.number().min(3).required(),
    complement: Yup.string().min(3).required(),
    state: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    }),
    city: Yup.string().min(3).required(),
  })
  .typeError('Este input deve ser preenchido corretamente')

export default function NovoCliente({ handleNext }: any) {
  const location = useLocation()
  const { setSelectedClient } = useContext(SapiensContext)
  const [allowNextPage, setAllowNextPage] = useState(false)
  const [isPF, setIsPF] = useState(
    /\/gestaoClientes\/contaDigital\/pf/i.test(location.pathname)
  )
  const formRef = useRef<HTMLFormElement | any>(null)
  const [newClient, setNewClient] = useState({
    firstName: '',
    document: '',
    secondName: '',
    email: '',
    cellphone: '',
  })
  const [adress, setAdress] = useState<any>({
    postalCode: '',
    district: '',
    street: '',
    number: '',
    complement: '',
    state: '',
    city: '',
  })

  useEffect(() => {
    setIsPF(/\/gestaoClientes\/contaDigital\/pf/i.test(location.pathname))
  }, [])

  useEffect(() => {
    const validateInputs = async () => {
      try {
        await schemaNewClient.validate(newClient, { abortEarly: false })
        await schemaAdress.validate(adress, { abortEarly: false })
        setAllowNextPage(true)
      } catch (err) {
        console.log(err)
        setAllowNextPage(false)
      }
    }
    validateInputs()
  }, [newClient, adress])

  const handleChangePage = async () => {
    formRef.current.setErrors({})
    try {
      await schemaAdress.validate(adress, { abortEarly: false })
      await schemaNewClient.validate(newClient, { abortEarly: false })
      const client = {
        accountType: isPF
          ? { label: 'Pessoa física', value: 0 }
          : { label: 'Pessoa jurídica', value: 1 },
        ...newClient,
      }
      setSelectedClient({ ...client, address: { ...adress } })
      handleNext()
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: {
          [key: string]: { path: string; message: string }
        } = {}
        err.inner.forEach((error: any) => {
          validationErrors[error.path] = error.message
        })
        formRef.current.setErrors(validationErrors)
      } else {
        console.log(err)
      }
    }
  }

  const resetInputs = () => {
    const arr = document.getElementsByClassName('new-client-input')
    const arr2 = document.getElementsByClassName('masked-input')
    Array.from(arr).forEach((i: any) => (i.value = ''))
    Array.from(arr2).forEach((i: any) => (i.value = ''))
    setNewClient({
      firstName: '',
      document: '',
      secondName: '',
      email: '',
      cellphone: '',
    })
    setAdress({
      postalCode: '',
      district: '',
      street: '',
      number: '',
      complement: '',
      state: '',
      city: '',
    })
  }

  const handlePostalCode = async (e: string) => {
    const r = await axios.get(`https://ws.apicep.com/cep/${e}.json`)
    return r
  }
  useEffect(() => {
    const { postalCode = '' } = adress
    if (postalCode.length < 9) return
    handlePostalCode(postalCode).then((res: any) => {
      const { city, state, address } = res.data
      setAdress({
        ...adress,
        city,
        state: { label: state, value: state },
        street: address,
      })
    })
  }, [adress.postalCode])

  return (
    <>
      <PagePathing
        withoutMarginLeft
      >{`Gestão do cliente > Novo cliente`}</PagePathing>
      <PageTitle withoutMarginLeft>Abertura de Conta</PageTitle>
      <FormDiv>
        <Form onSubmit={handleChangePage} ref={formRef}>
          <Row gap={14}>
            <Select
              value={
                isPF
                  ? { label: 'Pessoa física', value: 0 }
                  : { label: 'Pessoa jurídica', value: 1 }
              }
              onChange={(e: any) => {
                if (e.value === 0) {
                  setIsPF(true)
                } else setIsPF(false)
                resetInputs()
              }}
              name="accountType"
              options={accountType}
              placeholder="Tipo de conta"
            />
            <Input
              className="new-client-input"
              onChange={(e: any) => {
                setNewClient({
                  ...newClient,
                  firstName: e.target.value,
                })
              }}
              placeholder={isPF ? 'Nome completo' : 'Razão social'}
              type="text"
              name={'firstName'}
            />
            <MaskedInput
              type={isPF ? 'cpf' : 'cnpj'}
              handleChange={(e: any) => {
                setNewClient({
                  ...newClient,
                  document: e,
                })
              }}
              placeholder={isPF ? 'CPF' : 'CNPJ'}
              name={'document'}
            />
          </Row>
          <Row gap={14}>
            <Input
              className="new-client-input"
              onChange={(e: any) => {
                setNewClient({
                  ...newClient,
                  secondName: e.target.value,
                })
              }}
              placeholder={isPF ? 'Nome da mãe' : 'Nome fantasia'}
              type="text"
              name={'secondName'}
            />
            <Input
              className="new-client-input"
              onChange={(e: any) => {
                setNewClient({
                  ...newClient,
                  email: e.target.value,
                })
              }}
              placeholder={'Email'}
              type="text"
              name={'email'}
            />
            <MaskedInput
              type={'cell'}
              handleChange={(e: any) => {
                setNewClient({
                  ...newClient,
                  cellphone: e,
                })
              }}
              placeholder={'Telefone'}
              name={'cellphone'}
            />
          </Row>
          <Row gap={14}>
            <MaskedInput
              type={'cep'}
              handleChange={(e: any) => {
                setAdress({
                  ...adress,
                  postalCode: e,
                })
              }}
              placeholder={'CEP'}
              name={'postalCode'}
            />
            <Input
              className="new-client-input"
              onChange={(e: any) => {
                setAdress({
                  ...adress,
                  district: e.target.value,
                })
              }}
              placeholder={'Bairro'}
              type="text"
              name={'district'}
            />
            <Input
              className="new-client-input"
              onChange={(e: any) => {
                setAdress({
                  ...adress,
                  street: e.target.value,
                })
              }}
              placeholder={'Endereço com rua'}
              type="text"
              name={'street'}
            />
          </Row>
          <Row gap={14}>
            <Input
              className="new-client-input"
              onChange={(e: any) => {
                setAdress({
                  ...adress,
                  number: e.target.value,
                })
              }}
              placeholder={'Número'}
              type="text"
              name={'number'}
            />
            <Input
              className="new-client-input"
              onChange={(e: any) => {
                setAdress({
                  ...adress,
                  complement: e.target.value,
                })
              }}
              placeholder={'Complemento'}
              type="text"
              name={'complement'}
            />
            <Select
              value={adress.state}
              onChange={(e: any) => {
                setAdress({
                  ...adress,
                  state: e,
                })
              }}
              disabled
              name="state"
              placeholder="UF"
              options={states}
              small
            />
            <Input
              value={adress.city}
              disabled
              className="new-client-input"
              onChange={(e: any) => {
                setAdress({
                  ...adress,
                  city: e.target.value,
                })
              }}
              placeholder={'Cidade'}
              type="text"
              name={'city'}
              small
            />
          </Row>
          <ButtonNext disabled={!allowNextPage} />
        </Form>
      </FormDiv>
    </>
  )
}
