import ProgressBar from './style'

interface IProgressBar {
  progress: number
  isReservation?: boolean
}

export default function ProgressBarComponent(props: IProgressBar) {
  return (
    <ProgressBar {...props}>
      <div className="fillbar" />
    </ProgressBar>
  )
}
