import styled from 'styled-components'

interface IStyleProps {
  progress: number
  isReservation?: boolean
}

export default styled.div`
  background: #cccccc;
  border-radius: 50px;
  height: 10px;
  width: 275px;
  margin: 0 20px 3px;

  .fillbar {
    margin: 0;
    background: ${(props: IStyleProps) => {
      if (props.isReservation) return `#FFCD03`
      return ' #0bf569;'
    }};
    box-shadow: 0 0 1px #0bf569dd;
    border-radius: 50px;
    height: 10px;
    width: ${(props: IStyleProps) => {
      return `${props.progress}%`
    }};
  }
`
