import styled from 'styled-components'

interface IStyleProps {
  error?: string
}

export const Autocomplete = styled.div`
  input {
    color: #070707;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

    height: 50px;
    width: 315px;

    padding-left: 20px;
    border-radius: 5px;
    background: ${(props: IStyleProps) =>
      props.error ? '#F606650D' : '#f9f9f9'};
    border: ${(props: IStyleProps) =>
      props.error ? '1px solid #f60665' : '1px solid #9c948c'};
    box-sizing: border-box;

    &:hover {
      background-color: #8f888133;
    }

    &:focus {
      &:hover {
        background-color: #8f888133;
      }
    }
  }

  .menu {
    z-index: 999;
    background-color: #fbfbfb;

    position: absolute;
    top: 50px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    box-sizing: border-box;
    margin-top: 0;
    border-radius: 5px;
    border: 1px solid #9c948c77;
    width: 315px;
  }

  .active {
    background-color: #cdc9c5 !important;
    color: #ffffff !important;
  }

  .options {
    border-radius: 5px;

    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    padding-left: 20px;
    overflow: hidden;
    height: 50px;
    width: 293px;
    max-width: 315px;
    background-color: #fbfbfb;
    color: #9c948c;

    &:hover {
      background-color: #f5f4f3;
    }
  }
`

export const InputError = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: start;
  padding: 5px 30px;
  width: 215px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #f60665;
`
