import styled from 'styled-components'

interface IStyleProps {
  green?: boolean
}

export const Checkbox = styled.input`
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid;
  border-color: ${(props: IStyleProps) =>
    props.green ? '#0BF569' : '#070707'};
  border-radius: 4px;
  appearance: none;
  outline: 0;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 3px;
    left: 6px;
    width: 4px;
    height: 7px;
    border-style: solid;
    border-color: white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  &:checked {
    color: white !important;
    border-color: ${(props: IStyleProps) =>
      props.green ? '#0BF569' : '#070707'};
    background: ${(props: IStyleProps) =>
      props.green ? '#0BF569' : '#070707'};
    &::before {
      opacity: 1;
    }
  }

  &:hover {
    background-color: ${(props: IStyleProps) =>
      props.green ? '#0BF56933' : '#07070733'};
    &:checked {
      background: ${(props: IStyleProps) =>
        props.green ? '#0BF569' : '#070707'};
    }
  }
`

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;

  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #9c948c;
`
