import icons from 'helper/icons'
import Chat from 'pages/DetalhesModal/Chat'
import { SapiensContext } from 'context'
import { clearString } from 'helper/utils'
import {
  Dispatch,
  SetStateAction,
  useState,
  ReactElement,
  useEffect,
  useContext,
} from 'react'
import Tooltip from 'react-tooltip'
import BugReport from '../BugReport'
import SuportReport from '../SuportReport'
import { RightMenu, Background, ExtendableMenu, Row } from './style'

interface IRightMenuProps {
  setShowModal: Dispatch<SetStateAction<boolean>>
  disable?: boolean
  extendedMenuData?: ReactElement | boolean
  setExtendedMenu?: ReactElement | boolean
}

export default function RightMenuComponent(props: IRightMenuProps) {
  const { setShowModal, disable, extendedMenuData } = props
  const { socket, buffer, setBuffer } = useContext(SapiensContext)
  const [extended, setExtended] = useState(false)
  const [pageNumber, setPageNumber] = useState(0)

  const handleSetExtend = () => {
    if (!disable || extended) {
      setExtended((oldState) => !oldState)
      setPageNumber(0)
    }
  }

  useEffect(() => {
    setExtended(!!extendedMenuData)
  }, [extendedMenuData])

  const pages: { [key: number]: any } = {
    0: extendedMenuData,
    1: (
      <BugReport
        user={buffer?.chatUser?.name || '--'}
        color="green"
        status="Conta ativa"
      />
    ),
    2: (
      <SuportReport
        user={buffer?.chatUser?.name || '--'}
        color="green"
        status="Conta ativa"
      />
    ),
    3: (
      <Chat
        title={buffer?.chatTitle || 'Chat'}
        status={buffer?.chatStatus}
        subtitle={buffer?.chatSubtitle}
        stage={buffer?.chatStage}
        name={buffer?.chatUser?.name}
        document={
          buffer?.chatUser?.documentNumber ||
          buffer?.chatUser?.document ||
          buffer?.chatUser?.cpf
        }
        room={clearString(buffer?.chatRoom) || 'cg'}
        subjectId={buffer?.chatUser?.subjectId}
        socket={socket}
      />
    ),
  }

  return (
    <Row>
      {extended && <Background />}
      <ExtendableMenu {...props} extended={extended}>
        <img
          className="extend-button"
          src={icons.backSolid}
          alt="extend button"
          onClick={handleSetExtend}
        />
        {extended && pages[pageNumber]}
      </ExtendableMenu>
      <RightMenu>
        <img
          src={icons.xSolid}
          alt="Close Menu icon"
          className="closeModal"
          onClick={() => {
            setBuffer({})
            setShowModal(false)
          }}
        />
        <img
          src={icons.bugSolid}
          alt="Bug icon"
          data-for="bug"
          data-tip="Relatório de <br /> atendimento"
          onClick={() => {
            setPageNumber(1)
            setExtended(true)
          }}
        />
        <Tooltip place="bottom" multiline id="bug" effect="solid" />
        <img
          src={icons.docSolid}
          alt="Bug icon"
          data-for="history"
          data-tip="Histórico de <br /> problemas"
          onClick={() => {
            setPageNumber(2)
            setExtended(true)
          }}
        />
        <img
          src={icons.chatSolid}
          alt="Chat icon"
          data-for="chat"
          data-tip="Chat direto"
          onClick={() => {
            setPageNumber(3)
            setExtended(true)
          }}
        />
        <Tooltip place="bottom" multiline id="chat" effect="solid" />
      </RightMenu>
    </Row>
  )
}
