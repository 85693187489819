import styled from 'styled-components'
import { ITheme } from 'styles/Themes'

interface IStyleProps {
  small?: boolean
  theme: ITheme
  green?: boolean
  secondary?: boolean
  disabled?: boolean
  medium?: boolean
  smallIcon?: boolean
}
export const ResumeInputCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 142px;
  width: ${(props: IStyleProps) => {
    if (props.small) return '206px'
    if (props.medium) return '260px'
    return '315px'
  }};
  border-radius: 5px;
  box-shadow: 0px 3px 4px 0px #00000040;
  background: ${(props: IStyleProps) => {
    if (props.green) return `${props.theme.card.backgroundColor.green}`
    if (props.secondary) return `${props.theme.card.backgroundColor.secondary}`
    return `${props.theme.card.backgroundColor.primary}`
  }};
  cursor: ${(props: IStyleProps) => {
    if (props.disabled) return ''
    return 'pointer'
  }};

  .title {
    color: ${(props: IStyleProps) => {
      // if (props.disabled) return `${props.theme.card.fontColor.disabled}`
      // if (props.secondary) return `${props.theme.card.fontColor.secondary}`
      return `${props.theme.card.fontColor.primary}`
    }};
    font-size: ${(props: IStyleProps) => {
      if (props.small) return '14px'
      return '16px'
    }};
    font-style: normal;
    font-weight: 700;
    text-align: left;
    line-height: ${(props: IStyleProps) => {
      if (props.small) return '16.41px'
      return '18.75px'
    }};
    letter-spacing: 1%;
    margin: 0 0 22px 25px;
  }

  .iconClickable {
    filter: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.icon.disabled}`
      if (props.secondary) return `${props.theme.card.icon.white}`
      if (props.green) return `${props.theme.card.icon.green}`
      return `${props.theme.card.icon.green}`
    }};
    max-width: ${(props: IStyleProps) => {
      if (props.smallIcon) return '18px'
      return '24px'
    }};
    height: ${(props: IStyleProps) => {
      if (props.smallIcon) return '18px'
      return '24px'
    }};
    margin: 0 0 12px 25px;
  }

  .editIcon {
    filter: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.icon.disabled}`
      if (props.secondary) return `${props.theme.card.icon.white}`
      if (props.green) return `${props.theme.card.icon.green}`
      return `${props.theme.card.icon.green}`
    }};
    max-width: 10px;
    height: 10px;
    margin: ${(props: IStyleProps) => {
      if (props.small) return '0 30px 52px 0'
      return '30px 0 52px 30px'
    }};
  }

  textarea {
    resize: none;
    border: none;
    max-width: ${(props: IStyleProps) => {
      if (props.small) return '138px'
      return '197px'
    }};
    background: ${(props: IStyleProps) => {
      if (props.green) return `${props.theme.card.backgroundColor.green}`
      if (props.secondary)
        return `${props.theme.card.backgroundColor.secondary}`
      return `${props.theme.card.backgroundColor.primary}`
    }};
    cursor: ${(props: IStyleProps) => {
      if (props.disabled) return ''
      return 'pointer'
    }};
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    text-align: left;
    line-height: 21.09px;
    vertical-align: text-bottom;
    margin: 0 0 12px 12px;
    ::placeholder {
      color: ${(props: IStyleProps) => {
        if (props.disabled) return `${props.theme.card.fontColor.primary}`
        return `${props.theme.card.fontColor.secondary}`
      }};
    }
  }

  input {
    border: none;
    max-width: ${(props: IStyleProps) => {
      if (props.small) return '138px'
      return '170px'
    }};
    background: ${(props: IStyleProps) => {
      if (props.green) return `${props.theme.card.backgroundColor.green}`
      if (props.secondary)
        return `${props.theme.card.backgroundColor.secondary}`
      return `${props.theme.card.backgroundColor.primary}`
    }};
    cursor: ${(props: IStyleProps) => {
      if (props.disabled) return ''
      return 'pointer'
    }};
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    text-align: left;
    line-height: 21.09px;
    vertical-align: text-bottom;
    margin: 0 0 12px 12px;
    ::placeholder {
      color: ${(props: IStyleProps) => {
        if (props.disabled) return `${props.theme.card.fontColor.primary}`
        return `${props.theme.card.fontColor.secondary}`
      }};
    }
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  p {
    width: 16px;
    max-width: 16px;
    height: 18px;
    max-height: 18px;
    color: #0bf569;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    text-align: left;
    line-height: 18px;
    margin: ${(props: IStyleProps) => {
      if (props.small) return '0 30px 52px 0'
      return '30px 0 52px 30px'
    }};
  }
`
