import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import {
  Login,
  // Demo,
  Dashboard,
  NotFound,
  GestaoProdutos,
  GestaoClientes,
  ContaDigital,
  InvestimentosEsg,
  ClientePF,
  ClientePJ,
  NovoCliente,
  // Lizz
  Suporte,
  KanbanSuport,
  Demo,
  Movimentacoes,
  AluguelVerde,
  AluguelVerdeProduto,
  ContratosAluguelVerde,
  GestaoCupomDashboard,
} from 'pages'

import { PrivateRoute, Outlet } from 'layouts'
import Captacoes from 'pages/GestaoProdutos/InvestimentosEsg/Captacoes'
import Confirm from '../components/SecurityConfirm/Confirm'

const UserRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* PUBLIC ROUTES */}
        <Route path="/login" element={<Login />} />

        {/* PRIVATE ROUTES */}
        <Route path="/" element={<PrivateRoute />}>
          <Route path="confirm" element={<Confirm />} />
          <Route path="demo" element={<Demo />} />
          <Route path="dashboard" element={<Dashboard />} />

          {/* <Route path="dashboard" element={<Dashboard />} /> */}
          <Route path="gestaoCupons" element={<GestaoCupomDashboard />}>
            {
              ['resume', 'status', 'campaign']
                .map((path) => (
                  <Route key={path} path={path} element={<GestaoCupomDashboard />} />
                ))
            }
          </Route>

          <Route path="gestaoClientes" element={<Outlet />}>
            <Route index element={<GestaoClientes />} />

            <Route path="aluguelVerde" element={<AluguelVerde />}>
              <Route path="inquilinos" element={<AluguelVerde />} />
              <Route path="proprietarios" element={<AluguelVerde />} />
              <Route path="imobiliarias" element={<AluguelVerde />} />
              <Route path="coberturas" element={<AluguelVerde />} />
            </Route>

            <Route path="contaDigital" element={<ContaDigital />}>
              <Route path="pf" element={<ClientePF />}>
                <Route path="novoCliente" element={<NovoCliente />} />
              </Route>
              <Route path="pj" element={<ClientePJ />}>
                <Route path="novoCliente" element={<NovoCliente />} />
              </Route>
            </Route>
          </Route>
          <Route path="gestaoProdutos" element={<Outlet />}>
            <Route index element={<GestaoProdutos />} />
            <Route path="aluguelVerde" element={<AluguelVerdeProduto />} />
            <Route
              path="aluguelVerde/contratos"
              element={<ContratosAluguelVerde />}
            />
            <Route path="investimentosEsg" element={<InvestimentosEsg />} />
            <Route path="investimentosEsg/captacoes" element={<Captacoes />} />
          </Route>
          <Route path="suporte" element={<Outlet />}>
            <Route element={<Suporte />}>
              <Route path="cg" element={<KanbanSuport />} />
              <Route path="av" element={<KanbanSuport />} />
            </Route>
          </Route>
          <Route path="movimentacoes" element={<Movimentacoes />}>
            <Route element={<Movimentacoes />}>
              {
                ['saques', 'rentabilidades', 'parcelamentos', 'fee', 'bonus']
                  .map((path) => (
                    <Route key={path} path={path} element={<Movimentacoes />} />
                  ))
              }
            </Route>
          </Route>
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default UserRoutes
