import styled from 'styled-components';
import { getColor } from 'helper/filterColor';

interface IStyleProps {
  small?: boolean
  color?: string
  image?: string
  opacity?: string
}
export const Poster = styled.div`
  width: 320px;
  height: ${(props: IStyleProps) => {
    if (props.small) return `231px`;
    return `404px`;
  }};
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  /* border: 1px solid red; */
  box-sizing: border-box;
`;

export const Card = styled.div`
box-sizing: border-box;
  z-index: 1;
  width: 310px;
  height: ${(props: IStyleProps) => {
    if (props.small) return `224px`;
    return '391px';
  }};
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const ChartContainer = styled.div`
  height: auto;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px 16px 32px;
  box-sizing: border-box;
`;
export const MinRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const InvestText = styled.p`
  font-size: 12px;
  font-weight: 400;
  color:#070707;
  margin: 0;
`;

export const MoneyText = styled.p`
  font-weight: 700;
  font-size: 16px;
  color: #070707;
  margin: 0 0 0 16px;
`;

export const ColoredTag = styled.div`
  background-color: ${(props: IStyleProps) => {
    if (props.color) return props.color;
    return '#9E9E9E';
  }};
  padding: 3px 8px;
  border-radius: 100px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 8px;
`;

export const TagText = styled.p`
  font-size: 11px;
  font-weight: 700;
  line-height: 12px;
  color: #F9F9F9;
  margin: 0;
`;

export const FlagTextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 44px;
  top: 15px;
  right: -3px;
  z-index: 4;
  transform: rotate(45deg);
`;

export const FlagIcon = styled.img`
  position: absolute;
  top: 10px;
  right: 6px;
  z-index: 4;
  
`;

export const FlagTextIcon = styled.img`
  position: absolute;
  top: 10px;
  right: 6px;
  z-index: 4;
`;
export const FlagText = styled.p`
  position: absolute;
  top: 16px;
  right: 9px;
  z-index: 4;
  font-weight: 700;
  transform: rotate(45deg);
  font-size: 8px;
  text-align: center;
  color: ${(props: IStyleProps) => {
    if (props.color) return props.color;
    return '#070707'
  }};
`;

export const InvestTitle = styled.p`
  color: #F9F9F9;
  font-weight: 700;
  line-height: 20px;
  font-size: 16px;
  margin: 0 0 0 8px;
`;

export const ImageContainer = styled.div`
  background-image: ${(props: IStyleProps) => (`url(${props.image})`)};
  background-repeat: no-repeat;
  background-size: cover;
  height: 132px;
  z-index: 1;
  position: relative;
  width: 310px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
`;

export const Gradient = styled.div`
  z-index: 2;
  position: absolute;
  width: 310px;
  height: 132px;
  background-color: ${(props: IStyleProps) => {
    if (props.opacity) return `#000000${props.opacity}`
    return '#00000050'
  }};
  /* background-color: #00000090; */
  top: 0;
`;

export const ImageContent = styled.div`
  display: flex;
  z-index: 3;
  position: absolute;
  width: 310px;
  height: 132px;
  top: 0;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 16px 12.5px 16px 12.5px;
`;

export const ChartWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const RateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 43px;
  top: 53px;
`;

export const RateColoured = styled.p`
  color: ${(props: IStyleProps) => {
    if (props.color) return props.color;
    return '#070707';
  }};
  font-weight: 700;
  font-size: 14px;
  margin: 0;
`;

export const RateText = styled.p`
  color: #070707;
  font-weight: 400;
  font-size: 10px;
  margin: 0;
`;

export const Anchor = styled.div`
position: relative;
width: 24px;
height: 24px;
display: flex;
align-items: center;
justify-content: center;
/* border: 1px solid red; */
`

export const Outlined = styled.img`
position: absolute;
width: 12px;
height: 12px;
${getColor('#FFFFFF')}
`