import { useEffect, useState, useContext } from 'react'
import { IObject } from 'interfaces'
import { SapiensContext } from 'context'
import {
  ClickableCard,
  PagePathing,
  PageTitle,
  ResumeInputCard,
} from 'components'
import FEF from 'pages/DetalhesModal/AluguelVerde/FEF'
import { numberToMoney } from 'helper/utils'
import useFetch from 'hooks/useFetch'
import { CardsWrap, Wrapper } from './style'

const CONTAINER_WIDTH = 80

export default function Details(props: IObject) {
  const { selected, setExtendedMenuData } = props
  const { setBuffer, buffer } = useContext(SapiensContext)
  const [coverageData, setCoverageData] = useState<IObject>()
  const api = useFetch(`product/aluguel-verde?type=coverage&id=${selected.id}`)

  useEffect(() => {
    api.fetch('get').then((res) => {
      if (!res.data.response)
        throw new Error('Não foi possível realizar requisição')

      const { contract } = res.data.response
      setCoverageData(res.data.response)
      setBuffer({
        chatTitle: `Solicitação: ${selected.code}`,
        chatSubtitle: `Contrato: ${contract.id.split('-')[0]}`,
        chatStatus: contract.status,
        chatUser: contract.partner,
        chatRoom: selected.code,
        contractId: contract.id,
      })
    })
  }, [])

  return (
    <Wrapper>
      <div className="content-wrapper">
        <PagePathing withoutMarginLeft>
          {'Gestão de clientes > Aluguel verde > Coberturas > Detalhes'}
        </PagePathing>
        <PageTitle detailsMargin withoutMarginLeft>
          Solicitação: {selected.code} <b style={{ color: '#868686' }}>/</b>{' '}
          Contrato: {coverageData?.contract.id.split('-')[0]}
        </PageTitle>
        <CardsWrap width={`${CONTAINER_WIDTH}%`}>
          <ClickableCard
            onClick={() => {
              setExtendedMenuData(
                <FEF contractId={buffer.contractId} complete />
              )
            }}
            icon="contractOutlined"
            green
          >
            Dados do contrato
          </ClickableCard>

          <ResumeInputCard
            placeholder={
              coverageData
                ? `R$ ${numberToMoney(
                    coverageData.contract.coverageAmount / 100
                  )}`
                : '--'
            }
            icon="coinOutlined"
            names="resumo-meta-captacao"
            clickable={false}
          >
            Valor da cobertura
          </ResumeInputCard>
          <ResumeInputCard
            placeholder={
              coverageData
                ? `R$ ${numberToMoney(coverageData.contract.amountPaid / 100)}`
                : '--'
            }
            icon="coinOutlined"
            names="resumo-meta-captacao"
            clickable={false}
          >
            Cobertura paga
          </ResumeInputCard>
          <ResumeInputCard
            names="resumo-meta-captacao"
            clickable={false}
            placeholder={
              coverageData
                ? `R$ ${numberToMoney(
                    coverageData.contract.coverageUsed / 100
                  )}`
                : '--'
            }
            icon="coinOutlined"
          >
            Cobertura utilizada
          </ResumeInputCard>

          {coverageData?.debtList?.map((item: IObject) => (
            <ResumeInputCard
              key={item.type}
              placeholder={item.amount}
              icon="houseGreenOutlined"
              names="resumo-meta-captacao"
              clickable={false}
            >
              {item.quantity
                ? `${item.quantity} parcelas de ${item.name}`
                : 'Outros'}
            </ResumeInputCard>
          ))}
        </CardsWrap>
      </div>
    </Wrapper>
  )
}
