import { ITheme } from '.'

// ====================== //
// ======= COLORS ======= //
// ====================== //
const white100 = '#FFFFFF'
const gray40 = '#F9F9F9'
const gray80 = '#F5F4F3'
const gray100 = '#CDC9C5'

const black20 = '#00000040'
const black40 = '#262626'
const black80 = '#1F1F1F'
const black100 = '#070707'

const brown40 = '#9C948C'

const darkGreen = '#06E45F'
const green100 = '#0BF569'

const red80 = '#F60665'

const violet = '#CB6CE6'

const blue60 = '#03989E'

// ===================== //
// ======= THEME ======= //
// ===================== //

const DarkTheme: ITheme = {
  dark: true,
  backgroundColor: black40,
  boxShadow: black20,
  rightMenu: {
    extendable: {
      background: gray80,
    },
    background: gray40,
    shadow: '0px 4px 4px 0px #00000040',
  },
  button: {
    backgroundColor: {
      primary: black100,
      hoverPrimary: black80,

      secondary: green100,
      hoverSecondary: darkGreen,

      outlined: white100,
      hoverOutlined: gray80,

      disabled: gray100,
    },
    fontColor: {
      primary: white100,
      secondary: black100,

      outlined: black100,

      disabled: white100,
      outlinedDisabled: gray100,
    },
    border: {
      focusedPrimary: black40,

      focusedSecondary: darkGreen,

      outlined: black40,
      outlinedDisabled: gray100,
    },
  },
  card: {
    backgroundColor: {
      primary: gray40,

      secondary: black100,

      green: green100,
      danger: red80,

      disabled: gray40,
    },
    fontColor: {
      primary: black100,

      secondary: gray40,

      green: black100,
      danger: black100,

      esg: gray100,
      esgBold: gray40,

      disabled: brown40,
    },
    icon: {
      primary:
        'invert(83%) sepia(18%) saturate(7400%) hue-rotate(85deg) brightness(101%) contrast(96%)',

      secondary:
        'invert(83%) sepia(18%) saturate(7400%) hue-rotate(85deg) brightness(101%) contrast(96%)',

      disabled:
        'invert(61%) sepia(5%) saturate(504%) hue-rotate(349deg) brightness(96%) contrast(89%)',

      green: 'brightness(0) saturate(100%)',

      white:
        'invert(100%) sepia(4%) saturate(370%) hue-rotate(252deg) brightness(114%) contrast(100%)',
    },
    variation: {
      primary: gray100,
      secondary: gray100,
      green: black100,
      danger: black100,
      disabled: brown40,
    },
    ribbon: {
      backgroundColor: {
        pro: blue60,
        soon: green100,
        basic: violet,
      },
    },
  },
}

export default DarkTheme
