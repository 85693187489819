import styled from 'styled-components'

export const ExtendedContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 95%;
  padding: 54px 0 0 50px;
  width: 94%;
  /* margin-left: 103px; */
  background-color: #f5f4f3;
`
