import { useContext, useEffect, useState, useCallback, useRef } from 'react'
import { Kanban, Modal } from 'components'
import { SapiensContext } from 'context'
import useFetch from 'hooks/useFetch'
import useSocket from 'hooks/useSocket'
import { clearString } from 'helper/utils'
import DetalhesCupom from 'pages/DetalhesModal/Cupom'

import { IKanbanData, IObject } from 'interfaces'

export default function KanbanMovimentacoes() {
  // const { searchParam, setBuffer } = useContext(SapiensContext)
  const [kanbanData, setKanbanData] = useState<IKanbanData>({})
  const [socket, setSocket] = useState<IObject>({})
  const [showModal, setShowModal] = useState<boolean>(false)
  const [detailCard, setDetailCard] = useState<any>({})
  const api = useFetch('cupom/list')
  const apiCard = useFetch()

  // const detailCard = useRef<any>({})

  const fetch = useCallback(() => {
    api.fetch('get').then((res: IObject) => {
      if (res.fail) throw new Error()
      const { data } = res
      // console.log(data.response)
      setKanbanData(data.response)
    })
  }, [])

  useEffect(fetch, [])

  useSocket((io: IObject) => {
    setSocket(io)
    io.on('adm_refresh_withdraw', fetch)
  }, socket)

  const onDropCard = async (itemId: number, columnId: number) => {
    try {
      const body = {
        status: kanbanData[columnId].id,
      }
      apiCard.fetch('put', body, `cupom/status/${itemId}`).catch((err) => {
        console.error(err)
      })
      // console.log(body)
    } catch (err: any) {
      console.error(err.message)
    }
  }

  const onCardClick = async (item: { [key: string]: any }) => {
    try {
      // code
      const url = `/cupom/${item.id}`
      apiCard.fetch('get', null, url).then((res) => {
        setDetailCard(res.data.response)
      })
      // console.log(item)
      setShowModal(true)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <div className="container">
        <Kanban
          data={kanbanData}
          setData={setKanbanData}
          onDropCard={onDropCard}
          onCardClick={onCardClick}
          noIconCards
        />
      </div>
      <Modal showModal={showModal} setShowModal={setShowModal} FS>
        <DetalhesCupom data={detailCard} />
      </Modal>
    </>
  )
}
