import Select, { StylesConfig } from 'react-select'

interface ISelectColorProps {
  selection: { label: string; value: string | number; color: string }[]
  width?: string
  placeholder?: string
  onSelect?: (_ev?: any) => any
}

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
})

export default (props: ISelectColorProps) => {
  const { selection, width = '150px', onSelect, placeholder } = props
  const colourStyles: StylesConfig = {
    container: (styles) => ({
      ...styles,
      width,
    }),
    control: (styles) => ({
      ...styles,
      maxWidth: width,
      fontSize: '14px',
      lineHeight: '20px',
      backgroundColor: ' #fdfdfd',
      padding: '5px 10px 5px 10px',
    }),
    option: (styles, { data, isFocused }: any) => ({
      ...styles,
      backgroundColor: isFocused ? '#F5F4F3' : '#fdfdfd',
      color: '#9C948C',
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'Roboto',
      height: '50px',
      maxWidth: width,
      border: 'none',
      ...dot(data.color),
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 212,
      margin: '0',
      backgroundColor: ' #fdfdfd',
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'Roboto',
      textAlign: 'left',
      boxSizing: 'border-box',
      borderRadius: '5px',
      maxWidth: width,
      border: '0 !important',
      boxShadow: '0 !important',
    }),
    input: (styles) => ({ ...styles, ...dot() }),
    placeholder: (styles) => ({ ...styles, ...dot('#9C948C') }),
    singleValue: (styles, { data }: any) => ({ ...styles, ...dot(data.color) }),
  }

  return (
    <Select
      options={selection}
      onChange={onSelect}
      placeholder={placeholder || 'Selecione'}
      isSearchable={false}
      styles={colourStyles}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  )
}
