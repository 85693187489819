import { InsuranceCard, PageTitle } from 'components'
import icons from 'helper/icons'
import { CardsContainer, Row } from 'styles'
import { SubContentContainer, FlexContainer, Span, Card } from '../style'

export default () => {
  return (
    <SubContentContainer>
      <FlexContainer>
        <img className="icon" src={icons.shieldOutlined} alt="card icon" />
        <PageTitle>Modalidade do seguro</PageTitle>
      </FlexContainer>
      <CardsContainer>
        <Row gap={20}>
          <div>
            <InsuranceCard green icon="buildingOutlined">
              Completo
            </InsuranceCard>
            <Span>
              <img
                className="titleIcon"
                src={icons.greenCheckOutlined}
                alt="first title icon"
              />
              <p>Raios</p>
            </Span>
            <Span>
              <img
                className="titleIcon"
                src={icons.greenCheckOutlined}
                alt="first title icon"
              />
              <p>Explosão</p>
            </Span>
            <Span>
              <img
                className="titleIcon"
                src={icons.greenCheckOutlined}
                alt="first title icon"
              />
              <p>Incêndio</p>
            </Span>
            <Span>
              <img
                className="titleIcon"
                src={icons.greenCheckOutlined}
                alt="first title icon"
              />
              <p>Alagamento</p>
            </Span>
            <Span>
              <img
                className="titleIcon"
                src={icons.greenCheckOutlined}
                alt="first title icon"
              />
              <p>Danos elétricos</p>
            </Span>
            <Span>
              <img
                className="titleIcon"
                src={icons.greenCheckOutlined}
                alt="first title icon"
              />
              <p>Danos hidráulicos</p>
            </Span>
            <Span>
              <img
                className="titleIcon"
                src={icons.greenCheckOutlined}
                alt="first title icon"
              />
              <p>Encanador</p>
            </Span>
            <Card>
              <span className="title">R$ 100,00</span>
              <span>mensal</span>
            </Card>
          </div>
        </Row>
      </CardsContainer>
    </SubContentContainer>
  )
}
