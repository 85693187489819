import { menuIcons } from 'helper'
import { IMenuIcons } from 'helper/menuIcons'
import { MouseEvent } from 'react'
import { MenuButton } from './style'

interface IMenuButton {
  icon: keyof IMenuIcons
  children?: string
  onClick?: (_e: MouseEvent<HTMLElement>) => void
  name: string
  active?: boolean
  extended: boolean
  extendedIcon?: boolean
  fixed?: boolean
  to?: string
}

export default function MenuComponent({
  icon,
  children,
  extended,
  active,
  to,
  ...rest
}: IMenuButton) {
  return extended ? (
    <MenuButton extended={extended} active={active} {...rest} to={to || ''}>
      <img
        className={!active ? 'menuextendedactive' : ''}
        src={menuIcons[icon]}
        alt={`menu ${icon}`}
      />
      <span>{children}</span>
    </MenuButton>
  ) : (
    <MenuButton extended={extended} active={active} {...rest} to={to || ''}>
      <img
        src={menuIcons[icon]}
        alt={`menu ${icon}`}
        className={active ? 'menuextendedactive' : ''}
      />
      <span>{children}</span>
    </MenuButton>
  )
}
