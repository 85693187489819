import { Card, PagePathing } from 'components'
import Tooltip from 'react-tooltip'
import icons, { IIcons } from 'helper/icons'
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { Row } from 'styles'
import { useFetch } from 'hooks'
import {
  ContentContainer,
  Img,
  CardsWrap,
  IconContainer,
  Button,
} from './style'
import DetalhesInvestidores from '../DetalhesInvestidores'

interface ICaptionData {
  cotizationIn: number
  cotizationOut: number
  deadline: number
  image: string
  investors: number
  meta: string
  min: string
  name: string
  paid: string // rentabilidade paga
  paymentType: string
  productName: string
  profitability: string
  total: string // captados
  type: string // personal ou aberta
  // rentapibilidade tota
  // rentabiidade paga
}

interface IDetailCaptionProps {
  productId: number
  purchaseId: number
  title: string
  isChangable?: boolean
  column: {
    name: string
    color: string
    icon: keyof IIcons
  }
  productIcon: string
  productAttach: string
  setExtendedMenu: Dispatch<SetStateAction<ReactElement | boolean>>
}

export default (props: IDetailCaptionProps) => {
  const {
    productId,
    purchaseId,
    setExtendedMenu,
    isChangable,
    productAttach,
    title,
    column: { name, color, icon },
    productIcon,
  } = props

  const [captionData, setCaptiondata] = useState<ICaptionData>({
    cotizationIn: 0,
    cotizationOut: 0,
    deadline: 0,
    image: '',
    investors: 0,
    meta: '',
    min: '',
    name: '',
    paid: '',
    paymentType: '',
    productName: '',
    profitability: '',
    total: '',
    type: '',
  })
  const api = useFetch('purchase')

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const r = await api.fetch(
          'get',
          {},
          `purchase?id=${purchaseId}&productId=${productId}`
        )
        if (r.fail) throw new Error()
        const { response } = r.data
        setCaptiondata(response)
      } catch (e) {
        console.error(e)
      }
    }
    fetchUsers()
    setExtendedMenu(false)
  }, [])

  const block = async () => {
    try {
      const r = await api.fetch('delete', { productId, id: purchaseId })
      if (r.fail) throw new Error()
    } catch (e) {
      console.error(e)
    }
  }

  const trash = async () => {
    try {
      const r = await api.fetch('delete', {
        productId,
        id: purchaseId,
        final: true,
      })
      if (r.fail) throw new Error()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <ContentContainer color={color}>
      <PagePathing withoutMarginLeft>
        {'Gestão de produtos > Investimentos ESG > Captações'}
      </PagePathing>
      <Row gap={5}>
        <p className="title-page">{title}</p>
        {isChangable && (
          <>
            <Button type="button" onClick={() => block()}>
              <Img
                src={icons.lockClosedOutlined2}
                alt="lock-icon"
                data-for="lock"
                data-tip="Finalizar captação"
                className="image-button"
              />
              <Tooltip place="bottom" id="lock" effect="solid" />
            </Button>
            <Button type="button" onClick={() => trash()}>
              <Img
                src={icons.blockedSolid}
                alt="trash-icon"
                className="black-icon image-button"
                data-for="trash"
                data-tip="Cancelar captação"
              />
              <Tooltip place="bottom" id="trash" effect="solid" />
            </Button>
          </>
        )}
      </Row>
      <Row gap={5}>
        <IconContainer>
          <Img src={icons[productIcon]} alt="lock-icon" className="tiny-icon" />
          <span style={{ marginLeft: 5 }}>{productAttach}</span>
        </IconContainer>
        <IconContainer>
          <Img
            src={icons[icon]}
            alt="lock-icon"
            color={color}
            className="tiny-icon"
          />
          <span style={{ color, marginLeft: 5 }}>{name}</span>
        </IconContainer>
      </Row>
      <CardsWrap>
        <Card plain icon="targetOutlined" value={captionData.meta} wide>
          Meta de Captação
        </Card>
        <Card plain icon="chartArrowOutlined" value={captionData.profitability}>
          Rentabilidade
        </Card>
        <Card plain icon="dollarsignOutlined" value={captionData.min}>
          Investimento mínimo
        </Card>
        <Card
          green
          blackIcon
          plain
          icon="userOutlined"
          value={String(captionData.investors)}
          onClick={() =>
            setExtendedMenu(
              <DetalhesInvestidores
                productId={productId}
                purchaseId={purchaseId}
              />
            )
          }
        >
          Investidores
        </Card>
        <Card plain icon="coinOutlined" value={captionData.total} wide>
          Captados
        </Card>
        <Card
          plain
          icon="contractOutlined"
          value={`${captionData.deadline} meses`}
        >
          Contrato
        </Card>
        <Card plain icon="coinOutlined" value={captionData.paymentType}>
          Tipo de pagamento
        </Card>
        <Card
          plain
          icon="crosshairOutlined"
          value={`${captionData.cotizationIn} dias`}
        >
          Cotização
        </Card>
        <Card plain icon="coinOutlined" value={'R$ 100.000,00'} wide>
          Rentabilidade total
        </Card>
        <Card plain icon="coinOutlined" value={captionData.paid} wide disabled>
          Rentabilidade paga
        </Card>
        <Card
          plain
          icon="loginOutlined"
          value={`${captionData.cotizationOut} dias`}
        >
          Coatização de resgate
        </Card>
      </CardsWrap>
    </ContentContainer>
  )
}
