import { SapiensContext } from 'context'
import icons from 'helper/icons'
import { useContext } from 'react'
import { SearchBar, SearchBarContainer } from './style'

export default function SearchBarComponent() {
  const { extended } = useContext(SapiensContext)

  return (
    <SearchBarContainer extended={extended}>
      <div className="row">
        <img
          src={icons.searchOutlined}
          alt="search icon"
          className="leftIcon"
        />
        <SearchBar placeholder="Pesquisar" />
      </div>
      <img src={icons.bellOutlined} alt="notification" className="rightIcon" />
    </SearchBarContainer>
  )
}
