import { useContext, useEffect, useState } from 'react'
import { SapiensContext } from 'context'
import { ResumeInputCard, ResumeSelectCard } from 'components'
import { dataToMoney, clearString } from 'helper/utils'
import { IObject } from 'interfaces'

import useFetch from 'hooks/useFetch'

interface IProps {
  contractData: IObject
}

interface IArray {
  [key: number]: string
}

interface IStatus {
  selected: boolean
  value: number
  label: string
  color: string
  rule: IArray | boolean
}

export default (props: IProps) => {
  const { contractData } = props
  const { buffer } = useContext(SapiensContext)
  const userApi = useFetch(`product/aluguel-verde?type=${buffer.type}`)
  const [currentStatus, setCurrentStatus] = useState<IObject>()

  useEffect(() => {
    if (!('status' in buffer)) return
    const [curStatus] = buffer.status.filter((item: IStatus) => item.selected)
    setCurrentStatus(curStatus)
  }, [])

  const onSelectStatus = (status: IStatus) => {
    const statusRule = currentStatus?.rule
    if (
      (typeof statusRule === 'boolean' && statusRule) ||
      (Array.isArray(statusRule) && statusRule.includes(status.value))
    ) {
      userApi
        .fetch('put', { id: buffer.contractId, status: status.value })
        .then((res: { [key: string]: any }) => {
          buffer.setKanbanData(res.data.response)
        })
    }
  }

  return (
    <>
      <ResumeInputCard
        small
        clickable={false}
        names="resumo-meta-captacao"
        icon="contractOutlined"
        placeholder={`#${contractData?.property?.code || '--'}`}
      >
        Número do contrato
      </ResumeInputCard>
      <ResumeInputCard
        clickable={false}
        names="resumo-meta-captacao"
        icon="buildingOutlined"
        placeholder={contractData?.partner?.name || '--'}
      >
        Imobiliária
      </ResumeInputCard>
      <ResumeInputCard
        small
        clickable={false}
        names="resumo-meta-captacao"
        icon="locationOutlined"
        placeholder={contractData?.property?.address?.city || '--'}
      >
        Cidade
      </ResumeInputCard>
      <ResumeInputCard
        clickable={false}
        names="resumo-meta-captacao"
        icon="locationOutlined"
        placeholder={`${contractData?.property?.address?.address || '--'}, ${
          contractData?.property?.address?.addressNumber || '--'
        }`}
      >
        Endereço
      </ResumeInputCard>
      <ResumeInputCard
        small
        clickable={false}
        names="resumo-meta-captacao"
        icon="contractOutlined"
        placeholder={dataToMoney(contractData?.coverageAmount || 0)}
      >
        Valor da cobertura
      </ResumeInputCard>
      <ResumeInputCard
        small
        clickable={false}
        names="resumo-meta-captacao"
        icon="contractOutlined"
        placeholder={dataToMoney(contractData?.coverageUsed || 0)}
      >
        Cobertura utilizada
      </ResumeInputCard>
      <ResumeInputCard
        clickable={false}
        names="resumo-meta-captacao"
        icon="coinOutlined"
        placeholder={dataToMoney(
          Number(clearString(contractData?.coverageAmount)) -
            Number(clearString(contractData?.coverageUsed))
        )}
      >
        Saldo disponível da cobertura
      </ResumeInputCard>
      <ResumeInputCard
        clickable={false}
        names="resumo-meta-captacao"
        icon="contractOutlined"
        placeholder={dataToMoney(Number(contractData?.contractValue) * 100)}
      >
        Valor do contrato de garantia
      </ResumeInputCard>
      <ResumeInputCard
        small
        clickable={false}
        names="resumo-meta-captacao"
        icon="checkedContractOutlined"
        placeholder={`${contractData?.deadline} meses` || '12 meses'}
      >
        Prazo do contrato
      </ResumeInputCard>
      {buffer.status && (
        <ResumeSelectCard
          isDoted
          options={buffer.status}
          name={currentStatus?.label || '--'}
          icon="contractOutlined"
          handleChange={onSelectStatus}
        >
          Status
        </ResumeSelectCard>
      )}
    </>
  )
}
