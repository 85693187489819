import { icons } from 'helper'
import { IIcons } from 'helper/icons'
import { useState } from 'react'
import styled from 'styled-components'
import { ResumeInputCard } from './style'

interface ICardProps {
  isMask?: string
  children: string
  icon?: keyof IIcons
  small?: boolean
  green?: boolean
  secondary?: boolean
  disabled?: boolean
  onClick?: any
  placeholder: string
  handleChangeInput?: any
  names: string
  clickable?: boolean
  medium?: boolean
  blocks?: any
  smallIcon?: boolean
}

const TextDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
`

export default function ResumeTextAreaCardComponent(props: ICardProps) {
  const [disabled, setDisabled] = useState(true)
  const {
    placeholder,
    children,
    icon,
    handleChangeInput,
    clickable = true,
  } = props

  return (
    <ResumeInputCard {...props}>
      <TextDiv>
        {icon && (
          <img className="iconClickable" src={icons[icon]} alt="card icon" />
        )}
        {disabled ? (
          <input placeholder={placeholder} disabled value={placeholder} />
        ) : (
          <textarea
            placeholder={placeholder}
            value={placeholder}
            rows={3}
            cols={15}
            onChange={(e) => handleChangeInput(e.target.value)}
          />
        )}
        {clickable ? (
          disabled ? (
            <img
              className="editIcon"
              src={icons.editOutlined}
              alt="edit icon"
              onClick={() => setDisabled((curr) => !curr)}
            />
          ) : (
            <p onClick={() => setDisabled((curr) => !curr)}>OK</p>
          )
        ) : (
          <div />
        )}
      </TextDiv>
      <span className="title">{children}</span>
    </ResumeInputCard>
  )
}
