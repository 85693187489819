import styled from 'styled-components'
import { ITheme } from 'styles/Themes'

interface IStyleProps {
  theme: ITheme
  green?: boolean
  secondary?: boolean
  disabled?: boolean
  boldTitle?: boolean
  large?: boolean
  smallIcon?: boolean
}

export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  height: ${(props: IStyleProps) => {
    if (props.large) return '164px'
    return '145px'
  }};
  width: ${(props: IStyleProps) => {
    if (props.large) return '195px'
    return '175px'
  }};

  border-radius: 5px;
  box-shadow: 0px 3px 4px 0px #00000040;

  background: ${(props: IStyleProps) => {
    if (props.green) return `${props.theme.card.backgroundColor.green}`
    if (props.secondary) return `${props.theme.card.backgroundColor.secondary}`
    return `${props.theme.card.backgroundColor.primary}`
  }};

  cursor: ${(props: IStyleProps) => {
    if (props.disabled) return ''
    return 'pointer'
  }};

  .title {
    color: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.fontColor.disabled}`
      if (props.secondary) return `${props.theme.card.fontColor.secondary}`
      return `${props.theme.card.fontColor.primary}`
    }};

    font-size: 14px;
    font-style: normal;
    font-weight: ${(props: IStyleProps) => {
      if (props.boldTitle) return '700'
      return '400'
    }};

    text-align: left;
    line-height: 12px;

    margin: 0 0 22px 25px;
  }

  .iconClickable {
    filter: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.icon.disabled}`
      if (props.secondary) return `${props.theme.card.icon.white}`
      if (props.green) return `${props.theme.card.icon.green}`
      return `${props.theme.card.icon.green}`
    }};
    max-width: 24px;
    height: ${(props: IStyleProps) => {
      if (props.smallIcon) return '16px'
      return '24px'
    }};
    margin: 0 0 12px 25px;
  }
`
