import { SapiensContext } from 'context/sapiens'
import { useContext, useRef } from 'react'
import {
  PageTitle,
  PagePathing,
  ButtonNext,
  ResumeInputCard,
  ResumeSelectCard,
  ResumeTextAreaCard,
} from 'components'
import { Form } from '@unform/web'
import { states } from 'helper/consts'
import { SubTitle, CardsWrap, Wrapper } from './style'

export default function ResumoNovoCliente({ handleNext }: any) {
  const { selectedClient, setSelectedClient } = useContext(SapiensContext)
  const formRef = useRef<HTMLFormElement | any>(null)

  const handleChangePage = () => {
    return null
  }
  return (
    <Wrapper>
      <div className="content-wrapper">
        <PagePathing
          withoutMarginLeft
        >{`Gestão do cliente > Novo cliente > Resumo`}</PagePathing>
        <PageTitle withoutMarginLeft>Resumo da conta editável</PageTitle>
        <SubTitle>
          Confira e avance na seta abaixo para confirmar a criação
        </SubTitle>
        {/* <ScrollDiv> */}
        {/* <FormDiv> */}
        <Form onSubmit={handleChangePage} ref={formRef} className="big-form">
          <CardsWrap>
            {/* <Row gap={20}> */}
            <ResumeInputCard
              clickable={false}
              names="resumo-meta-captacao"
              icon="contractOutlined"
              placeholder={selectedClient.accountType.label}
            >
              Tipo de Conta
            </ResumeInputCard>
            <ResumeTextAreaCard
              smallIcon
              handleChangeInput={(e: any) =>
                setSelectedClient({
                  ...selectedClient,
                  firstName: e,
                })
              }
              names="resumo-meta-captacao"
              icon="clientOutlined"
              placeholder={selectedClient.firstName}
            >
              {selectedClient.accountType.value === 0
                ? 'Nome completo'
                : 'Razão social'}
            </ResumeTextAreaCard>
            <ResumeInputCard
              smallIcon
              isMask={
                selectedClient.accountType.value === 0
                  ? '000.000.000-00'
                  : '00.000.000/0000-00'
              }
              handleChangeInput={(e: any) =>
                setSelectedClient({
                  ...selectedClient,
                  document: e,
                })
              }
              names="resumo-meta-captacao"
              icon="clientOutlined"
              placeholder={selectedClient.document}
            >
              {selectedClient.accountType.value === 0 ? 'CPF' : 'CNPJ'}
            </ResumeInputCard>
            {/* </Row>
              <Row gap={20}> */}
            <ResumeTextAreaCard
              smallIcon
              handleChangeInput={(e: any) =>
                setSelectedClient({
                  ...selectedClient,
                  secondName: e,
                })
              }
              names="resumo-meta-captacao"
              icon="clientOutlined"
              placeholder={selectedClient.secondName}
            >
              {selectedClient.accountType.value === 0
                ? 'Nome da mãe'
                : 'Nome Fantasia'}
            </ResumeTextAreaCard>
            <ResumeTextAreaCard
              handleChangeInput={(e: any) =>
                setSelectedClient({
                  ...selectedClient,
                  email: e,
                })
              }
              names="resumo-meta-captacao"
              icon="mailOutlined"
              placeholder={selectedClient.email}
            >
              Email
            </ResumeTextAreaCard>
            <ResumeInputCard
              isMask="(00) 00000-0000"
              handleChangeInput={(e: any) =>
                setSelectedClient({
                  ...selectedClient,
                  cellphone: e,
                })
              }
              names="resumo-meta-captacao"
              icon="phoneOutlined"
              placeholder={selectedClient.cellphone}
            >
              Telefone
            </ResumeInputCard>
            {/* </Row>
              <Row gap={20}> */}
            <ResumeInputCard
              isMask="00000-000"
              handleChangeInput={(e: any) =>
                setSelectedClient({
                  ...selectedClient,
                  address: {
                    ...selectedClient.address,
                    postalCode: e,
                  },
                })
              }
              names="resumo-meta-captacao"
              small
              icon="locationOutlined"
              placeholder={selectedClient.address.postalCode}
            >
              CEP
            </ResumeInputCard>
            <ResumeInputCard
              handleChangeInput={(e: any) =>
                setSelectedClient({
                  ...selectedClient,
                  address: {
                    ...selectedClient.address,
                    number: e,
                  },
                })
              }
              names="resumo-meta-captacao"
              small
              icon="locationOutlined"
              placeholder={selectedClient.address.number}
            >
              Número
            </ResumeInputCard>
            <ResumeTextAreaCard
              handleChangeInput={(e: any) =>
                setSelectedClient({
                  ...selectedClient,
                  address: {
                    ...selectedClient.address,
                    complement: e,
                  },
                })
              }
              names="resumo-meta-captacao"
              small
              icon="locationOutlined"
              placeholder={selectedClient.address.complement}
            >
              Complemento
            </ResumeTextAreaCard>
            <ResumeTextAreaCard
              handleChangeInput={(e: any) =>
                setSelectedClient({
                  ...selectedClient,
                  address: {
                    ...selectedClient.address,
                    district: e,
                  },
                })
              }
              names="resumo-meta-captacao"
              small
              icon="locationOutlined"
              placeholder={selectedClient.address.district}
            >
              Bairro
            </ResumeTextAreaCard>
            {/* </Row>
              <Row gap={20}> */}
            <ResumeTextAreaCard
              handleChangeInput={(e: any) =>
                setSelectedClient({
                  ...selectedClient,
                  address: {
                    ...selectedClient.address,
                    street: e,
                  },
                })
              }
              names="resumo-meta-captacao"
              icon="locationOutlined"
              placeholder={selectedClient.address.street}
            >
              Endereço
            </ResumeTextAreaCard>
            <ResumeSelectCard
              small
              type="cotizationOutOptions"
              icon="locationOutlined"
              name={selectedClient.address.state.label}
              options={states}
            >
              UF
            </ResumeSelectCard>
            <ResumeTextAreaCard
              clickable={false}
              names="resumo-meta-captacao"
              small
              icon="locationOutlined"
              placeholder={selectedClient.address.city}
            >
              Cidade
            </ResumeTextAreaCard>
            {/* </Row> */}
          </CardsWrap>
        </Form>
        {/* </FormDiv> */}
        {/* </ScrollDiv> */}
      </div>
      <div className="align-self">
        <ButtonNext onClick={handleNext} />
      </div>
    </Wrapper>
  )
}
