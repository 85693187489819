import { Card, PagePathing, PageTitle } from 'components'
import { SapiensContext } from 'context/sapiens'
import { useContext } from 'react'
import { CardsContainer } from 'styles'
import { ExtendedContainer } from './style'

interface IDocumentsMenuProps {
  isPF: boolean
}

export default (props: IDocumentsMenuProps) => {
  const { isPF } = props

  const { setShowCarousel } = useContext(SapiensContext)
  return (
    <ExtendedContainer>
      <PagePathing withoutMarginLeft>
        {'Detalhes do cliente > Informações > Documentos'}
      </PagePathing>
      <PageTitle withoutMarginLeft>Documentos</PageTitle>
      {isPF ? (
        <CardsContainer withoutMarginLeft>
          <Card
            blackIcon
            plain
            icon="folderOutlined"
            value={''}
            onClick={() => setShowCarousel('document')}
          >
            RG
          </Card>
          <Card
            blackIcon
            plain
            icon="folderOutlined"
            value={''}
            onClick={() => setShowCarousel('document')}
          >
            CNH
          </Card>
          <Card
            blackIcon
            plain
            icon="maximizeOutlined"
            value={''}
            onClick={() => setShowCarousel('selfie')}
          >
            Selfie
          </Card>
        </CardsContainer>
      ) : (
        <CardsContainer withoutMarginLeft>
          <Card blackIcon plain icon="contractOutlined" value={''} disabled>
            Contrato social
          </Card>
          <Card blackIcon plain icon="contractOutlined" value={''} disabled>
            Acordo de sócios
          </Card>
        </CardsContainer>
      )}
    </ExtendedContainer>
  )
}
