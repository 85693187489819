import { SapiensContext } from 'context/sapiens'
import { useContext, useRef } from 'react'
import { IMask } from 'react-imask'

import {
  PageTitle,
  PagePathing,
  ButtonNext,
  ResumeInputCard,
  ResumeSelectCard,
  ResumeTextAreaCard,
} from 'components'
import { Form } from '@unform/web'
import { numberToMoney } from 'helper/utils'
import { SubTitle, CardsWrap, Wrapper } from './style'

import { IObject } from 'interfaces'


// const novoCupom = {
//   campaignType: { label: 'Abertura de conta', value: 0 },
//   cupomName: 'abertura',
//   cupomCode: 'abc12',
//   currency: { label: 'OTTB1', value: 0 },
//   cupomExpiration: '22/05/2024',
//   cupomSupply: 300,
//   cupomValue: 'R$ 2.000,00',
// }

const accountType = [
  { label: 'Pessoa física', value: 0 },
  { label: 'Pessoa jurídica', value: 1 },
]

export default function ResumoNovoCupom({ handleNext }: any) {
  const { novoCupom, setNovoCupom } = useContext(SapiensContext)
  const formRef = useRef<HTMLFormElement | any>(null)

  const handleChangePage = () => {
    return null
  }
  return (
    <Wrapper>
      <div className="content-wrapper">
        <PagePathing
          withoutMarginLeft
        >{`Gestão do cliente > Novo cliente > Resumo `}</PagePathing>
        <PageTitle withoutMarginLeft>Resumo do Novo Cupom</PageTitle>
        <SubTitle>
          Confira e avance na seta abaixo para confirmar a criação
        </SubTitle>
        <Form onSubmit={handleChangePage} ref={formRef} className="big-form">
          <CardsWrap>
            <ResumeSelectCard
              handleChange={(e: any) => {
                setNovoCupom((c: IObject) => ({
                  ...c,
                  campaignType: e,
                }))
              }}
              icon="volumeBlack"
              type="campaignType"
              options={accountType}
              name={novoCupom.campaignType.label}
            >
              Tipo de campanha
            </ResumeSelectCard>
            <ResumeTextAreaCard
              // smallIcon
              handleChangeInput={(e: any) => {
                setNovoCupom((c: IObject) => ({
                  ...c,
                  cupomName: e,
                }))
              }}
              names="resumo-meta-captacao"
              icon="ticketBlack"
              placeholder={novoCupom.cupomName}
            >
              Nome do cupom
            </ResumeTextAreaCard>
            <ResumeTextAreaCard
              // smallIcon
              small
              names="cupomCode"
              icon="locationOutlined"
              placeholder={novoCupom.cupomCode}
              disabled
            >
              Código do cupom
            </ResumeTextAreaCard>
            {/* </Row>
              <Row gap={20}> */}
            <ResumeInputCard
              handleChangeInput={(e: any) =>
                setNovoCupom((c: IObject) => ({
                  ...c,
                  cupomValue: e,
                }))
              }
              names="cupomValue"
              icon="coinOutlined"
              placeholder={`R$ ${numberToMoney(novoCupom.cupomValue)}`}
            >
              Valor do cupom
            </ResumeInputCard>
            <ResumeInputCard
              handleChangeInput={(e: any) =>
                setNovoCupom((c: IObject) => ({
                  ...c,
                  cupomExpiration: e,
                }))
              }
              isMask="d/m/Y"
              blocks={{
                d: {
                  mask: IMask.MaskedRange,
                  from: 1,
                  to: 31,
                  maxLength: 2,
                },
                m: {
                  mask: IMask.MaskedRange,
                  from: 1,
                  to: 12,
                  maxLength: 2,
                },
                Y: {
                  mask: IMask.MaskedRange,
                  from: new Date().getFullYear(),
                  to: 3000,
                },
              }}
              names="cupomExpiration"
              icon="calendarOutlined"
              placeholder={novoCupom.cupomExpiration}
            >
              Prazo de expiração do cupom
            </ResumeInputCard>
            <ResumeTextAreaCard
              handleChangeInput={(e: any) => {
                setNovoCupom((c: IObject) => ({
                  ...c,
                  cupomSupply: e,
                }))
              }}
              names="cupomSupply"
              icon="ticketBlack"
              placeholder={String(novoCupom.cupomSupply)}
            >
              Quantidade limite de cupom
            </ResumeTextAreaCard>
          </CardsWrap>
        </Form>
      </div>
      <div className="align-self">
        <ButtonNext onClick={handleNext} />
      </div>
    </Wrapper>
  )
}
