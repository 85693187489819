import { IIcons } from 'helper/icons'
import { IObject } from 'interfaces'
import { createContext, Dispatch, SetStateAction, useState } from 'react'
import { ITheme, LightTheme, DarkTheme } from 'styles/Themes'

interface ICardInfo {
  label: string
  variation?: string
  value: string
  backgroundColor: string
  size: string
  icon: keyof IIcons
}

interface ICupomState {
  campaignType: { label: string; value: number }
  cupomName: string
  cupomCode: string
  currency: { label: string; value: number }
  cupomExpiration: string
  cupomSupply: number
  cupomValue: string
}

interface ICardsInfo {
  clientNumber: ICardInfo | Record<string, never>
  money: ICardInfo | Record<string, never>
  custody: ICardInfo | Record<string, never>
}
interface ISapiensContextType {
  cardsInfo: ICardsInfo
  setCardsInfo: Dispatch<SetStateAction<ICardsInfo>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  extended: boolean
  setExtended: Dispatch<SetStateAction<boolean>>
  searchParam: string
  setSearchParam: Dispatch<SetStateAction<string>>
  theme: ITheme
  toggleDarkMode: VoidFunction
  showModalNewClient: boolean
  setShowModalNewClient: Dispatch<SetStateAction<boolean>>
  showModalNovoCupom: boolean
  setShowModalNovoCupom: Dispatch<SetStateAction<boolean>>
  preview?: string
  preview64?: string
  setPreview: Dispatch<SetStateAction<string>>
  setPreview64: Dispatch<SetStateAction<string>>
  selected?: any
  setSelected?: any
  captationData?: any
  setCaptationData?: any
  pin: string
  setPin: any
  title: string
  setTitle: any
  selectedClient: any
  setSelectedClient: any
  setShowModalPF: Dispatch<SetStateAction<boolean>>
  showModalPF: boolean
  showCarousel: string | undefined
  setShowCarousel: Dispatch<SetStateAction<string | undefined>>
  newCard: boolean
  setNewCard: Dispatch<SetStateAction<boolean>>
  buffer: any
  setBuffer: Dispatch<SetStateAction<any>>
  extendModal: boolean
  setExtendModal: Dispatch<SetStateAction<boolean>>
  socket?: IObject
  setSocket?: Dispatch<SetStateAction<any>>
  novoCupom?: any
  setNovoCupom?: any
  newCaption?: any
  setNewCaption?: any,
  defaultCaption: any
}

export const SapiensContext = createContext<ISapiensContextType>(null!)

const defaultCaption = {
  metaCaption: null,
  fullName: null,
  tick: null,
  name: null,
  modalCaption: null,
  tipoInvest: null,
  garantia: null,
  cetip: null,
  price: null,
  supply: null,
  minimumInvest: null,
  periodoLanc: null,
  prazo: null,
  pagamentoJuros: null,
  liquidez: null,
  indexador: null,
  index: null,
  profitability: null,
  impostoRenda: null,
  coatizationPre: null,
  coatizationPos: null,
  cupom: null,
  color: null,
  secondColor: null,
  previewImg: null,
  imageOpacity: 50,
  preview64Token: null,
  details: [],
  contratoCCB: null,
  alienFiduciaria: null,
  avalSocios: null,
  contratoCDB: null,
}

export function SapiensProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = useState(false)
  const [socket, setSocket] = useState<IObject>()

  // CARDS Gestão Cliente
  const [cardsInfo, setCardsInfo] = useState<ICardsInfo>({
    clientNumber: {},
    money: {},
    custody: {},
  })

  // MENU
  const [extended, setExtended] = useState(false)

  // SUBMENU Conta Digital
  const [searchParam, setSearchParam] = useState('')

  // MODAL Cliente
  const [showModalNewClient, setShowModalNewClient] = useState(false)

  // MODAL CUPOM
  const [showModalNovoCupom, setShowModalNovoCupom] = useState(false)

  // NOVO CUPOM
  const [novoCupom, setNovoCupom] = useState<any>({
    campaignType: { label: 'Tipo de Campanha', value: 0 },
    cupomName: '',
    cupomCode: '',
    currency: { label: 'BRL', value: 0, type: 0, price: 1 },
    cupomExpiration: '',
    cupomSupply: 0,
    cupomValue: '',
  })

  // NOVA CAPTAÇÃO Preview
  const [preview, setPreview] = useState('')
  const [preview64, setPreview64] = useState('')
  const [selected, setSelected] = useState({})
  const [captationData, setCaptationData] = useState({})
  const [newCaption, setNewCaption] = useState(defaultCaption)

  // NOVO CLIENTE
  const [selectedClient, setSelectedClient] = useState({})

  // TEMAS
  const [theme, setTheme] = useState(LightTheme)

  // PIN
  const [pin, setPin] = useState('')

  // CONFIRM TITLE PAGE
  const [title, setTitle] = useState('')

  // MODAL
  const [showModalPF, setShowModalPF] = useState(false)

  // EXTEND - MODAL
  const [extendModal, setExtendModal] = useState(false)

  // CAROUSEL
  const [showCarousel, setShowCarousel] = useState<string | undefined>()

  const toggleDarkMode = () => {
    if (theme.dark) setTheme(LightTheme)
    else setTheme(DarkTheme)
  }

  // NOVO CARD - BUG REPORT - HISTORY REPORT
  const [newCard, setNewCard] = useState(false)

  // VARIAVEL PARA USO GERAL
  const [buffer, setBuffer] = useState()

  const value = {
    cardsInfo,
    setCardsInfo,
    preview,
    setPreview,
    preview64,
    setPreview64,
    showModalNewClient,
    setShowModalNewClient,
    toggleDarkMode,
    theme,
    loading,
    setLoading,
    extended,
    setExtended,
    searchParam,
    setSearchParam,
    selected,
    setSelected,
    captationData,
    setCaptationData,
    pin,
    setPin,
    title,
    setTitle,
    selectedClient,
    setSelectedClient,
    showModalPF,
    setShowModalPF,
    showCarousel,
    setShowCarousel,
    newCard,
    setNewCard,
    buffer,
    setBuffer,
    extendModal,
    setExtendModal,
    socket,
    setSocket,
    novoCupom,
    setNovoCupom,
    showModalNovoCupom,
    setShowModalNovoCupom,
    newCaption,
    setNewCaption,
    defaultCaption
  }

  return (
    <SapiensContext.Provider value={value}>{children}</SapiensContext.Provider>
  )
}
