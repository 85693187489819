import styled from 'styled-components'
import { colorConverter } from 'helper/utils'

interface IStyleProps {
  width?: string
  color?: string
}

export const PageTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
  /* line-height: 23px; */
  margin: 0;
`
export const KanbanContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: scroll;
  position: relative;
  /* max-width: 100vh; */
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: white;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }

  .column {
    display: flex;
    background-color: #f9f9f9;
    padding: 20px 22px 23px 33px;
    margin: 0px 5px;
    border-radius: 10px;
    width: 385px;
    flex-direction: column;
    gap: 24px;
    height: 672px;
  }

  .cards-container {
    display: flex;
    gap: 24px;
    padding: 10px 5px;
    flex-direction: column;
    overflow: scroll;
    height: 420px;
    ::-webkit-scrollbar {
      width: 8px;
      background-color: #c4c4c4;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-track {
      background: #f9f9f9;
      border: 0;
    }
    ::-webkit-scrollbar-thumb {
      width: 8px;
      background-color: #c4c4c4;
      border-radius: 50px;
      height: 140px;
    }
  }
`
export const Img = styled.img`
  filter: ${(props: IStyleProps) => {
    const c = props.color && colorConverter(props.color)
    return typeof c === 'object'
      ? c.filter
      : 'invert(0%) sepia(0%) saturate(2016%) hue-rotate(167deg) brightness(109%) contrast(80%)'
  }};
`
export const ColumnTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.2px;
  transform: rotate(-0.22deg);
  margin: 0;
  color: ${(props: IStyleProps) => props.color};
`
export const CardsWrap = styled.div`
  display: flex;
  position: absolute;
  flex-direction: row;
  max-height: 60%;
  height: auto;
  flex-wrap: wrap;
  /* min-width: 70%; */
  max-width: ${(props: IStyleProps) => {
    if (props.width) return `${props.width}`
    return '70%'
  }};
  overflow: scroll;
  padding: 0 50px 10px 10px;
  margin: 20px 0 20px 0;
  gap: 20px;

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: white;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }
  .row {
    display: flex;
    gap: 20px;
    width: 100%;
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-height: 90%;
  min-height: 90%;
  overflow: hidden;

  .content-wrapper {
    max-height: 90%;
    overflow: hidden;
    padding-left: 20px;
  }

  .align-self {
    width: 100%;
    z-index: 220;
  }

  .margem {
    margin-top: 10px;
  }
`
