import styled from 'styled-components'

export const FormDiv = styled.div`
  display: flex;
  width: 100%;

  .big-form {
    width: 100% !important;
  }
`

export const SubTitle = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  text-align: left;
  line-height: 26px;
`
interface IStyleProps {
  width?: string
}
export const CardsWrap = styled.div`
  display: flex;
  position: absolute;
  flex-direction: row;
  /* max-height: 100%; */
  max-height: 53%;
  /* align-items: flex-start; */
  /* border: 1px solid red; */
  margin-bottom: 10%;
  flex-wrap: wrap;
  max-width: ${(props: IStyleProps) => {
    if (props.width) return `${props.width}`
    return '60%'
  }};
  overflow: scroll;
  padding: 0 50px 10px 0;
  margin: 20px 0 0 0;
  gap: 20px;

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: white;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }
  .row {
    display: flex;
    gap: 20px;
    width: 100%;
  }
`
export const Wrapper = styled.div`
  border: red;
  display: flex;
  /* position: absolute; */
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 90%;
  overflow: hidden;

  .content-wrapper {
    height: 100%;
    overflow: hidden;
  }

  .align-self {
    /* position: absolute; */
    /* bottom: 50px; */
    width: 100%;
    z-index: 220;
    /* border: 1px solid red; */
  }
`
