import Wrapper from './Wrapper'
import Input from './Input'

interface ICheckboxProps {
  value: string
  label?: string
  name: string
  green?: boolean
  defaultChecked?: boolean
}

export default function Checkbox({
  value,
  label,
  name,
  ...rest
}: ICheckboxProps): JSX.Element {
  return (
    <Wrapper name={name}>
      <Input value={value} label={label} {...rest} />
    </Wrapper>
  )
}
