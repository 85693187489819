import styled from 'styled-components'

interface IStyleProps {
  width?: string
  height?: string
  gap?: number
  withoutMarginLeft?: boolean
  overflow?: boolean
  scrollTrack?: string
  position?: string
  marginLeft?: boolean
  paddingRight?: string
  paddingLeftTop?: boolean
}

export default styled.div`
  display: flex;
  gap: ${(props: IStyleProps) => {
    if (props.gap) return `${props.gap}px`
    return '20px'
  }};
  flex-wrap: wrap;
  width: ${(props: IStyleProps) => {
    if (props.width) return `${props.width}`
    return 'auto'
  }};
  height: ${(props: IStyleProps) => {
    if (props.height) return `${props.height}`
    return 'auto'
  }};

  margin: ${(props: IStyleProps) => {
    if (props.withoutMarginLeft) return `0 0 20px 0;`
    if (props.marginLeft) return `0 0 20px 206px;`
    return '0 0 20px 20px;'
  }};

  padding-right: ${(props: IStyleProps) => {
    if (props.paddingRight) return `${props.paddingRight}`
    // if (props.overflow) return '10px 60px 10px 0'
    return '0'
  }};

  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;

  margin-bottom: 0;

  overflow: ${(props: IStyleProps) => {
    if (props.overflow) return 'scroll'
    return 'hidden'
  }};

  position: ${(props: IStyleProps) => {
    if (props.position) return props.position
    return 'relative'
  }};

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: ${(props: IStyleProps) => {
      if (props.scrollTrack) return props.scrollTrack
      return 'white'
    }};
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }

  span {
    height: 23px;
    font-size: 14px;
    color: #868686;
  }
`
