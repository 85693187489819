import { useField } from '@unform/core'
import { useState, ChangeEvent, KeyboardEvent, useRef, useEffect } from 'react'
import { InputContainer } from 'styles'
import { Autocomplete, InputError } from './style'

interface IAutocompleteProps {
  suggestions: string[]
  name: string
  placeholder?: string
  type: string
  style?: any
}

interface ISuggertionsListProps {
  filteredSuggestions: string[]
  activeSuggestionIndex: number
  onClick: (_e: any) => void
}
const SuggestionsListComponent = ({
  filteredSuggestions,
  activeSuggestionIndex,
  onClick,
}: ISuggertionsListProps) => {
  return filteredSuggestions.length ? (
    <div className="menu">
      {filteredSuggestions.map((suggestion, index) => {
        let addClassName = ''
        if (activeSuggestionIndex === index) {
          addClassName = 'active'
        }
        return (
          <div
            key={suggestion}
            className={`options ${addClassName}`}
            onClick={onClick}
            role="textbox"
          >
            {suggestion}
          </div>
        )
      })}
    </div>
  ) : (
    <div className="menu">
      <div className="options">Sem sugestões</div>
    </div>
  )
}

export default function AutocompleteComponent({
  suggestions,
  placeholder,
  name,
  type,
  style,
}: IAutocompleteProps) {
  const { fieldName, registerField, error } = useField(name)
  const inputRef = useRef(null)

  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([])
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number>(0)
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const [input, setInput] = useState<string>('')

  const onClick = (e: { target: HTMLElement }) => {
    setFilteredSuggestions([])
    setInput(e.target.innerText)
    setShowSuggestions(false)
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    })
  }, [registerField, fieldName])

  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowDown') {
      setActiveSuggestionIndex((oldIndex) =>
        (oldIndex + 1) % filteredSuggestions.length !== 0 ? oldIndex + 1 : 0
      )
    } else if (e.key === 'ArrowUp') {
      setActiveSuggestionIndex((oldIndex) =>
        oldIndex === 0 ? filteredSuggestions.length - 1 : oldIndex - 1
      )
    } else if (e.key === 'Escape') {
      setFilteredSuggestions([])
      setShowSuggestions(false)
    } else if (e.key === 'Enter') {
      setFilteredSuggestions([])
      setShowSuggestions(false)
      setActiveSuggestionIndex(0)
      setInput(filteredSuggestions[activeSuggestionIndex])
    }
  }

  const onBlur = () => {
    setShowSuggestions(false)
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const userInput = e.target.value

    const unLinked = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    )

    setInput(e.target.value)
    setActiveSuggestionIndex(0)
    setFilteredSuggestions(unLinked)
    setShowSuggestions(true)
  }

  return (
    <InputContainer style={{ ...style }}>
      <Autocomplete error={error}>
        <input
          ref={inputRef}
          type={type}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          value={input}
          placeholder={placeholder}
          name={name}
        />
        <InputError>{error}</InputError>
        {showSuggestions && input && (
          <SuggestionsListComponent
            filteredSuggestions={filteredSuggestions}
            activeSuggestionIndex={activeSuggestionIndex}
            onClick={onClick}
          />
        )}
      </Autocomplete>
    </InputContainer>
  )
}
