import { Carousel } from 'react-responsive-carousel'
import styled from 'styled-components'

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const StyledCarousel = styled(Carousel)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 738.66px;
  height: 338px;
  margin-top: 50px;
  margin-bottom: 20px;
  .arrow {
    height: 32px;
    width: 19.33px;
  }

  .carousel-button {
    background: white;
    border: 0;
  }

  .carousel-slider {
    display: flex;
    align-items: center !important;
    justify-content: center;
  }

  .selected {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow-prev {
    transform: rotate(180deg);
    margin-left: 50px;
  }

  .arrow-next {
    margin-right: 50px;
  }
`

export const SlideContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 38px;
  background: #f9f9f9;
  height: 338px;
  width: 500px;
  margin: 0;
`
export const Slide = styled.img`
  margin: 0;
  height: 262px;
  width: 400px;
`

export const SlideLegend = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  margin: 0;
`
