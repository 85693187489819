import React, { useEffect, useState, useRef } from "react";
import {
	BigMenuContainer,
	MenuContainer,
	Dot,
	MenuText,
	Container,
	Percentage,
	Svg,
} from "./style";

interface IProps {
  isWallet?: boolean
	styleMenuText?: any
	squareEnd?: boolean
	radius?: number
	strokeWidth?: number
	valueArray?: any
	noLegend?: boolean
}

export default function ProgressCircle({
	isWallet = false,
	styleMenuText = {},
	squareEnd = false,
	radius = 120,
	strokeWidth = 10,
	valueArray = [],
	noLegend = false,
}: IProps) {
	const [values, setValues] = useState([]);
	const circleRef = useRef(null);
	const [rates, setRates] = useState([]);
	const halfCircle = radius + strokeWidth / 2;
	const circleCircunference = 2 * Math.PI * radius;
	const colorList = ['#3E788A', "#2A76E4", '#FA9C10'];

	useEffect(() => {
		if (!valueArray) return;
		setValues(valueArray);
		setRates(valueArray);
		if (isWallet) {
			const arr: any = valueArray;
			const sort = arr.map((item: any, i: number) => {
				switch (i) {
					case 0:
						return {
							...item,
							rate: item.rate + arr[1].rate + arr[2].rate,
						};
					case 1:
						return {
							...item,
							rate: item.rate + arr[2].rate,
						};
					case 2:
						return item;
					default:
						return item;
				}
			});
			setRates(sort);
		}
	}, [valueArray, isWallet]);

	return (
		<Container>
			<Svg
				transform="rotate(-180)"
				width={radius * 2}
				height={radius * 2}
				viewBox={`0 0 ${halfCircle * 2} ${halfCircle * 2}`}
				radius={radius}
			>
				<g origin={`${halfCircle}, ${halfCircle}`}>
					{/* CIRCULO CINZA BASE */}
					<circle
						cx="50%"
						cy="50%"
						stroke={"#EEEEEE"}
						strokeWidth={strokeWidth}
						r={radius}
						fill="transparent"
					/>

					{/* CIRCULO DO PRIMEIRO GRÁFICO COM A PONTA QUADRADA */}
					{squareEnd && (
						<circle
							origin={`${halfCircle}, ${halfCircle}`}
							// rotation={-3}
							cx="50%"
							cy="50%"
							stroke={valueArray[valueArray.length - 1].color}
							strokeWidth={strokeWidth}
							r={radius}
							fill="transparent"
							strokeDasharray={circleCircunference}
							strokeDashoffset={circleCircunference * 0.99}
						/>
					)}
					{rates.map((item: any, i: number) => {
						return (
							<circle
								ref={circleRef}
								key={`circle_${i}`}
								cx="50%"
								cy="50%"
								stroke={item.color}
								strokeWidth={strokeWidth}
								r={radius}
								fill="transparent"
								strokeDasharray={circleCircunference}
								strokeDashoffset={circleCircunference * (1 - item.rate)}
								strokeLinecap="round"
							/>
						);
					})}
				</g>
			</Svg>
			{!noLegend && (
				<BigMenuContainer>
					{!isWallet
						? [...values].reverse().map((item: any, i: number) =>
								item.title ? (
									<MenuContainer key={`legend_${i}`}>
										<Dot backgroundColor={item.color || colorList[i]}></Dot>
										<MenuText style={styleMenuText}>{item.title}</MenuText>
									</MenuContainer>
								) : null,
						  )
						: [...values].reverse().map((item: any, i: number) =>
								item.title ? (
									<MenuContainer key={`legend_${i}`}>
										<Percentage color={item.color || colorList[i]}>
											{Math.floor(item.rate * 100)}%
										</Percentage>
										<MenuText style={styleMenuText}>{item.title}</MenuText>
									</MenuContainer>
								) : null,
						  )}
				</BigMenuContainer>
			)}
		</Container>
	);
}
