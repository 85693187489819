import { AccountStatus } from './style'

interface IAccountStatus {
  status: string
  type?: string | boolean
  color: string
  label?: string 
}

export default function AccountStatusComponent(props: IAccountStatus) {
  const { status, type, color, label } = props
  return (
    <AccountStatus {...props}>
      {type && (
        <div>
          {label ? label : type === 'pf' ? 'Conta Pessoa Física' : 'Conta Pessoa Jurídica'}
        </div>
      )}
      <div className="row">
        <div className="ball-icon" style={{ background: color }} />
        {status || ''}
      </div>
    </AccountStatus>
  )
}
