import { Card, PageTitle } from 'components'
import icons from 'helper/icons'
import { IObject } from 'interfaces'
import { toCapitalizeCase } from 'helper/utils'
import useFetch from 'hooks/useFetch'
import { useEffect, useState } from 'react'
import { SubContentContainer, FlexContainer, CardsWrap } from '../style'

export default (props: IObject) => {
  const { id } = props

  const [residentsData, setResidentsData] = useState([])
  const [inquilino, setInquilino] = useState<IObject>()
  const [corresponsavel, setCorresponsavel] = useState<IObject>()

  const api = useFetch(`/product/aluguel-verde/contract/residents?id=${id}`)

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const r = await api.fetch('get')
        if (r.fail) throw new Error()
        const { response } = r.data
        setInquilino(response.lodger)

        // corresponsavel
        const [corresp] = response.residentList.filter((resident: any) => {
          return resident.isCoresponsible
        })
        setCorresponsavel(corresp)

        // residentes
        const residentes = response.residentList.filter((resident: any) => {
          return !resident.isCoresponsible
        })
        residentes.shift()
        setResidentsData(residentes)
      } catch (e) {
        console.error(e)
      }
    }
    fetchUsers()
  }, [])

  return (
    <SubContentContainer>
      <CardsWrap subcard>
        <FlexContainer>
          <img className="icon" src={icons.clientOutlined} alt="card icon" />
          <PageTitle>Dados do inquilino</PageTitle>
        </FlexContainer>
        <Card
          inverted
          variation={''}
          value="Nome"
          icon="mailOutlined"
          blackIcon
          detailsCard
          large
          withoutMarginLeft
        >
          {inquilino?.name
            ? `${toCapitalizeCase(inquilino.name)} ${toCapitalizeCase(
                inquilino.lastName
              )}`
            : '--'}
        </Card>
        <Card
          inverted
          variation={''}
          value="CPF"
          icon="mailOutlined"
          blackIcon
          large
          detailsCard
          withoutMarginLeft
        >
          {inquilino?.documentNumber || '--'}
        </Card>
        <Card
          inverted
          variation={''}
          value="Data de nascimento"
          icon="calendarSapiensOutlined"
          blackIcon
          detailsCard
          large
        >
          {inquilino?.birthday || '--'}
        </Card>
        <Card
          inverted
          variation={''}
          value="E-mail"
          icon="mailOutlined"
          blackIcon
          large
          detailsCard
          withoutMarginLeft
        >
          {inquilino?.email || '--'}
        </Card>
        <Card
          inverted
          variation={''}
          value="Telefone"
          icon="phoneOutlined"
          blackIcon
          detailsCard
          large
        >
          {inquilino?.phoneNumber || '--'}
        </Card>
        {residentsData?.map((resident: any, i: number) => {
          return (
            <>
              <FlexContainer>
                <img
                  className="icon"
                  src={icons.clientOutlined}
                  alt="card icon"
                />
                <PageTitle>{`Morador ${i + 1}`}</PageTitle>
              </FlexContainer>
              <Card
                inverted
                variation={''}
                value="Nome"
                icon="mailOutlined"
                blackIcon
                large
                detailsCard
                withoutMarginLeft
              >
                {resident.name ? toCapitalizeCase(resident.name) : '--'}
              </Card>
              <Card
                inverted
                variation={''}
                value="CPF"
                icon="mailOutlined"
                blackIcon
                large
                detailsCard
                withoutMarginLeft
              >
                {resident?.documentNumber || '--'}
              </Card>
              <Card
                inverted
                variation={''}
                value="Data de nascimento"
                icon="calendarSapiensOutlined"
                blackIcon
                large
                detailsCard
                withoutMarginLeft
              >
                {resident?.birthday || '--'}
              </Card>
              <Card
                inverted
                variation={''}
                value="E-mail"
                icon="mailOutlined"
                blackIcon
                large
                detailsCard
                withoutMarginLeft
              >
                {resident?.mainEmail || '--'}
              </Card>
              <Card
                inverted
                variation={''}
                value="Telefone"
                icon="calendarSapiensOutlined"
                blackIcon
                large
                detailsCard
                withoutMarginLeft
              >
                {resident?.mainPhone || '--'}
              </Card>
            </>
          )
        })}
        <FlexContainer>
          <img className="icon" src={icons.clientOutlined} alt="card icon" />
          <PageTitle>Corresponsável</PageTitle>
        </FlexContainer>
        <Card
          inverted
          variation={''}
          value="Nome"
          icon="mailOutlined"
          blackIcon
          large
          detailsCard
          withoutMarginLeft
        >
          {corresponsavel?.name ? toCapitalizeCase(corresponsavel.name) : '--'}
        </Card>
        <Card
          inverted
          variation={''}
          value="CPF"
          icon="mailOutlined"
          blackIcon
          large
          detailsCard
          withoutMarginLeft
        >
          {corresponsavel?.documentNumber || '--'}
        </Card>
        <Card
          inverted
          variation={''}
          value="Data de nascimento"
          icon="calendarSapiensOutlined"
          blackIcon
          large
          detailsCard
          withoutMarginLeft
        >
          {corresponsavel?.birthday || '--'}
        </Card>
        <Card
          inverted
          variation={''}
          value="E-mail"
          icon="mailOutlined"
          blackIcon
          large
          detailsCard
          withoutMarginLeft
        >
          {corresponsavel?.mainEmail || '--'}
        </Card>
        <Card
          inverted
          variation={''}
          value="Telefone"
          icon="calendarSapiensOutlined"
          blackIcon
          large
          detailsCard
          withoutMarginLeft
        >
          {corresponsavel?.mainPhone || '--'}
        </Card>
      </CardsWrap>
    </SubContentContainer>
  )
}
