import {
  PageTitle,
  PagePathing,
  TextArea,
  ButtonNext,
} from 'components'
import { Column, Row } from 'styles/index'
import { Form } from '@unform/web'

import { Subtitles, FormDiv } from './style'
import { useContext, useRef, useEffect, useState } from 'react'
import useFetch from 'hooks/useFetch'
import { SapiensContext } from 'context/sapiens'
import Factory, { IObject, IListArray } from './factory'

// const handleNext = () => {}

export default function ({ handleNext, handleBack }: any) {
  const { newCaption, setNewCaption } = useContext(SapiensContext)
  const api = useFetch('/index-list');
  const [cList, setCList] = useState<IListArray>(Factory.cardList())
  const iterator = useRef(0)
  const elementList = useRef<IObject>({})
  const factory = new Factory(newCaption)

  const handleText = (text: string, key: any) => {
    elementList.current[key] = text
    // console.log(elementList.current.length)
    const details = Object
      .entries(elementList.current)
      .sort((a, b) => {
        const [ka] = a, [kb] = b;
        return Number(ka.replace(/\D/g, '')) - Number(kb.replace(/\D/g, ''));
      })
      .map(([_, value]) => value);

    setNewCaption((cur: IObject) => ({
      ...cur,
      details, 
    }));
  }
  
  useEffect(() => {
    api.fetch('get').then((r: IObject) => {
      if (r.status !== 200) throw new Error();
      factory.indexList = r.data.response;

      const c = Factory.cardList().map((card: IObject) => {
        return {
          title: card.title,
          cards: card.cards.map(([title, key]: any) => [
            title,
            key ? factory.getFormated(key) : null,
          ])
        }
      })
      setCList(c);
    })
  }, []);

  return (
    <>
      <PagePathing
        withoutMarginLeft
      >
        {`Gestão de produtos >  Investimentos  > Nova captação  > Resumo da captação > Detalhes`}
      </PagePathing>
      <PageTitle withoutMarginLeft>Texto de Detalhes da Nova captação</PageTitle>
      <Row gap={90} alignStart>
        <FormDiv>
          <Form onSubmit={handleNext}>
            <Column gap={15}>
              {
                cList.map((card: any, i: number) => {
                  const { title, cards } = card;
                  iterator.current = 0;
                  return (
                    <div key={`sub_${i}`}>
                      <Subtitles>{title}</Subtitles>
                      {
                        new Array(Math.ceil(cards.length / 3))
                          .fill(null)
                          .map((_, j) => (
                            <Row key={`row_${i}${j}`} gap={24} noMarginLeft>
                              {
                                new Array(3).fill(null).map(() => {
                                  const card = cards[iterator.current++];
                                  const key = `card_${i}_${j}_${iterator.current}`;
                                  if (card) {
                                    elementList.current[key] = elementList.current[key] || null;
                                    return (
                                      <TextArea
                                        key={key}
                                        onChange={(text: string) => handleText(text, key)}
                                        title={card[0]}
                                        tag={card[1]}
                                        value={elementList.current[key]}
                                        style={{ marginTop: 16 }}
                                      />
                                    )
                                  }
                                  return <></>
                                })
                              }
                            </Row>
                          ))
                      }
                    </div>
                  )
                })
              }
            </Column>
          </Form>
        </FormDiv>
      </Row>
      <ButtonNext onClick={handleNext} alsoBack onClickBack={handleBack}/>
    </>
  )
}
