export const assembleBody = ({ newCaption, selected }: any) => {
  const  {
    fullName: name,
    tick,
    name: fonetic,
    supply,
    garantia: { flag: guarantee},
    cetip: { value },
    impostoRenda: { value: irType },
    coatizationPre: { num: cotizationPre},
    periodoLanc: {num: icoDays},
    coatizationPos: { num: burnAt },
    prazo: {num: deadline},
    preview64Img: image,
    price,
    profitability,
    pagamentoJuros: { num: recurrence},
    liquidez: { value: paymentType},
    cupom: { value: cValue},
    details,
    color,
    secondColor,
    imageOpacity,
    doneIcon: icon,
    contratoCCB,
    alienFiduciaria,
    avalSocios,
    contratoCDB,
    minimumInvest: minInvest
  } = newCaption
  return {
    minInvest,
    name,
    tick,
    fonetic,
    icoDays,
    supply,
    guarantee,
    CETIP: value === 0,
    irType,
    cotizationPre,
    burnAt,
    deadline,
    clientList: [],
    price,
    profitability,
    recurrence,
    paymentType: 1,
    liquidity: paymentType,
    hasCupom: cValue === 0,
    details,
    contractList: [contratoCCB || null, alienFiduciaria || null, avalSocios || null, contratoCDB || null],
    style: {
      iconList:[icon],
      color: [color, secondColor],
      image: {
        base64: image,
        opacity: Number(imageOpacity) / 100,
      }
    }
  }

}

