import { icons } from 'helper'
import { IIcons } from 'helper/icons'
import { Card } from './style'

interface ICardProps {
  children: string
  icon?: keyof IIcons
  green?: boolean
  secondary?: boolean
  disabled?: boolean
  onClick?: any
  boldTitle?: boolean
  id?: string
}

export default function InsuranceCard(props: ICardProps) {
  const { children, icon, green } = props
  return (
    <Card {...props}>
      <img
        className="selectIcon"
        src={green ? icons.checkOutlined : icons.vectorOutlined}
        alt="select icon"
      />
      {icon && (
        <img className="iconClickable" src={icons[icon]} alt="card icon" />
      )}
      <span className="title">{children}</span>
    </Card>
  )
}
