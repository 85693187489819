import { Card } from 'components'
import { SapiensContext } from 'context'
import { IIcons } from 'helper/icons'
import { useFetch } from 'hooks'
import { useContext, useEffect, useState } from 'react'
import { Content, CardsContainer } from 'styles'

interface IDashboardResponse {
  bannerUrl?: string
  cardList: {
    icon: keyof IIcons
    size: string
    value: string
    label: string
    variation: string
    backgroundColor?: string
  }[]
}

export default function Dashboard() {
  const { extended, setCardsInfo } = useContext(SapiensContext)

  const [dashboardInfo, setDashboardInfo] = useState<IDashboardResponse>({
    cardList: [],
  })

  const api = useFetch('dashboard/resume')

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await api.fetch('get')
        if (response.fail) throw new Error(response.errors.message)
        setDashboardInfo(response.data.response)
        const infoGestaoClientes = response.data.response.cardList.reduce(
          (obj: any, item: any) => {
            if (item.label === 'Número de clientes') {
              obj.clientNumber = item
            } else if (item.label === 'Capital sob gestão') {
              obj.money = item
            } else if (item.label === 'Capital sob custódia') {
              obj.custody = item
            }
            return obj
          },
          {}
        )
        setCardsInfo(infoGestaoClientes)
      } catch (err) {
        console.log(err)
      }
    }

    fetchCards()
  }, [])

  return (
    <Content extended={extended}>
      <CardsContainer width={'90%'}>
        {dashboardInfo?.cardList.map((card) => {
          return (
            <Card
              key={card.label}
              variation={card.variation}
              value={card.value}
              green={card.backgroundColor === 'green'}
              secondary={card.backgroundColor === 'black'}
              extraWide={card.size === 'l'}
              icon={card.icon}
            >
              {card.label}
            </Card>
          )
        })}
      </CardsContainer>
    </Content>
  )
}
