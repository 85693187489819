import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
  useContext,
} from 'react'
import {
  PagePathing,
  PageTitle,
  Card,
  ClickableCard,
  AccountStatus,
  ButtonNext,
} from 'components'
import { IObject } from 'interfaces'
import { SapiensContext } from 'context/sapiens'
import useFetch from 'hooks/useFetch'
import { timestampToDate } from 'helper/utils'
import AluguelVerde from './AluguelVerde'
import DocumentsMenu from './DocumentsMenu'
import HistoryMenu from './HistoryMenu'
import InvestmentESG from './InvestmentESG'
import { CardsWrap, Wrapper } from './style'

// PAGES

import Pages from './Pages'

interface IDetalhesModal {
  userId?: string | null
  setExtendedMenu: Dispatch<SetStateAction<ReactElement | boolean>>
}

interface IPage {
  inicial: string
  produtos: string
  information: string
  documents: string
  aluguelVerde: string
}

interface IData {
  [key: string]: any
}

const CONTAINER_WIDTH = 80

export default function DetalhesModal(props: IDetalhesModal) {
  const { userId, setExtendedMenu } = props
  const { setBuffer, buffer } = useContext(SapiensContext)
  const [data, userData] = useState<IData>({})
  const [page, setPage] = useState<string>('inicial')

  const userApi = useFetch(`user/${userId}`)

  const inicial = () => (
    <CardsWrap width={`${CONTAINER_WIDTH}%`}>
      <Card
        value={data?.assets?.formatedBalanceMoney || 'R$ 0,00'}
        wide
        icon="coinOutlined"
      >
        Saldo da conta digital
      </Card>
      <Card
        wide
        value={data?.assets?.formatedBalanceESG || 'R$ 0,00'}
        icon="esgOutlined"
      >
        Investimentos
      </Card>
      <Card
        value={data?.assets?.formatedBalanceSBC || '0.00'}
        icon="leafSolid"
        detailsCard
      >
        Saldo de sapienscoin
      </Card>
      <Card
        value={
          data?.assets?.criptoWallet?.length
            ? String(data?.assets?.criptoWallet?.length)
            : '0'
        }
        icon="cryptoSolid"
        detailsCard
      >
        Criptoativos
      </Card>
      <ClickableCard
        green
        onClick={() => setPage('produtos')}
        icon="leafOutlined"
      >
        Produtos
      </ClickableCard>
      <ClickableCard
        green
        icon="dataDetailsOutlined"
        onClick={() => setPage('information')}
      >
        Informações
      </ClickableCard>
      {data?.step?.id !== '7f' ? (
        <ClickableCard disabled icon="statementOutlined">
          Extrato
        </ClickableCard>
      ) : (
        <ClickableCard
          icon="statementOutlined"
          green
          onClick={() => {
            setExtendedMenu(
              <HistoryMenu
                id={data?.id}
                userType={data?.userType}
                name={data?.name}
                status={data?.step?.name}
                color={data?.step?.color}
              />
            )
          }}
        >
          Extrato
        </ClickableCard>
      )}
    </CardsWrap>
  )

  const produtos = () => (
    <CardsWrap width={`${CONTAINER_WIDTH}%`}>
      {data?.assets?.productList?.oneEarth ? (
        <ClickableCard
          onClick={() => {
            setExtendedMenu(
              <InvestmentESG id={data?.id} type={data?.userType} />
            )
          }}
          icon="esgOutlined"
          green
        >
          Investimentos ESG
        </ClickableCard>
      ) : (
        <ClickableCard icon="esgOutlined" disabled>
          Investimentos ESG
        </ClickableCard>
      )}
      {data?.assets?.productList?.AluGreen ? (
        <ClickableCard
          green
          icon="houseGreenOutlined"
          onClick={() => {
            const AluGreen = data?.assets?.productList?.AluGreen
            if (!buffer.AluGreen) {
              setBuffer((b: IObject) => ({ ...b, AluGreen }))
            }
            setPage('aluguelVerde')
          }}
        >
          Aluguel Verde
        </ClickableCard>
      ) : (
        <ClickableCard disabled icon="houseGreenOutlined">
          Aluguel Verde
        </ClickableCard>
      )}
      <ClickableCard icon="loanOutlined" disabled>
        Empréstimos
      </ClickableCard>
      <ClickableCard icon="cryptoOutlined" disabled>
        Criptoativos
      </ClickableCard>
      <ClickableCard icon="billOutlined" disabled>
        Pagar boletos
      </ClickableCard>
      <ClickableCard icon="creditCardOutlined" disabled>
        Cartão virtual
      </ClickableCard>
    </CardsWrap>
  )

  const information = () => (
    <CardsWrap>
      <Card
        medium
        blackIcon
        plain
        icon="calendarOutlined"
        value={data?.createdAt && timestampToDate(new Date(data.createdAt))}
      >
        Data de criação
      </Card>
      <Card
        medium
        blackIcon
        plain
        icon="calendarSapiensOutlined"
        value={
          data?.assets?.createdAt
            ? timestampToDate(new Date(data?.assets?.createdAt))
            : '--'
        }
      >
        Data de ativação
      </Card>
      <Card
        medium
        tooltip
        blackIcon
        plain
        icon="clientOutlined"
        value={data?.document || '--'}
        wide
      >
        {data?.userType === 'pf' ? 'CPF' : 'CNPJ'}
      </Card>
      <Card
        medium
        blackIcon
        plain
        icon="calendarClientOutlined"
        value={
          data?.birthday ? timestampToDate(new Date(data?.birthday)) : '--'
        }
      >
        Data de nascimento
      </Card>
      <Card
        medium
        blackIcon
        plain
        icon="locationOutlined"
        value={
          data?.address ? `${data?.address.city}/${data?.address.state}` : '--'
        }
      >
        Cidade
      </Card>
      <Card
        wide
        blackIcon
        plain
        icon="mailOutlined"
        tooltip
        value={
          data?.address
            ? `${data?.address.street}, ${data?.address.number}
            B. ${data?.address.district}`
            : '--'
        }
      >
        Endereço
      </Card>
      <Card
        medium
        tooltip
        blackIcon
        plain
        icon="locationOutlined"
        value={data?.address?.complement || '--'}
      >
        Complemento
      </Card>
      <Card
        medium
        green
        blackIcon
        plain
        icon="folderOutlined"
        value={''}
        onClick={() =>
          setExtendedMenu(<DocumentsMenu isPF={data?.userType === 'pf'} />)
        }
      >
        Documentos
      </Card>

      <Card
        wide
        blackIcon
        plain
        icon="phoneOutlined"
        value={data?.phone || '--'}
      >
        Telefone
      </Card>
      <Card
        wide
        blackIcon
        plain
        icon="whatsOutlined"
        value={data?.phone || '--'}
      >
        Whatsapp
      </Card>
      <Card
        wide
        tooltip
        blackIcon
        plain
        icon="mailOutlined"
        value={data?.email || '--'}
      >
        Email
      </Card>
    </CardsWrap>
  )

  const pageLists: { [key: string]: ReactElement } = {
    inicial: inicial(),
    produtos: produtos(),
    information: information(),
    aluguelVerde: <AluguelVerde setExtendedMenu={setExtendedMenu} />,
  }

  useEffect(() => {
    setExtendedMenu(false)
  }, [page])

  useEffect(() => {
    userApi.fetch('get').then((res) => {
      if (!res.data.response) return
      userData(res.data.response)
      setBuffer((b: IObject) => ({
        userId,
        chatUser: res.data.response,
        chatRoom: 'cg',
        ...b,
      }))
    })
  }, [])

  const pathingLists: { [key: string]: string } = {
    inicial: 'Detalhes do Cliente',
    produtos: 'Detalhes do Cliente > Produtos',
    information: 'Detalhes do Cliente > Informações',
  }

  const handlePageChange = () => {
    switch (page) {
      case 'aluguelVerde':
        return setPage('produtos')
      default:
        return setPage('inicial')
    }
  }

  return (
    <Wrapper>
      <div className="content-wrapper">
        <PagePathing withoutMarginLeft>
          { /* <Pages setPage={setPage} setExtendedMenu={setExtendedMenu} /> */ }
        </PagePathing>
        <PageTitle detailsMargin withoutMarginLeft>
          {data?.name || '--'}
        </PageTitle>
        <div className="margem">
          <AccountStatus
            status={data?.step?.name}
            type={data.userType}
            color={data?.step?.color}
          />
        </div>
        {pageLists[page]}
      </div>
      {page !== 'inicial' && (
        <div className="align-self">
          <ButtonNext isBack onClick={() => handlePageChange()} />
        </div>
      )}
    </Wrapper>
  )
}
