import { useContext, useEffect, useState } from 'react'
import { Kanban, Modal } from 'components'
import { SapiensContext } from 'context'
import useFetch from 'hooks/useFetch'
import useSocket from 'hooks/useSocket'
import { IKanbanColumns, IKanbanData, IObject } from 'interfaces'
import Details from './Details'

export default function AluguelVerde() {
  const [extendedMenuData, setExtendedMenuData] = useState<any>(false)
  const [kanbanData, setKanbanData] = useState<IKanbanData>({})
  const [filteredData, setFilteredData] = useState({})
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selected, setSelected] = useState<IObject>()

  const userApi = useFetch(`product/aluguel-verde?type=coverage`)

  const { searchParam, socket, setSocket } = useContext(SapiensContext)

  useEffect(() => {
    userApi.fetch('get').then((res: { [key: string]: any }) => {
      if (res.status === 200) return setKanbanData(res.data.response)
      return false
    })
  }, [])

  useEffect(() => {
    setFilteredData(kanbanData)
  }, [kanbanData])

  useEffect(() => {
    const shownData = Object.entries(kanbanData).reduce(
      (obj: { [key: string]: IKanbanColumns }, [columnId, { items }]) => {
        obj[columnId] = {
          ...kanbanData[columnId],
          items: items.filter(
            (item) =>
              item.name.includes(searchParam) ||
              item.cpf.includes(searchParam) ||
              item.email.includes(searchParam)
          ),
        }
        return obj
      },
      {}
    )
    setFilteredData(shownData)
  }, [searchParam])

  useSocket((io: IObject) => {
    if (!setSocket) return
    setSocket(io)
    io.on('adm_refresh_rental', () => {
      userApi.fetch('get').then((res: { [key: string]: any }) => {
        if (res.status === 200) return setKanbanData(res.data.response)
        return false
      })
    })
  }, socket)

  const onDropCard = async (id: string, col: number) => {
    userApi
      .fetch('put', { id, status: kanbanData[col].id })
      .then((res: { [key: string]: any }) => {
        if (res.status === 200) setKanbanData(res.data.response)
      })
      .finally(() => {
        socket?.emit('refresh', { command: 'av_rental' })
      })
  }

  const onCardClick = async (item: { [key: string]: any }) => {
    try {
      setSelected(item)
      setShowModal(true)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Kanban
        data={filteredData}
        setData={setKanbanData}
        onDropCard={onDropCard}
        onCardClick={onCardClick}
        withoutIcon
        noIconCards
      />
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        extendedMenuData={extendedMenuData}
        FS
      >
        <Details
          selected={selected}
          setExtendedMenuData={setExtendedMenuData}
        />
      </Modal>
    </>
  )
}
