export const options = {
  modalCaption: [
    { label: 'Captação aberta', value: 0 },
    // { label: 'Captação fechada', value: 1 },
  ],
  tipoInvest: [
    { label: 'CDB', value: 0 },
    { label: 'CCB', value: 1 },
    { label: 'SPE', value: 2 },
    { label: 'Token', value: 3 },
  ],

  garantia: [
    { label: 'CDB - FGC', value: 0, flag: 'CDB - FGC' },
    { label: 'Aval dos sócios', value: 1,  flag: 'Aval sócios' },
    { label: 'Ativos da empresa', value: 2, flag: 'Ativos' },
    { label: 'Não possui', value: 3, flag: null},
  ],

  cetip: [
    { label: 'Sim', value: 0 },
    { label: 'Não', value: 1 },
  ],

  periodoLanc: [
    { label: '7 dias', value: 0, num: 7 },
    { label: '15 dias', value: 1, num: 15 },
    { label: '30 dias', value: 2, num: 30 },
    { label: '60 dias', value: 3, num: 60 },
    { label: '90 dias', value: 4, num: 90 },
  ],

  prazo: [
    { label: '15 meses', value: 0, num: 15 },
    { label: '24 meses', value: 1, num: 24 },
    { label: '36 meses', value: 2, num: 36 },
    { label: '48 meses', value: 3, num: 48 },
  ],

  indexador: [
    { label: 'Pré fixado (Basic)', value: 0, flag: 'Pré-fixado' },
    { label: 'Pós fixado (Pro)', value: 1, flag: 'Pós-fixado' },
    { label: 'Híbrido (Pro)', value: 2, flag: 'Híbrido' },
  ],
  coatizationPre: [
    { label: '1 dia', value: 0, num: 1 },
    { label: '5 dias', value: 1,num: 5 },
    { label: '10 dias', value: 2, num: 10 },
    { label: '15 dias', value: 3,  num: 15},
    { label: '30 dias', value: 4, num: 30 },
    { label: '60 dias', value: 5, num: 60 },
    { label: '90 dias', value: 6, num: 90 },
  ],
  coatizationPos: [
    { label: '1 dia', value: 0, num: 1 },
    { label: '15 dias', value: 1, num: 15 },
    { label: '30 dias', value: 2, num: 30 },
    { label: '60 dias', value: 3, num: 60 },
    { label: '90 dias', value: 5, num: 90 },
  ],
  cupom: [
    { label: 'Aceita todos os cupons', value: 0 },
    // { label: 'Aceita Cupons específicos', value: 1 },
    { label: 'Não aceita cupom', value: 1 },
  ],
  liquidez: [
    { label: 'Diária', value: 3 },
    { label: 'Anual', value: 2 },
    { label: 'No vencimento', value: 0 },
  ],
  juros: [
    { label: 'Diária', value: 0 , num: 'd'},
    { label: 'Mensal', value: 1, num: 'm' },
    // { label: 'Trimestral', value: 2, num: 't' },
    { label: 'Semestral', value: 2, num: 'sm' },
    { label: 'Anual', value: 3, num: 'a' },
    { label: 'No Vencimento', value: 4, num: 'v' },
  ],
  ir: [
    { label: 'Descontado na fonte', value: 0 },
    { label: 'Personal', value: 1 },
  ],

}
