import styled from 'styled-components'
import { ITheme } from 'styles/Themes'

interface IStyleProps {
  theme: ITheme
  secondary?: boolean
  disabled?: boolean
  green?: boolean
  wide?: boolean
  extraWide?: boolean
  value?: string
  inverted?: boolean
  variation?: string
  plain?: boolean
  blackIcon?: boolean
  medium?: boolean
  large?: boolean
  greenIcon?: boolean
  detailsCard?: boolean
  withoutMarginLeft?: boolean
}

export default styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: end;
  height: 142px;
  width: ${(props: IStyleProps) => {
    if (props.extraWide) return '413px'
    if (props.wide) return '315px'
    if (props.large) return '305px'
    if (props.medium) return '204px'
    return '196px'
  }};

  padding-bottom: 22px;
  border-radius: 5px;
  box-shadow: 0px 3px 4px 0px #00000040;

  background: ${(props: IStyleProps) => {
    if (props.green && Number(props.value) === 0)
      return `${props.theme.card.backgroundColor.green}`
    if (props.green) return `${props.theme.card.backgroundColor.green}`
    if (props.secondary) return `${props.theme.card.backgroundColor.secondary}`
    return `${props.theme.card.backgroundColor.primary}`
  }};

  cursor: ${(props: IStyleProps) => {
    if (props.disabled || !props.green) return ''
    return 'pointer'
  }};

  user-select: none;

  .icon {
    align-self: center;
    justify-content: center;
    filter: ${(props: IStyleProps) => {
      if (props.green) return `${props.theme.card.icon.green}`
      if (props.disabled) return `${props.theme.card.icon.disabled}`
      if (props.secondary) return `${props.theme.card.icon.secondary}`
      if (props.greenIcon) return ''
      if (props.blackIcon)
        return 'invert(0%) sepia(17%) saturate(2041%) hue-rotate(154deg) brightness(82%) contrast(105%)'
      return `${props.theme.card.icon.primary}`
    }};

    max-width: 25px;
    height: 25px;

    margin: ${(props: IStyleProps) => {
      if (props.wide && !props.plain) return '0 0 12px 25px'
      if (props.detailsCard) return '0 0 12px 25px'
      return '0 0 12px 25px'
    }};
  }

  .value {
    display: block;
    margin-left: 10px;
    overflow-x: hidden;
    text-overflow: ellipsis !important;
    font-size: ${(props: IStyleProps) => {
      if (props.inverted && props.wide) return '16px'
      if (props.inverted) return '18px'
      if (props.plain && props.wide) return '18px'
      if (props.wide || props.detailsCard) return '22px'
      return '18px'
    }};
    font-style: normal;
    font-weight: ${(props: IStyleProps) => {
      if (props.inverted) return '300'
      if (props.plain) return '300'
      return '700'
    }};
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: left;
    height: auto;

    color: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.fontColor.disabled}`
      if (props.secondary) return `${props.theme.card.fontColor.secondary}`
      if (props.green) return `${props.theme.card.fontColor.green}`
      return `${props.theme.card.fontColor.primary}`
    }};
  }

  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: ${(props: IStyleProps) => {
      if (props.inverted || props.plain) return '700'
      return '400'
    }};
    height: auto;
    line-height: 18px;
    text-align: left;
    margin: ${(props: IStyleProps) => {
      if (props.plain || (props.plain && props)) return '12px 0 0 0'
      return '0 10px 0 0'
    }};
    padding-left: 25px;

    color: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.fontColor.disabled}`
      if (props.secondary) return `${props.theme.card.fontColor.secondary}`
      if (props.green) return `${props.theme.card.fontColor.green}`
      return `${props.theme.card.fontColor.primary}`
    }};
  }

  .ribbon {
    position: absolute;
    top: -4px;
    left: -4px;
    width: 57px;
    height: 60px;
  }

  .variation {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    height: auto;
    /* line-height: 12px; */
    letter-spacing: 0.01em;
    text-align: left;

    color: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.variation.disabled}`
      if (props.secondary) return `${props.theme.card.variation.secondary}`
      if (props.green) return `${props.theme.card.variation.green}`
      return `${props.theme.card.variation.primary}`
    }};

    margin: ${(props: IStyleProps) => {
      if (props.wide || props.detailsCard) return '6px 0 0 25px'
      return '12px 0 0 25px'
    }};
  }

  .flex {
    display: flex;
    align-items: baseline;
    margin-right: 22px;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    margin-right: 0;
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    font-size: 12px;
    visibility: hidden;
    width: auto;
    background-color: #000000;
    color: #fff;
    margin: 0;
    text-align: center;
    padding: 5px 5px;
    border-radius: 6px;

    /* Position the tooltip text */
    top: 135%;
    left: 50%;
    margin-left: -60px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
  }

  /* Tooltip arrow */
  .tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
`
