import icons, { IIcons } from 'helper/icons'
import { Card, Row } from './style'

interface IHistoryCardProps {
  icon: keyof IIcons
  signal: string
  value: string
  label: string
  date: string
}

export default (props: IHistoryCardProps) => {
  const { icon, signal, value, label, date } = props

  const newDate = () => {
    try {
      const [dt = ''] = date.split('T')
      const [_, month, day] = dt.split('-')
      return `${day}/${month}`
    } catch (err) {
      console.log('ERRO', date)
      return '00/00/0000'
    }
  }

  const newHour = () => {
    try {
      const dt = new Date(date)
      const hour = dt.getHours() < 10 ? `0${dt.getHours()}` : dt.getHours()
      const min = dt.getMinutes() < 10 ? `0${dt.getMinutes()}` : dt.getMinutes()
      return `${hour}:${min}`
    } catch (err) {
      console.log('ERRO', date)
      return '00:00'
    }
  }

  return (
    <Card signal={signal}>
      <Row>
        <img className="icon" src={icons[icon]} alt="history-icon" />
        <p className="value">{`${signal}${value}`}</p>
      </Row>
      <p>{`${newDate()} às ${newHour()}`}</p>
      <p className="bold">{label}</p>
    </Card>
  )
}
