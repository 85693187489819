import styled from 'styled-components'

export const ContainerS = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input {
    margin-bottom: 50px;
  }
`
export const PageText = styled.p`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-top: 20px;
`
export const PageTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center !important;
`
