import { icons, ribbons } from 'helper'
import { IIcons } from 'helper/icons'
import { IRibbons } from 'helper/ribbons'
import Card from './style'

interface ICardProps {
  children: any
  variation?: string
  icon: keyof IIcons
  green?: boolean
  secondary?: boolean
  disabled?: boolean
  wide?: boolean
  extraWide?: boolean
  value: string
  inverted?: boolean
  plain?: boolean
  blackIcon?: boolean
  onClick?: any
  tooltip?: boolean
  ribbon?: keyof IRibbons
  showRibbon?: boolean
  medium?: boolean
  large?: boolean
  greenIcon?: boolean
  detailsCard?: boolean
  withoutMarginLeft?: boolean
}

export default function CardComponent(props: ICardProps) {
  const {
    children,
    icon,
    variation,
    value,
    inverted,
    tooltip,
    ribbon,
    showRibbon,
  } = props
  return (
    <Card {...props}>
      {showRibbon &&
        (ribbon ? (
          <img className="ribbon" src={ribbons[ribbon]} alt="ribbon" />
        ) : (
          <img className="ribbon" src={ribbons.crypto} alt="ribbon" />
        ))}
      <div className="flex">
        {icon && <img className="icon" src={icons[icon]} alt="card icon" />}
        {inverted ? (
          <span className="value">{
            typeof children === 'string' ? (
              children.length > 20 ? `${String(children).substring(0, 70)}...` : children
            ) : children
          }</span>
        ) : (
          <div className="tooltip">
            <span className="value">
              {typeof value === 'string' && value.includes('Ton')
                ? `${Number(value.split(' ')[0]).toFixed(2)} Ton`
                : String(value).length > 20
                ? `${String(value).substring(0, 20)}...` : value
              }
            </span>
            {tooltip && <span className="tooltiptext">{value}</span>}
          </div>
        )}
      </div>
      {inverted ? (
        <div className="tooltip">
          <span className="title">{value}</span>
          <span className="tooltiptext">
            {
              typeof children === 'string' ? children : value
            }
          </span>
        </div>
      ) : (
        <span className="title">{children}</span>
      )}
      {variation && <span className="variation">{variation}</span>}
    </Card>
  )
}
