import { SapiensContext } from 'context'
import { icons, images } from 'helper'
import useAuth from 'hooks/useAuth'
import { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Menu } from './style'
import Button from './Button'

export default function MenuComponent() {
  const { extended, setExtended } = useContext(SapiensContext)
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const [extendedIcon, setExtendedIcon] = useState(extended)

  // TODO - usar este meio para realizar o redirect quando o token expirar
  const handleLogout = () => {
    auth.signout(() => navigate('/login'))
  }

  const handleExtendMenu = () => {
    setExtended((oldValue) => !oldValue)
    setExtendedIcon((oldValue) => !oldValue)
  }

  return (
    <Menu extended={extended}>
      <img
        className="extendButton"
        src={icons.backGreenSolid}
        onClick={handleExtendMenu}
        onKeyDown={handleExtendMenu}
        alt="extend menu"
      />

      <div className="buttons">
        <div>
          {extendedIcon ? (
            <img src={images.logoMenu} alt="dashboard" className="logo" />
          ) : (
            <Button
              fixed
              icon="leafSolid"
              name="logo"
              extended={false}
              to="dashboard"
            />
          )}
          <Button
            extendedIcon={extendedIcon}
            extended={extended}
            icon="dashboardOutlined"
            name="dashboard"
            active={/dashboard/i.test(location.pathname)}
            to="dashboard"
            onClick={() => {
              setExtended(false)
              setExtendedIcon(false)
            }}
          >
            Dashboard
          </Button>
          <Button
            extendedIcon={extendedIcon}
            extended={extended}
            icon="clientOutlined"
            name="gestaoClientes"
            active={/gestaoClientes/i.test(location.pathname)}
            to="gestaoClientes"
          >
            Gestão de clientes
          </Button>
          <Button
            extendedIcon={extendedIcon}
            extended={extended}
            icon="leafOutlined"
            name="gestaoProdutos"
            active={/gestaoProdutos/i.test(location.pathname)}
            to="gestaoProdutos"
          >
            Gestão de produtos
          </Button>
          <Button
            extendedIcon={extendedIcon}
            extended={extended}
            icon="ticketBlack"
            name="gestaoCupons"
            active={/gestaoCupons/i.test(location.pathname)}
            to="gestaoCupons"
          >
            Gestão de Cupons
          </Button>
          <Button
            fixed
            extendedIcon={extendedIcon}
            extended={extended}
            icon="refreshOutlined"
            name="movimentacoes"
            active={/movimentacoes/i.test(location.pathname)}
            to="movimentacoes/saques"
          >
            Movimentações
          </Button>
          <Button
            extendedIcon={extendedIcon}
            extended={extended}
            icon="chatOutlined"
            name="Suporte"
            active={/suporte/i.test(location.pathname)}
            to="suporte/cg"
          >
            Suporte
          </Button>
        </div>
        <Button
          extended={extended}
          extendedIcon
          icon="logoutOutlined"
          name="Logout"
          onClick={handleLogout}
        >
          Log out
        </Button>
      </div>
    </Menu>
  )
}
