import styled from 'styled-components'


export const SubTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  text-align: left;
  line-height: 26px;
  margin-left: 10px;
`
interface IStyleProps {
  width?: string
}
export const CardsWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: ${(props: IStyleProps) => {
    if (props.width) return `${props.width}`
    return '100%'
  }};
  padding: 0 50px 10px 0;
  gap: 20px;
`

export const CardTitle = styled.h2`
  font-size: 16px;
  font-weight: 500;
  /* margin: 0; */
  margin-left: 10px;
`

export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70%;
  /* margin-bottom: 7%; */
  /* border: 1px solid red; */
  width: 100%;
  /* gap: 50px; */
  overflow-x: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: white;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }
`