import { icons } from 'helper'
import { IIcons } from 'helper/icons'
import Select, { StylesConfig } from 'react-select'
import { ResumeInputCard, TextDiv } from './style'

interface ICardProps {
  children: string
  icon?: keyof IIcons
  small?: boolean
  green?: boolean
  secondary?: boolean
  disabled?: boolean
  onClick?: any
  name?: string
  options?: any
  type?: any
  handleChange?: any
  value?: any
  isDoted?: boolean
}

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
})

export default function ResumeInputCardComponent(props: ICardProps) {
  const { name, children, icon, options, handleChange, disabled, isDoted } =
    props
  const selectStyle = {
    control: () => ({
      background: '#FDFDFD',
      display: 'inline-flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
    }),
    valueContainer: () => ({
      width: '60%',
      display: 'inline-flex',
      justifyContent: 'center',
    }),
    indicatorsContainer: () => ({
      width: '20%',
    }),
    dropdownIndicator: () => ({
      color: disabled ? '#9C948C' : '#070707',
    }),
    menu: (styles: any) => ({
      ...styles,
      zIndex: 212,
      backgroundColor: ' #fdfdfd',
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'Roboto',
      textAlign: 'left',
      boxSizing: 'border-box',
      borderRadius: '5px',
      maxWidth: '280px',
      border: '0 !important',
      boxShadow: '0 !important',
    }),
    option: (styles: any, { isFocused }: any) => ({
      ...styles,
      backgroundColor: isFocused ? '#F5F4F3' : '#fdfdfd',
      color: '#9C948C',
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'Roboto',
      height: '50px',
      maxWidth: '280px',
      border: 'none',
      paddingLeft: '20px',
      fontStyle: 'normal',
      fontWeight: '400',
      padding: '15px 30px',
      textAlign: 'left',
      overflow: 'hidden',
    }),
  }

  const colourStyles: StylesConfig = {
    container: (styles: any) => ({
      ...styles,
      width: '280px',
      marginLeft: '20px',
    }),
    indicatorsContainer: () => ({
      width: '20%',
    }),
    control: (styles: any) => ({
      ...styles,
      maxWidth: '280px',
      fontSize: '14px',
      lineHeight: '20px',
      backgroundColor: ' #fdfdfd',
      border: '0 !important',
      boxShadow: '0 !important',
      '&:hover': {
        border: '0 !important',
      },
    }),
    option: (styles: any, { data, isFocused }: any) => ({
      ...styles,
      backgroundColor: isFocused ? '#F5F4F3' : '#fdfdfd',
      color: '#9C948C',
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'Roboto',
      height: '50px',
      maxWidth: '280px',
      border: 'none',
      ...dot(data.color),
    }),
    menu: (styles: any) => ({
      ...styles,
      zIndex: 212,
      backgroundColor: ' #fdfdfd',
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'Roboto',
      textAlign: 'left',
      boxSizing: 'border-box',
      borderRadius: '5px',
      maxWidth: '280px',
      border: '0 !important',
      boxShadow: '0 !important',
    }),
    input: (styles: any) => ({ ...styles, ...dot() }),
    placeholder: (styles: any) => ({
      ...styles,
      ...dot(options?.find((item: any) => item.selected === true)?.color),
    }),
    singleValue: (styles: any, { data }: any) => ({
      ...styles,
      ...dot(data.color),
    }),
  }

  return (
    <ResumeInputCard {...props}>
      <Select
        name={name}
        onChange={(e) => handleChange(e)}
        options={options}
        placeholder={
          isDoted
            ? options?.find((item: any) => item.selected === true)?.label
            : ''
        }
        styles={isDoted ? colourStyles : selectStyle}
        isSearchable={false}
        isDisabled={disabled}
        {...props}
      />
      <TextDiv>
        {icon && (
          <img className="iconClickable" src={icons[icon]} alt="card icon" />
        )}
        <p>{name}</p>
      </TextDiv>
      <span className="title">{children}</span>
    </ResumeInputCard>
  )
}
