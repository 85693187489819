import { InputContainer } from 'styles'
import { useState, useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import { InputError, MaskedInput } from './style'
import { IMask } from 'react-imask'

interface IInputProps {
  rounded?: boolean
  small?: boolean
  xsmall?: boolean
  large?: boolean
  icon?: boolean
  error?: string
  marginRight?: number
  smallPad?: boolean
  name: string
  handleChange?: any
  initialError?: string
  placeholder?: string
  disabled?: boolean
  defaultValue?: string
  type: string
}

// const MaskedStyledInput = IMaskMixin(({ inputRef, ...props }) => (
//   <Input {...props} ref={inputRef} />
// ))

export default function MaskedInputComponent({
  name,
  initialError,
  handleChange,
  type,
  ...rest
}: IInputProps) {
  const { fieldName, registerField, error } = useField(name)
  const inputRef = useRef<any>(null)
  const [Erro, setErro] = useState(initialError)

  const showInput = (tp: any) => {
    switch (tp) {
      case 'money':
        return (
          <MaskedInput
            className="masked-input"
            ref={inputRef}
            mask="R$ num"
            blocks={{
              num: {
                mask: Number,
                thousandsSeparator: '.',
              },
            }}
            onAccept={(e: any) => handleChange(e)}
            {...rest}
          />
        )
      case 'cpf':
        return (
          <MaskedInput
            className="masked-input"
            mask="000.000.000-00"
            ref={inputRef}
            onAccept={(e: any) => handleChange(e)}
            {...rest}
          />
        )
      case 'cnpj':
        return (
          <MaskedInput
            className="masked-input"
            ref={inputRef}
            mask="00.000.000/0000-00"
            onAccept={(e: any) => handleChange(e)}
            {...rest}
          />
        )
      case 'cell':
        return (
          <MaskedInput
            className="masked-input"
            ref={inputRef}
            mask="(00) 00000-0000"
            onAccept={(e: any) => handleChange(e)}
            {...rest}
          />
        )
      case 'cep':
        return (
          <MaskedInput
            className="masked-input"
            ref={inputRef}
            mask="00000-000"
            onAccept={(e: any) => handleChange(e)}
            {...rest}
          />
        )
      case 'jurosaa':
        return (
          <MaskedInput
            className="masked-input"
            ref={inputRef}
            mask="num% \a.\a."
            eager
            blocks={{
              num: {
                mask: Number,
                scale: 2,
                signed: false,
                from: 0,
                to: 100,
              },
            }}
            onAccept={(e: any) => handleChange(e)}
            {...rest}
          />
        )
        case 'date':
        return (
          <MaskedInput
            className="masked-input"
            ref={inputRef}
            mask="d/m/Y"
            blocks={{
              d: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 31,
                maxLength: 2,
              },
              m: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12,
                maxLength: 2,
              },
              Y: {
                mask: IMask.MaskedRange,
                from: new Date().getFullYear(),
                to: 3000
              },
            }}
            onAccept={(e: any) => handleChange(e)}
            {...rest}
          />
        )
      default:
        return (
          <MaskedInput
            className="masked-input"
            mask={'Number'}
            ref={inputRef}
            onAccept={(e: any) => handleChange(e)}
            {...rest}
          />
        )
    }
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    })
  }, [registerField, fieldName])

  useEffect(() => {
    if (error) {
      setErro(error)
    }
  }, [error])

  return (
    <InputContainer>
      {showInput(type)}
      {Erro && <InputError>{Erro}</InputError>}
    </InputContainer>
  )
}
