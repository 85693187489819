import { ResumeInputCard, PageTitle } from 'components'
import icons from 'helper/icons'
import {
  toCapitalizeCase,
  numberToMoney,
  numberToCellphone,
  maskCPForCNPJ,
} from 'helper/utils'
import useFetch from 'hooks/useFetch'
import { useEffect, useState } from 'react'
import { FlexContainer } from './style'

interface IImovel {
  id: string
}

interface IData {
  [key: string]: any
}

export default (props: IImovel) => {
  const [propertyData, setPropertyData] = useState<IData>({})
  const [addressData, setAddressData] = useState<IData>({})
  const [ownerData, setOwnerData] = useState<IData>({})
  const { id } = props

  const api = useFetch(`/product/aluguel-verde/contract/property?id=${id}`)

  useEffect(() => {
    api.fetch('get').then((res) => {
      if (res.fail) throw new Error()
      const { response } = res.data
      setPropertyData(response)
      setAddressData(response.address)
      setOwnerData(response.owner)
    })
  }, [])

  return (
    <>
      <FlexContainer>
        <img
          className="icon"
          src={icons.clientOutlined}
          alt="ResumeInputCard icon"
        />
        <PageTitle>Dados do imóvel</PageTitle>
      </FlexContainer>
      <ResumeInputCard
        placeholder={
          propertyData?.type ? toCapitalizeCase(propertyData?.type) : 'd--'
        }
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        Tipo de imóvel
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={
          addressData?.address ? toCapitalizeCase(addressData?.address) : '--'
        }
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        Endereço
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={
          addressData?.city ? toCapitalizeCase(addressData?.city) : '--'
        }
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        Cidade
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={addressData?.state || '--'}
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        UF
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={
          addressData?.neighborhood
            ? toCapitalizeCase(addressData?.neighborhood)
            : '--'
        }
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        Bairro
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={addressData?.zipcode}
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        CEP
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={addressData?.addressNumber}
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        Número
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={
          addressData?.complement
            ? toCapitalizeCase(addressData?.complement)
            : '--'
        }
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        Complemento
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={
          propertyData?.rentValue
            ? `R$ ${numberToMoney(Number(propertyData?.rentValue) / 100)}`
            : '--'
        }
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        Valor do aluguel
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={
          propertyData?.condominiumValue
            ? `R$ ${numberToMoney(
                Number(propertyData?.condominiumValue) / 100
              )}`
            : '--'
        }
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        Valor do condomínio
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={
          propertyData?.iptuValue
            ? `R$ ${numberToMoney(Number(propertyData?.iptuValue) / 100)}`
            : '--'
        }
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        Valor do IPTU
      </ResumeInputCard>
      <FlexContainer>
        <img
          className="icon"
          src={icons.clientOutlined}
          alt="ResumeInputCard icon"
        />
        <PageTitle>Dados do proprietário</PageTitle>
      </FlexContainer>
      <ResumeInputCard
        placeholder={ownerData?.name || '--'}
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        Nome
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={
          ownerData?.documentNumber
            ? maskCPForCNPJ(ownerData?.documentNumber)
            : '--'
        }
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        CPF
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={ownerData?.birthday || '--'}
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        Data de nascimento
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={ownerData?.email || '--'}
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        E-mail
      </ResumeInputCard>
      <ResumeInputCard
        placeholder={numberToCellphone(ownerData?.phoneNumber) || '--'}
        icon="leafOutlined"
        clickable={false}
        names="resumo-meta-captacao"
      >
        Telefone
      </ResumeInputCard>
    </>
  )
}
