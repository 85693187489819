import {
  PageTitle,
  PagePathing,
  TokenPoster,
  ColorPicker,
  ButtonNext,
  UploadImg,
  Slider,
} from 'components'
import { Column, Row } from 'styles/index'
import { Form } from '@unform/web'

import { PreviewText, Subtitles, Content, SmallContent, FormDiv } from './style'
import { useContext, useEffect, useState } from 'react'
import { SapiensContext } from 'context/sapiens'
import { adjustColor } from 'helper/secondColor'
import ReactDOMServer from 'react-dom/server'
import TokenIcon from 'components/Card/TokenPoster/TokenIcon'
import * as Yup from 'yup'
import { numberToMoney } from 'helper/utils'

const cardmock = {
  min: '',
  colorTags: [],
  createdAt: '',
  name: '',
  tick: '',
  rate: 0,
  chart: [{ rate: 0, title: '', color: '#757575' }],
  guarantee: null,
  buttonText: 'Investir',
}

const schemaCardLayout = Yup.object()
  .shape({
    color: Yup.string().min(7).required(),
    secondColor: Yup.string().min(7).required(),
    previewImg: Yup.string().min(1).required(),
    imageOpacity: Yup.string().min(1).required(),
    preview64Token: Yup.string().min(1).required(),
  })
  .typeError('Este input deve ser preenchido corretamente')

export default function ({ handleNext, handleBack }: any) {
  const { newCaption, setNewCaption } = useContext(SapiensContext)
  const [cardData, setCardData] = useState(cardmock)
  const [color, setColor] = useState('#757575')
  const [allowedNext, setAllowedNext] = useState<boolean>(false)

  useEffect(() => {
    setNewCaption((c: any) => ({
      ...c,
      imageOpacity: '50',
    }))
  }, [])

  useEffect(() => {
    setCardData((prev: any) => ({
      ...prev,
      rate: 0,
      min: numberToMoney(newCaption?.minimumInvest) || '',
      guarantee: newCaption?.garantia?.flag,
      buttonText: 'Investir',
      colorTags: [
        newCaption?.indexador?.flag || '',
        newCaption?.prazo?.label || '',
        newCaption?.index
          ? `${newCaption?.index?.label} + ${newCaption?.profitability}% a.a.`
          : `${newCaption?.profitability}% a.a.` || '',
      ],
      createdAt: new Date().getFullYear() || '',
      name: newCaption?.name || '',
      tick: newCaption?.tick || '',
      chart: [{ rate: 0, title: '', color: newCaption?.color }],
      color: newCaption?.color,
      secondColor: newCaption?.secondColor,
      image: newCaption?.previewImg,
      opacity: newCaption?.imageOpacity,
      newIcon: newCaption?.preview64Token,
    }))
    const validateInputs = async () => {
      try {
        await schemaCardLayout.validate(newCaption, { abortEarly: false })
        setAllowedNext(true)
      } catch (err) {
        console.log(err)
        setAllowedNext(false)
      }
    }
    validateInputs()
  }, [newCaption])

  const handleSubmit = () => {
    setNewCaption((c: any) => ({
      ...c,
      doneIcon: newCaption?.preview64Token
    }))
    // console.log('akakakaka')
    handleNext()
  }

  return (
    <>
      <PagePathing
        withoutMarginLeft
      >{`Gestão de produtos >  Investimentos  > Nova captação  > Resumo da captação > Layout`}</PagePathing>
      <PageTitle withoutMarginLeft detailsMargin>
        Criar Layout da Nova Captação
      </PageTitle>
      <Form onSubmit={handleSubmit}>
        <FormDiv>
          <Row gap={40} alignStart>
            <Column gap={15}>
              <Subtitles>Cores</Subtitles>
              <Row gap={24} noMarginLeft>
                <ColorPicker
                  // initColor={newCaption.color}
                  getColor={(color: string) => {
                    setColor(color)
                    setNewCaption((c: any) => ({
                      ...c,
                      color,
                      secondColor: adjustColor(color, 0.8, 0.4),
                    }))
                  }}
                />
                <ColorPicker
                  placeholder="Cor Secundária gerada"
                  disabled
                  initColor={adjustColor(color, 0.8, 0.4)}
                />
              </Row>
              <Row gap={10} noMarginLeft>
                <Column gap={16}>
                  <Subtitles>Imagem</Subtitles>
                  <Content>
                    <span>Selecione um arquivo </span>ou arraste e solte aqui
                  </Content>
                  <UploadImg
                    accept='.png'
                    iconName="pictureBlack"
                    placeholder="Upload de Imagem"
                    setPreview={(pic: string) => {
                      setNewCaption((c: any) => ({
                        ...c,
                        previewImg: pic,
                      }))
                    }}
                    setPreview64={(pic: string) => {
                      setNewCaption((c: any) => ({
                        ...c,
                        preview64Img: pic,
                      }))
                    }}
                  />
                </Column>
                <Column gap={16}>
                  <Subtitles style={{ marginLeft: 14 }}>
                    Opacidade da imagem
                  </Subtitles>
                  <Content style={{ marginLeft: 14 }}>
                    <span>Deslize </span>para ajustar o nível de opacidade da
                    imagem
                  </Content>
                  <Slider
                  defaultValue={newCaption.imageOpacity}
                    getStep={(step: string) => {
                      setNewCaption((c: any) => ({
                        ...c,
                        imageOpacity: step,
                      }))
                    }}
                  />
                </Column>
              </Row>
              <Column gap={5}>
                <SmallContent>
                  <span>Recomendações do upload:</span>
                </SmallContent>
                <SmallContent>
                  {' '}
                  • Arquivo de imagem: JPG ou PNG Tamanho
                </SmallContent>
                <SmallContent> • Tamanho da imagem: 300px x 130px</SmallContent>
              </Column>
              <Column gap={16}>
                <Subtitles>Ícone</Subtitles>
                <UploadImg
                  iconName="tokenIconBlack"
                  placeholder="Upload do ícone do token"
                  name="uploadIcon"
                  id="uploadIcon"
                  accept=".svg"
                  setPreview={(pic: string) => {
                    setNewCaption((c: any) => ({
                      ...c,
                      previewToken: pic,
                    }))
                  }}
                  setPreview64={(pic: string) => {
                    setNewCaption((c: any) => ({
                      ...c,
                      preview64Token: pic,
                    }))
                  }}
                />
                <Column gap={5}>
                  <SmallContent>
                    <span>Recomendações do upload:</span>
                  </SmallContent>
                  <SmallContent> • Arquivo Vetor: SVG </SmallContent>
                  <SmallContent> • Tamanho do ícone: 24px x 24px</SmallContent>
                  {/* <SmallContent>
                    {' '}
                    • Espessura de linha do ícone: 1px
                  </SmallContent>
                  <SmallContent> • Cor: Branco #FFFFFF</SmallContent> */}
                </Column>
              </Column>
            </Column>
            <Column>
              <PreviewText>Preview da captação no app Sapiensbank</PreviewText>
              <TokenPoster onPress={() => {}} item={cardData} i={1} />
            </Column>
          </Row>
        </FormDiv>
        <ButtonNext disabled={!allowedNext} alsoBack onClickBack={handleBack} />
      </Form>
    </>
  )
}
