import { SapiensContext } from 'context'
import icons from 'helper/icons'
import { ChangeEvent, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { SubMenu, MenuButton, SearchBar, SearchBarContainer, SubMenuButton } from './style'

interface IProps {
  categories?: any[][]
  onClickSub?: any
  selectedSub?:string
}

export default function SubMenuComponent(props: IProps) {
  const location = useLocation()
  const { setSearchParam, setShowModalNewClient } = useContext(SapiensContext)

  const { categories = [], onClickSub = () => {}, selectedSub } = props

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchParam(e.target.value)
  }

  return (
    <SubMenu>
      <SearchBarContainer>
        <img
          src={icons.searchOutlined}
          alt="search icon"
          className="leftIcon"
        />
        <SearchBar placeholder="Pesquisar" onChange={handleSearch} />
      </SearchBarContainer>

      {categories.map(
        ([label, to = 'pf', icon = icons.clientPFSolid, isModal = false, subcategories = []]) => (
          <>
          <MenuButton
            to={to}
            key={`item_${to}`}
            active={
              isModal ? false : new RegExp(to, 'i').test(location.pathname)
            }
            state={{ from: location }}
            onClick={() => isModal && setShowModalNewClient(true)}
            >
            <img src={icon} alt="search icon" />
            {label}
          </MenuButton>
            {subcategories.map((sub: any) => (
             <MenuButton
             isSub
             to={to}
             key={`item_${sub.label}_subcategore`}
             active={
              selectedSub === sub.label
             }
            //  state={{ from: location }}
             onClick={() => onClickSub(sub.label)}
             >
             {sub.label}
           </MenuButton>
            ))}
            </>
        )
      )}
    </SubMenu>
  )
}
