import styled from 'styled-components'

interface ICardProps {
  extraWide?: boolean
  wide?: boolean
  large?: boolean
  medium?: boolean
  disabled?: boolean
}

export default styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 14px;
  color: #9c948c;
  width: ${(props: ICardProps) => {
    if (props.medium) return '171px'
    return '30px'
  }};
  height: 20px;
  background: transparent;
  background-image: url('https://sapiensbank.com.br/assets/icons/misc/select-arrow.svg');
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: none;
  margin: 10px 0;

  &:focus {
    /* background-image: none; */
    outline: none;
  }

  .teste {
    padding: 0 30px 0 10px;
    min-height: 40px;
    display: flex;
    align-items: center;
    background: #e5e5e5;
    border-top: #222 solid 1px;
    position: absolute;
    top: 0;
    width: 100%;
    pointer-events: none;
    order: 2;
    z-index: 1;
    transition: background 0.4s ease-in-out;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
  }
`
