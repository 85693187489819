import styled from 'styled-components'
import { colorConverter } from '../../helper/utils'

interface IStyleProps {
  column?: string
  color?: string
}

export const KanbanColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin: 5px 5px 15px;
  background-color: #f9f9f9;
  height: 720px;
  width: 270px;

  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 50px;
    border: none;
  }

  .columnName {
    display: flex;
    align-items: center;
    align-self: flex-start;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: left;
    margin: 30px 0 20px 30px;

    color: #070707;

    img {
      margin-right: 10px;
      height: 19px;
      filter: ${(props: IStyleProps) => {
        const c = props.color && colorConverter(props.color)
        return typeof c === 'object'
          ? c.filter
          : 'invert(0%) sepia(0%) saturate(2016%) hue-rotate(167deg) brightness(109%) contrast(80%)'
      }};
    }
  }
`

export const KanbanContainer = styled.div`
  overflow: auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 0 0 20px 0;
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 50px;
    border: none;
  }
`
