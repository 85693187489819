import styled from 'styled-components'

export const LoadingBackground = styled.div`
  z-index: 300 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  background-color: ${(props) => props.color};

  p {
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    color: black;
  }
`
