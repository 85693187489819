import { LoadGif } from 'components'
import { MouseEvent } from 'react'
import Button from './style'

interface IButtonProps {
  $loading: boolean | string
  children: JSX.Element[] | JSX.Element | string
  disabled?: boolean
  outlined?: boolean
  secondary?: boolean
  rounded?: boolean
  small?: boolean
  xSmall?: boolean
  color?: string
  type?: 'button' | 'submit'
  onClick?: (_e: MouseEvent<HTMLElement>) => void
}

export default function Btn(props: IButtonProps) {
  const { $loading, secondary, outlined, children } = props
  return (
    <Button {...props} disabled={!!$loading}>
      {$loading ? <LoadGif black={secondary || outlined} /> : children}
    </Button>
  )
}
