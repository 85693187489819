import styled from 'styled-components'
import { colorConverter } from 'helper/utils'

interface IStyleProps {
  [key: string]: any
}

export const PageTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
  /* line-height: 23px; */
  margin: 0;
`
export const KanbanContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: scroll;
  position: relative;
  /* max-width: 100vh; */
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: white;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }

  .column {
    display: flex;
    background-color: #f9f9f9;
    padding: 20px 22px 23px 33px;
    margin: 0px 5px;
    border-radius: 10px;
    width: 385px;
    flex-direction: column;
    gap: 24px;
    height: 672px;
  }

  .cards-container {
    display: flex;
    gap: 24px;
    padding: 10px 5px;
    flex-direction: column;
    overflow: scroll;
    height: 420px;
    ::-webkit-scrollbar {
      width: 8px;
      background-color: #c4c4c4;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-track {
      background: #f9f9f9;
      border: 0;
    }
    ::-webkit-scrollbar-thumb {
      width: 8px;
      background-color: #c4c4c4;
      border-radius: 50px;
      height: 140px;
    }
  }
`
export const Img = styled.img`
  filter: ${(props: IStyleProps) => {
    const c = props.color && colorConverter(props.color)
    return typeof c === 'object'
      ? c.filter
      : 'invert(0%) sepia(0%) saturate(2016%) hue-rotate(167deg) brightness(109%) contrast(80%)'
  }};
`
export const ColumnTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.2px;
  transform: rotate(-0.22deg);
  margin: 0;
  color: ${(props: IStyleProps) => props.color};
`
