import { useEffect, useState } from 'react'
import useFetch from 'hooks/useFetch'
import { PagePathing, PageTitle, AccountStatus } from 'components'

import { ExtendedContainer, CardsWrap } from './style'

import FEF from './fef'
import Imovel from './imoveis'
import Inquilino from './inquilino'
import Seguro from './seguro'

interface IData {
  [key: string]: any
}

interface IProps {
  complete?: boolean
  contractId: string
}

export default (props: IProps) => {
  const { complete, contractId } = props
  const contractApi = useFetch(
    `product/aluguel-verde/contract?id=${contractId}`
  )
  const [contractData, setContractData] = useState<IData>({})

  useEffect(() => {
    contractApi.fetch('get').then((res) => {
      setContractData(res.data.response)
    })
  }, [])

  return (
    <ExtendedContainer>
      <PagePathing withoutMarginLeft>
        {'Detalhes do cliente > Produtos > Aluguel verde > FEF'}
      </PagePathing>
      <AccountStatus
        color={contractData?.status?.color || 'black'}
        status={contractData?.status?.name || '--'}
      />
      <PageTitle withoutMarginLeft>Ficha de extrato de finança:</PageTitle>
      <CardsWrap>
        <FEF contractData={contractData} />
        {complete && contractData?.propertyId && (
          <Imovel id={contractData.propertyId} />
        )}
        {complete && contractId && <Inquilino id={contractId} />}
        {complete && contractId && <Seguro />}
      </CardsWrap>
    </ExtendedContainer>
  )
}
