import styled from 'styled-components'

export const ExtendedContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  max-height: 100%;
  height: 100%;
  padding: 0;
  width: 99%;
  background-color: #f5f4f3;

  .left-menu-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 60px 0 0 50px;
    width: 350px;
    overflow: hidden;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    padding: 30px 0 0 0px;
    width: 80%;
    max-height: 100%;
    background-color: #f9f9f9;
  }

  .mess-conteiner-user {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .mess-conteiner-sapiens {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  img {
    margin-right: 10px;
  }

  .user-message {
    background: #23a6f0;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 15px;
    max-width: 210px;
    min-width: 40px;
    font-weight: 400;
    font-size: 18px;
    color: #f9f9f9;
  }

  .sapiens-message {
    background: #fdfdfd;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: auto;
    padding: 15px;
    max-width: 210px;
    font-weight: 400;
    font-size: 18px;
    color: #9c948c;
    min-width: 40px;
  }

  .bold-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
  }

  .chat-window {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    max-height: 80%;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 8px;
      background-color: #c4c4c4;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-track {
      background: #fbfbfb;
      border: 0;
    }
    ::-webkit-scrollbar-thumb {
      width: 8px;
      background-color: #c4c4c4;
      border-radius: 50px;
    }
  }

  .input-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    background-color: #f9f9f9;
    border-top: solid 0.5px #a6a6a6;
    height: 20%;
    padding: 15px 0 10px 0;
    width: 99%;
    textarea {
      width: 100%;
      height: 80px;
      margin-bottom: 15px;
      border-bottom: solid 0.5px #a6a6a6;
    }
  }

  textarea {
    cursor: 'pointer';
    resize: none;
    background-color: #f9f9f9;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    max-height: 96%;
    height: 180px;
    align-self: flex-start;
    width: 80%;
    border: none;
    outline: none;
    ::placeholder {
      color: black;
    }
    ::-webkit-scrollbar {
      width: 8px;
      background-color: #c4c4c4;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-track {
      background: #fbfbfb;
      border: 0;
    }
    ::-webkit-scrollbar-thumb {
      width: 8px;
      background-color: #c4c4c4;
      border-radius: 50px;
    }
  }
`
