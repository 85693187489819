import styled from 'styled-components'

export default styled.div`
  height: 70%;
  overflow: scroll;
  ::-webkit-scrollbar-track {
    background-color: #ffffff;
  }
  ::-webkit-scrollbar {
    width: 6px;
    background: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    background: #ffffff;
  }
`
