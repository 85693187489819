import styled from 'styled-components'

interface IStyleProps {
  width?: number
  height?: number
  src: string
}

export default styled.img`
  width: ${(props: IStyleProps) => {
    if (props.width) return props.width
    return ''
  }};
  height: ${(props: IStyleProps) => {
    if (props.height) return props.height
    return ''
  }};
`
