import { PagePathing, PageTitle, KanbanSubMenu } from 'components'
import { useEffect, useState } from 'react'
import { CardsContainer } from 'styles'
import { icons } from 'helper'
import { useLocation } from 'react-router-dom'

import Inquilino from './Inquilinos'
import Proprietario from './Proprietarios'
import Imobiliaria from './Imobiliarias'
import Coberturas from './Coberturas'

export default function GestaoContratos() {
  const location = useLocation()
  const [currentPage, setCurrentPage] = useState('')

  const categories = [
    ['Inquilinos', `inquilinos`, icons.userOutlined, false],
    ['Imóvel Pro', `proprietarios`, icons.houseGreenOutlined, false],
    ['Imobiliárias', `imobiliarias`, icons.buildingOutlined, false],
    ['Coberturas', `coberturas`, icons.contractOutlined, false],
  ]

  useEffect(() => {
    const [page] = location.pathname.split('/').reverse()
    console.log(location.pathname)
    setCurrentPage(page)
  }, [location.pathname])

  const getLabelFromPathname = () => {
    const [filtered = []] = categories.filter(
      ([_, page]) => page === currentPage
    )
    return filtered[0]
  }

  return (
    <>
      <div>
        <PagePathing>{`Gestão de clientes > Aluguel verde > ${getLabelFromPathname()}`}</PagePathing>
        <PageTitle>Aluguel Verde</PageTitle>
        <KanbanSubMenu categories={categories} />
      </div>
      <CardsContainer marginLeft>
        {
          {
            inquilinos: <Inquilino />,
            proprietarios: <Proprietario />,
            imobiliarias: <Imobiliaria />,
            coberturas: <Coberturas />,
          }[currentPage]
        }
      </CardsContainer>
    </>
  )
}
